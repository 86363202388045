import { Layout } from 'antd';
import styled from 'styled-components';

export const WrapperLayout = styled(Layout)`
    width: 100%;
    height: 100%;
    .ant-layout-sider {
        background: #fff;
    }
    .sider-admin {
        width: 220px;
        height: 100%;
        position: fixed;
        left: 0;
        top: 60px;
        z-index: 999;
        box-shadow: 0 4px 10px #ddd;
    }
    .sider-admin-collapsed {
        .ant-menu-inline-collapsed > .ant-menu-item {
            padding-block-start: 5px;
        }
    }
    .layout-page-content {
        display: flex;
        flex-direction: column;
        height: 100vh;
        padding: 20px;
        background: #f8f8f8;
        overflow: auto;
        margin: 60px 0 20px 200px;
        min-height: 100vh;
        width: calc(100% - 240px) !important;
    }
    .layout-page-content-collapsed {
        margin-left: 50px;
        margin-top: 15px;
        width: calc(100% - 240px) !important;
    }
`;
