import qs from 'query-string';
import { intercepter, mock } from '../config';
import { TTheme } from '../../api/theme';

const data: TTheme[] = [
    {
        id: 1,
        name: 'Purple Theme',
        value: 'purple',
        config: {
            fontFamily: 'Roboto, sans-serif',
            colorPrimary: '#7357FF',
            colorSecondary: '#4DCC8F',
        },
    },
    {
        id: 2,
        name: 'Magenta Theme',
        value: 'magenta',
        config: {
            fontFamily: 'Roboto, sans-serif',
            colorPrimary: '#FF57A6',
            colorSecondary: '#FF8C73',
        },
    },
    {
        id: 3,
        name: 'Green Theme',
        value: 'green',
        config: {
            fontFamily: 'Roboto, sans-serif',
            colorPrimary: '#4DCC8F',
            colorSecondary: '#7357FF',
        },
    },
    {
        id: 4,
        name: 'Golden Theme',
        value: 'golden',
        config: {
            fontFamily: 'Roboto, sans-serif',
            colorPrimary: '#BF9B30',
            colorSecondary: '#a67c00',
        },
    },
    {
        id: 5,
        name: 'Red Theme',
        value: 'red',
        config: {
            fontFamily: 'Roboto, sans-serif',
            colorPrimary: '#b01139',
            colorSecondary: '#780725',
        },
    },
];

mock.mock(/\/theme\/list\/mock*/, 'get', (config: any) => {
    const jsonParams = config.url.split('?')[1];
    const params = qs.parse(jsonParams);

    return intercepter(data, params);
});
