import React from 'react';
import { IconProps } from './type';

export const ICCategory: React.FC<IconProps> = ({
    width = 24,
    height = 24,
}) => {
    return (
        <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            viewBox="0 0 24 24"
            height={height}
            width={width}
        >
            <path stroke="none" d="M0 0h24v24H0z" />
            <path d="M4 4h6v6H4zM14 4h6v6h-6zM4 14h6v6H4z" />
            <path d="M20 17 A3 3 0 0 1 17 20 A3 3 0 0 1 14 17 A3 3 0 0 1 20 17 z" />
        </svg>
    );
};
