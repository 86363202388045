import { Progress } from 'antd';
import React from 'react';
import {
    EStatusOfService,
    TCarInService,
} from '../../../pages/start-new-task/type';

type StatusCarProps = {
    task: TCarInService;
};

export const StatusCar: React.FC<StatusCarProps> = ({ task }) => {
    const serviceDone = React.useMemo(() => {
        if (task?.services?.length === 0) {
            return 0;
        }
        const doneServices = task?.services?.filter(
            service => service?.serviceDetailStatus === EStatusOfService.DONE
        );
        return doneServices?.length;
    }, [task]);

    const progressCount = React.useMemo(() => {
        if (task?.services?.length === 0) {
            return 0;
        }
        const doneServices = task?.services?.filter(
            service => service?.serviceDetailStatus === EStatusOfService.DONE
        );
        if (doneServices && task?.services) {
            const result = (doneServices.length / task?.services?.length) * 100;
            return Math.round(result * 100) / 100;
        }
        return 0;
    }, [task]);

    const isCompleted = React.useMemo(() => {
        return progressCount === 100;
    }, [progressCount]);

    const duration = React.useMemo(() => {
        if (task?.services?.length === 0) {
            return 0 + ' minutes';
        }
        const doneServices = task?.services?.filter(
            service => service?.serviceDetailStatus === EStatusOfService.DONE
        );
        if (doneServices && task?.services) {
            const result = doneServices.reduce(
                (acc, service) => acc + (service?.durationInMinutes || 0),
                0
            );
            if (result >= 60) {
                const hours = Math.floor(result / 60);
                const minutes = result % 60;
                if (minutes > 0) {
                    return hours + ' hours ' + minutes + ' minutes';
                }
                return hours + ' hours';
            }
            if (result > 0) {
                return result + ' minutes';
            }
        }
        return 0 + ' minutes';
    }, [task]);

    return isCompleted ? (
        <div className="flex flex-col gap-1 w-full">
            <div className="flex justify-between items-center">
                <span className="text-muted font-13">Completed:</span>
                <span className="text-muted font-13">{duration}</span>
            </div>
        </div>
    ) : (
        <div className="flex flex-col gap-1 w-full">
            <div className="flex justify-between items-center">
                <span className="text-muted font-13">Services completed:</span>
                <span className="text-muted font-13">
                    {serviceDone} / {task?.services?.length}
                </span>
            </div>
            <Progress
                percent={progressCount}
                strokeColor={
                    progressCount !== 100 ? 'var(--primaryColor)' : '#87d068'
                }
                status={progressCount !== 100 ? 'active' : 'normal'}
            />
        </div>
    );
};
