import { ApiInstance } from '../..';
import { SearchQueryParams } from '../../../interface/paging';
import { TService } from '../../../pages/start-new-task/type';
import { convert2QueryString } from '../../../utils/convert2QueryString';

export const getServices = () => {
    return ApiInstance.getInstance().get('/api/services');
};

export const getServicePaging = (params: SearchQueryParams | undefined) => {
    const requestURL = '/api/services/search?' + convert2QueryString(params);
    return ApiInstance.getInstance().get(requestURL);
};

export const createService = (data: any) => {
    return ApiInstance.getInstance().post('/api/services/add', data);
};

export const updateService = (
    serviceId: string | undefined | number,
    data: TService
) => {
    const baseUrl = `/api/services/${serviceId}`;
    return ApiInstance.getInstance().put(baseUrl, data);
};

export const deleteService = (serviceId: string | undefined | number) => {
    const baseUrl = `/api/services/${serviceId}`;
    return ApiInstance.getInstance().delete(baseUrl);
};
