import { Badge, Dropdown } from 'antd';
import React from 'react';
import classNames from 'classnames';
import { ICClose, ICNotification } from '../../../icons';
import { useGeneralPageData } from '../../../pages/general';
import { timeAgo } from '../../../utils';
import { TNotification } from '../../../interface/user/user';
import styled from 'styled-components';
const idb = require('idb');

const WrapperNotificationList = styled.div`
    .ant-dropdown-menu-item {
        padding: 10px !important;
    }
    li {
        cursor: pointer;
        padding: 10px 15px;
        border-bottom: 1px solid #f0f0f0;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
        &:hover {
            background: #f5f5f5;
        }
    }
`;

const Wrapper = styled.div`
    .ant-dropdown-trigger {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
`;

export const NotificationControl: React.FC = () => {
    const { notifications, setNotifications } = useGeneralPageData();
    const [notificationList, setNotificationList] = React.useState<
        TNotification[]
    >([]);

    React.useEffect(() => {
        setNotificationList(notifications);
    }, [notifications]);

    const onRemoveNotification = React.useCallback(
        async (notification: TNotification) => {
            setNotifications(prev => {
                return prev.filter(item => item.id !== notification.id);
            });
            const db = await idb.openDB('autoTrack', 1, {
                upgrade(db) {
                    db.createObjectStore('notifications');
                },
            });
            const tx = db.transaction('notifications', 'readwrite');
            tx.objectStore('notifications').delete(notification.id);
        },
        []
    );

    const renderNotificationList = React.useMemo(() => {
        if (!notificationList) {
            return [];
        }
        const notificationsTemp = notificationList.sort((a, b) => {
            return (
                new Date(b.timestamp ?? 0).getTime() -
                new Date(a.timestamp ?? 0).getTime()
            );
        });
        return notificationsTemp.map((notification, index) => {
            const timestamp = timeAgo(notification.timestamp?.toString() ?? '');
            return {
                label: (
                    <div
                        className={classNames(
                            'flex flex-col gap-[15px] items-start justify-start w-full overflow-auto max-h-[200px]',
                            {
                                'unread-notification': !notification.isRead,
                            }
                        )}
                    >
                        <div className="flex flex-col w-full relative">
                            <div className="flex gap-3 items-center">
                                <h3
                                    className={classNames([
                                        'text-[16px]',
                                        notification.isRead &&
                                            'text-gray-600 font-normal',
                                        !notification.isRead &&
                                            'text-body-bold',
                                    ])}
                                >
                                    {notification.title}
                                </h3>
                                <span
                                    className={classNames([
                                        'text-[10px] text-gray-600',
                                    ])}
                                >
                                    {timestamp}
                                </span>
                            </div>
                            <div
                                className={classNames([
                                    'text-[13px]',
                                    notification.isRead &&
                                        'text-gray-600 font-normal',
                                ])}
                            >
                                {notification.body}
                            </div>
                            <div
                                className="absolute right-0 top-[10px]"
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    onRemoveNotification(notification);
                                }}
                            >
                                <ICClose width={14} height={14} />
                            </div>
                        </div>
                    </div>
                ),
                key: `notification_${index}`,
            };
        });
    }, [notificationList]);

    const onMarkRead = React.useCallback(async () => {
        setNotifications(prev => {
            return prev.map(item => {
                return {
                    ...item,
                    isRead: true,
                };
            });
        });
        const db = await idb.openDB('autoTrack', 1, {
            upgrade(db) {
                db.createObjectStore('notifications');
            },
        });
        const tx = db.transaction('notifications', 'readwrite');
        // mark all as read in indexedDB
        tx.objectStore('notifications')
            .getAll()
            .then((data: TNotification[]) => {
                data.forEach(item => {
                    tx.objectStore('notifications').put({
                        ...item,
                        isRead: true,
                    });
                });
            });
    }, []);

    const countUnread = React.useMemo(() => {
        return notificationList?.filter(item => !item.isRead).length;
    }, [notificationList]);

    return (
        <Wrapper className="flex items-center justify-center relative max-h-[60px]">
            <div className="relative h-full flex items-center justify-center cursor-pointer">
                <Dropdown
                    menu={{ items: renderNotificationList }}
                    overlayClassName="user-dropdown notification-dropdown"
                    getPopupContainer={triggerNode =>
                        triggerNode.parentNode as HTMLElement
                    }
                    overlayStyle={{
                        minWidth: '272px',
                        borderRadius: '12px',
                        color: '#110C22',
                        fontWeight: 500,
                    }}
                    // open
                    trigger={['click']}
                    placement="bottomRight"
                    arrow
                    dropdownRender={menu => (
                        <div className="notification-dropdown bg-white rounded-[12px] p-[5px] shadow-lg">
                            {notificationList?.length > 0 && (
                                <div className="flex items-center justify-between p-3 border-b">
                                    <h3 className="text-body-bold m-0">
                                        Notifications
                                    </h3>
                                    <div
                                        className="underline cursor-pointer"
                                        onClick={onMarkRead}
                                    >
                                        Mark all as read
                                    </div>
                                </div>
                            )}
                            <WrapperNotificationList className="flex flex-col w-full h-full overflow-auto max-h-[250px] custom-scroll-bar">
                                {menu}
                            </WrapperNotificationList>
                        </div>
                    )}
                >
                    <a onClick={e => e.preventDefault()}>
                        <Badge count={countUnread} offset={[0, 0]}>
                            <ICNotification />
                        </Badge>
                    </a>
                </Dropdown>
            </div>
        </Wrapper>
    );
};
