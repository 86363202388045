import React from 'react';
import { GetProp, Upload, UploadProps } from 'antd';
import { useNotification } from '../../../../hooks/useNotification';
import { AutoTrackButton } from '../../../../components/button';
import styled from 'styled-components';
import { ICCamera, ICNoneData } from '../../../../icons';

const WrapperCamera = styled.div`
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

type FileUploadProps = {
    setOpenCamera: React.Dispatch<React.SetStateAction<boolean>>;
};

export const FileUpload: React.FC<FileUploadProps> = ({ setOpenCamera }) => {
    const { showError } = useNotification();
    const [loading, setLoading] = React.useState(false);
    const [imageUrl, setImageUrl] = React.useState<string>();
    type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

    const uploadButton = (
        <div className="flex flex-col p-[10px] items-center justify-center w-full gap-2">
            <ICNoneData fill="#BDBDBD" width={30} height={30} />
            <div>Pick Image</div>
        </div>
    );

    const getBase64 = (img: FileType, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
            callback(reader.result as string)
        );
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file: FileType) => {
        const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            showError('Warning', 'You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            showError('Warning', 'Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange: UploadProps['onChange'] = info => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj as FileType, url => {
                setLoading(false);
                setImageUrl(url);
            });
        }
    };

    // const onFileUpload = () => {
    //     if (!file) return;
    //     setIsLoading(true);
    //     const storageRef = ref(storageFirebase, `/files/${file.name}`);
    //     const uploadTask = uploadBytesResumable(storageRef, file);

    //     uploadTask.on('state_changed', (snapshot) => {
    //         const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
    //         setProgrss(progress);
    //     }, () => {
    //         setIsLoading(false);
    //     },
    //         () => {
    //             getDownloadURL(uploadTask.snapshot.ref)
    //                 .then(url => {
    //                     setUrl(url);
    //                     setIsLoading(false);
    //                 })
    //         }
    //     )
    // }

    return (
        <div className="flex flex-col h-full gap-[10px] w-full">
            <div className="flex w-full">
                <div className="flex items-center justify-center min-h-[150px] w-full">
                    <Upload
                        name="file"
                        listType="picture-card"
                        className="avatar-uploader"
                        accept="image/*"
                        showUploadList={false}
                        disabled={loading}
                        action="/api/upload"
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                        style={{ width: '100%', height: '100%' }}
                    >
                        {imageUrl ? (
                            <img
                                src={imageUrl}
                                alt="avatar"
                                style={{ width: '100%' }}
                            />
                        ) : (
                            uploadButton
                        )}
                    </Upload>
                </div>
            </div>
            <div className="flex w-full">
                <div className="flex w-full">
                    <WrapperCamera>
                        <AutoTrackButton
                            btnSize="sm"
                            classess="w-full"
                            btnType="success"
                            style={{
                                height: '60px',
                                width: '60px',
                                borderRadius: '50%',
                            }}
                            onClick={() => setOpenCamera(true)}
                        >
                            <ICCamera fill="white" width={40} height={40} />
                        </AutoTrackButton>
                    </WrapperCamera>
                </div>
            </div>
        </div>
    );
};
