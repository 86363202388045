import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../redux/reducers';
import { NavigationProvider } from '../hooks/useNavigate';
import { LayoutType } from '../utils/enums';
import { notification as Notification, NotificationArgsProps } from 'antd';
import { ICCheckFill, ICClose, ICWarning } from '../icons';
import { ScrollTop } from '../hooks/useScrollTop';
import AntdWrapApp from '../message';
import { TBreadcrumbs } from '../layout/components/breadcrumbs';
import { AdminLayout } from '../layout';
import { useMe } from '../hooks/useMe';
import { getToken, onMessage } from 'firebase/messaging';
import { FIREBASE_VAPID_KEY, messaging } from '../firebase/firebaseConfig';
import { TNotification } from '../interface/user/user';
import { addFirebaseToken } from '../api/setting/users';
const idb = require('idb');

type NotificationType = 'success' | 'info' | 'warning' | 'error';

type TGeneralPageDataProps = {
    openNotification: (
        type: NotificationType,
        title: React.ReactNode,
        description?: React.ReactNode
    ) => void;
    breadcrumbs: TBreadcrumbs[];
    setBreadcrumbs: React.Dispatch<React.SetStateAction<TBreadcrumbs[]>>;
    titlePage: string;
    setTitlePage: React.Dispatch<React.SetStateAction<string>>;
    notifications: TNotification[];
    setNotifications: React.Dispatch<React.SetStateAction<TNotification[]>>;
};

export const GeneralPageDataContext =
    React.createContext<TGeneralPageDataProps>({} as TGeneralPageDataProps);
export const useGeneralPageData = () =>
    React.useContext(GeneralPageDataContext);

type GeneralPageProps = {
    page: React.ReactElement;
    isPrivate?: boolean;
    layout?: LayoutType;
    classes?: string;
};
export const GeneralPage: React.FC<GeneralPageProps> = ({
    page,
    isPrivate = false,
    classes,
    layout = LayoutType.ADMIN,
}) => {
    const navigate = useNavigate();
    const [api, contextHolder] = Notification.useNotification();
    const userInfo = useSelector((state: RootState) => state.userInfo);
    const [breadcrumbs, setBreadcrumbs] = React.useState<TBreadcrumbs[]>([]);
    const [titlePage, setTitlePage] = React.useState<string>('');
    const [notifications, setNotifications] = React.useState<TNotification[]>(
        []
    );
    const { userId, getMe } = useMe();
    type NotificationPlacement = NotificationArgsProps['placement'];
    const [fcmToken, setFcmToken] = React.useState<string | null>(null);

    const openNotification = (
        type: NotificationType,
        title: React.ReactNode,
        description?: React.ReactNode,
        placement?: NotificationPlacement
    ) => {
        Notification.destroy();
        if (type === 'success') {
            api[type]({
                icon: <ICCheckFill />,
                message: (
                    <span className="text-high-em text-body-bold">{title}</span>
                ),
                description,
                placement: placement || 'bottom',
                className: 'middle-notification',
                closeIcon: <ICClose height={18} width={18} fill={'#4F4B5C'} />,
            });
        }
        if (type === 'info') {
            api[type]({
                icon: <ICCheckFill />,
                message: (
                    <span className="text-high-em text-body-bold">{title}</span>
                ),
                description,
                placement: placement || 'bottom',
                className: 'middle-notification',
                closeIcon: <ICClose height={18} width={18} fill={'#4F4B5C'} />,
            });
        }
        if (type === 'warning') {
            api[type]({
                icon: <ICCheckFill />,
                message: (
                    <span className="text-high-em text-body-bold">{title}</span>
                ),
                description,
                placement: placement || 'bottom',
                className: 'middle-notification',
                closeIcon: <ICClose height={18} width={18} fill={'#4F4B5C'} />,
            });
        }
        if (type === 'error') {
            return api[type]({
                icon: <ICWarning fill="#F03D3D" />,
                message: (
                    <span className="text-body-bold text-high-em">{title}</span>
                ),
                // duration: 99999,
                description,
                placement: placement || 'bottom',
                className: 'middle-notification',
                closeIcon: <ICClose height={18} width={18} fill={'#4F4B5C'} />,
            });
        }
    };

    const [pageContent, setPageContent] = React.useState<React.ReactElement>(
        <></>
    );

    const saveTokenFB = async (token: string[]) => {
        try {
            if (!userId) {
                return;
            }
            const response = await addFirebaseToken(userId, token);
            if (response.status === 200) {
                // TODO: Handle success
            }
        } catch (error) {
            openNotification('error', 'Error when save token');
        }
    };

    React.useEffect(() => {
        if (fcmToken && userId) {
            saveTokenFB([fcmToken]);
        }
    }, [fcmToken, userId]);

    async function requestPermission() {
        try {
            if (!('Notification' in window)) {
                openNotification(
                    'warning',
                    'This browser does not support desktop notification',
                    'Please use another browser'
                );
                return;
            }
            const permission = await window.Notification.requestPermission();
            if (permission === 'granted') {
                const token = await getToken(messaging, {
                    vapidKey: FIREBASE_VAPID_KEY,
                });
                setFcmToken(token);
                window.localStorage.setItem('fcm_token', token);
            } else if (permission === 'denied') {
                openNotification(
                    'warning',
                    'You denied for the notification',
                    'Please allow the notification to get the best experience'
                );
            }
            const uuid = Math.random().toString(36).substring(7);
            onMessage(messaging, payload => {
                const nowDate = new Date();
                const newNotification: TNotification = {
                    id: uuid,
                    title: payload?.data?.title ?? '',
                    body: payload?.data?.body ?? '',
                    email: payload.data?.email ?? '',
                    isRead: false,
                    timestamp: nowDate.toISOString(),
                };
                setNotifications(prev => {
                    return [newNotification, ...prev];
                });
                const db = idb.openDB('autoTrack', 1, {
                    upgrade(db) {
                        db.createObjectStore('notifications', {
                            keyPath: 'id',
                            autoIncrement: true,
                        });
                    },
                });

                db.then((db: any) => {
                    const tx = db.transaction('notifications', 'readwrite');
                    const store = tx.objectStore('notifications');
                    store.put(newNotification);
                });
                openNotification(
                    'info',
                    payload?.data?.title ?? '',
                    payload.data?.body ?? '',
                    'topRight'
                );
            });
        } catch (error) {
            openNotification('error', 'Error when request permission');
        }
    }

    const loadNotificationFromIndexDB = async () => {
        if (!('indexedDB' in window)) {
            openNotification(
                'warning',
                'This browser does not support indexedDB',
                'Please use another browser'
            );
            return;
        }
        const db = await idb.openDB('autoTrack', 1, {
            upgrade(db) {
                db.createObjectStore('notifications', {
                    keyPath: 'id',
                    autoIncrement: true,
                });
            },
        });
        const tx = db.transaction('notifications', 'readwrite');
        const store = tx.objectStore('notifications');
        const data = await store.getAll();
        if (data.length > 0) {
            setNotifications(data);
        }
    };

    const initData = async () => {
        try {
            await getMe();
            await requestPermission();
        } catch (error) {
            openNotification('error', 'Error when init data');
        }
    };

    React.useEffect(() => {
        if (isPrivate) {
            initData();
            loadNotificationFromIndexDB();
        }
    }, []);

    React.useEffect(() => {
        if (classes) {
            document.body.className = classes;
        } else {
            document.body.className = '';
        }
        setTitlePage('');
        if (
            (isPrivate || typeof isPrivate === 'undefined') &&
            !userInfo.token
        ) {
            navigate('/login');
        } else {
            if (layout === LayoutType.ADMIN) {
                return setPageContent(<AdminLayout>{page}</AdminLayout>);
            } else {
                setPageContent(page);
            }
        }
    }, [page, userInfo, isPrivate, classes, navigate, layout]);

    return (
        <NavigationProvider>
            <GeneralPageDataContext.Provider
                value={{
                    openNotification,
                    breadcrumbs,
                    setBreadcrumbs,
                    titlePage,
                    setTitlePage,
                    notifications,
                    setNotifications,
                }}
            >
                <AntdWrapApp />
                {contextHolder}
                {pageContent}
                <ScrollTop />
            </GeneralPageDataContext.Provider>
        </NavigationProvider>
    );
};
