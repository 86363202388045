import { IconProps } from './type';

export const ICUser: React.FC<IconProps> = ({
    fill = 'var(--icon-high-em)',
    width = 18,
    height = 18,
    className = 'svgFillColor',
}) => {
    return (
        <svg
            width={width}
            height={height}
            className={className}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.82182 3.75C7.74487 3.75 6.87182 4.62305 6.87182 5.7C6.87182 6.77696 7.74487 7.65 8.82182 7.65C9.89878 7.65 10.7718 6.77696 10.7718 5.7C10.7718 4.62305 9.89878 3.75 8.82182 3.75ZM5.37182 5.7C5.37182 3.79462 6.91644 2.25 8.82182 2.25C10.7272 2.25 12.2718 3.79462 12.2718 5.7C12.2718 7.60538 10.7272 9.15 8.82182 9.15C6.91644 9.15 5.37182 7.60538 5.37182 5.7ZM1.60686 13.8644C2.78703 11.8959 5.27539 10.7071 8.82182 10.7071C12.3682 10.7071 14.8566 11.8959 16.0368 13.8644C16.2498 14.2196 16.1344 14.6803 15.7792 14.8933C15.4239 15.1062 14.9633 14.9909 14.7503 14.6357C13.9423 13.288 12.0734 12.2071 8.82182 12.2071C5.57026 12.2071 3.70132 13.288 2.89337 14.6357C2.68038 14.9909 2.21972 15.1062 1.86446 14.8933C1.50921 14.6803 1.39387 14.2196 1.60686 13.8644Z"
                fill={fill}
            />
        </svg>
    );
};
