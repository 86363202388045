import { Avatar, Card, Drawer, Modal, Tag } from 'antd';
import React from 'react';
import {
    OrderedListOutlined,
    EditOutlined,
    CopyOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import {
    EPriorityCarInService,
    EStatusCarInService,
    EStatusOfService,
    PriorityColorMapping,
    PriorityGradientColorMapping,
    PriorityOptionsMapping,
    StatusColorMapping,
    StatusOptionsMapping,
    TCarInService,
    TCarInServiceRequest,
    TServiceDetail,
    TServiceRequest,
    TUserService,
} from '../start-new-task/type';
import { AutoTrackTooltip } from '../../components/tooltip';
import classNames from 'classnames';
import { ServicesActionsPage } from './services-actions';
import { ViewServices } from './view-services';
import { useNotification } from '../../hooks/useNotification';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/slices/appInfo';
import { saveCarInService } from '../../api/car-in-service';
import { StatusCar } from '../../components/controls/car-status';
import { AutoTrackButton } from '../../components/button';
import { CardServices } from './card-services';

const Wrapper = styled.div`
    .ant-card-body {
        display: flex;
        flex-direction: column;
        padding: 16px;
    }
    .actions {
        .action-container {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 1px solid var(--borderColor);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;

type TaskItemPageProps = {
    task: TCarInService;
    mobileMode: boolean;
    setCarInServiceSelected: React.Dispatch<
        React.SetStateAction<TCarInService | undefined>
    >;
    setOpenEditTask: React.Dispatch<React.SetStateAction<boolean>>;
    onUpdateStatus: (
        status: EStatusOfService,
        serviceSelection: TServiceDetail,
        task: TCarInService
    ) => void;
    onReload: () => void;
};

export const TaskItemPage: React.FC<TaskItemPageProps> = ({
    task,
    mobileMode,
    setCarInServiceSelected,
    setOpenEditTask,
    onUpdateStatus,
    onReload,
}) => {
    const { showSuccess } = useNotification();
    const dispatch = useDispatch();
    const [isShowServices, setIsShowServices] = React.useState(false);
    const [openCopyTask, setOpenCopyTask] = React.useState(false);
    const [carNeedCopy, setCarNeedCopy] = React.useState<
        TCarInService | undefined
    >(undefined);

    const userNameList = React.useMemo(() => {
        const userServicesTemp: TUserService[] = [];
        if (task?.services?.length === 0) {
            return '';
        }
        task?.services?.forEach(service => {
            if (service?.user) {
                const isUserExist = userServicesTemp.find(
                    user => user.userId === service?.user?.userId
                );
                if (!isUserExist) {
                    userServicesTemp.push(service?.user);
                }
            }
        });
        return userServicesTemp;
    }, [task]);

    const getNickName = (user: TUserService) => {
        const name = user?.username?.split(' ');
        if (name && name.length > 1) {
            return name[0].charAt(0) + name[1].charAt(0);
        }
        return user?.username;
    };

    const priorityText = React.useMemo(() => {
        if (task?.priority) {
            return PriorityOptionsMapping[task?.priority];
        }
        return '';
    }, [task]);

    const priorityColor = React.useMemo(() => {
        if (task?.priority) {
            return PriorityColorMapping[task?.priority];
        }
        return '';
    }, [task]);

    const allowCopy = React.useMemo(() => {
        return (
            task?.status === EStatusCarInService.PENDING ||
            task?.status === EStatusCarInService.DONE
        );
    }, [task]);

    const renderFooter = React.useMemo(() => {
        const countDuration = task.services?.reduce(
            (acc, service) => acc + (service?.durationInMinutes || 0),
            0
        );
        return (
            <div className="flex flex-row items-center justify-between w-full">
                <div className="flex items-center gap-2">
                    <span className="text-muted font-normal text-[12px]">
                        Total Duration:
                    </span>
                    <span className="font-bold">{countDuration} hours</span>
                </div>
            </div>
        );
    }, [task?.services]);

    const onCopyCarInService = async () => {
        try {
            if (carNeedCopy) {
                dispatch(setLoading(true));
                const newServices = carNeedCopy?.services?.map(item => ({
                    serviceId: item.serviceId,
                    userId: item.user ? item.user.userId : undefined,
                    saveCarInService: EStatusCarInService.PENDING,
                }));
                const payload: TCarInServiceRequest = {
                    carId: carNeedCopy?.carId,
                    services: newServices as TServiceRequest[],
                    status: EStatusCarInService.PENDING,
                    priority: EPriorityCarInService.MEDIUM,
                };
                const result = await saveCarInService(payload);
                if (result.status === 201) {
                    showSuccess(
                        'Save service success',
                        'Service has been created'
                    );
                    setOpenCopyTask(false);
                    onReload();
                }
            }
        } catch (error) {
            // Handle error
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <Wrapper className="flex flex-col relative">
            <Card
                className={classNames([
                    'relative flex flex-col cursor-pointer rounded-[12px] hover:ring-2 hover:ring-[var(--primaryColor)] shadow-card-shadow',
                ])}
                style={{
                    background: task?.priority
                        ? PriorityGradientColorMapping[task?.priority]
                        : 'white',
                }}
            >
                <div className="flex flex-col gap-[15px] relative">
                    <div className="flex flex-col gap-1">
                        <div className="flex w-full">
                            <Tag color={priorityColor}>{priorityText}</Tag>
                        </div>
                        <div className="flex mb-0 justify-between items-center text-center gap-[10px]">
                            <h4 className="mb-0 text-left text-standard font-bold line-clamp-1">
                                {task?.car?.licensePlate} {task?.car?.make}
                            </h4>
                            <div className="flex actions gap-2">
                                {allowCopy && (
                                    <AutoTrackTooltip
                                        content="Copy"
                                        placement="top"
                                        childNode={
                                            <div
                                                className="action-container cursor-pointer shadow-big-shadow"
                                                onClick={() => {
                                                    setCarNeedCopy(task);
                                                    setOpenCopyTask(true);
                                                }}
                                            >
                                                <CopyOutlined />
                                            </div>
                                        }
                                    />
                                )}
                                {task?.status !== EStatusCarInService.DONE && (
                                    <AutoTrackTooltip
                                        content="Edit"
                                        placement="top"
                                        childNode={
                                            <div
                                                className="action-container cursor-pointer shadow-big-shadow"
                                                onClick={() => {
                                                    setCarInServiceSelected(
                                                        task
                                                    );
                                                    setOpenEditTask(true);
                                                }}
                                            >
                                                <EditOutlined />
                                            </div>
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className={classNames([
                            'grid items-center gap-3 max-md:min-h-[auto] min-h-[40px]',
                            {
                                'grid-cols-1': task.services?.length === 1,
                            },
                            {
                                'grid-cols-[10px_1fr]':
                                    task.services && task.services?.length > 1,
                            },
                        ])}
                    >
                        {task.services && task.services?.length > 1 && (
                            <div className="flex flex-row items-center gap-2">
                                <OrderedListOutlined />
                            </div>
                        )}
                        <div
                            className={classNames([
                                'w-full justify-start items-center gap-3',
                                {
                                    'grid grid-cols-[1fr_150px]':
                                        task.services?.length === 1,
                                    'flex flex-row flex-wrap':
                                        task.services &&
                                        task.services?.length > 1,
                                },
                            ])}
                        >
                            {task.services?.length === 1 ? (
                                <div className="flex max-md:w-full max-md:items-center justify-between text-muted font-13">
                                    <span className="w-full line-clamp-1">
                                        {task.services[0]?.service?.name}
                                    </span>
                                </div>
                            ) : (
                                <div
                                    className="flex gap-3 font-13"
                                    onClick={() => setIsShowServices(true)}
                                >
                                    <span className="text-muted">
                                        {task.services?.length} services
                                    </span>
                                    <span className="text-info-bold underline cursor-pointer">
                                        View
                                    </span>
                                </div>
                            )}
                            {task.services?.length === 1 && (
                                <ServicesActionsPage
                                    service={task.services[0]}
                                    carInServiceSelected={task}
                                    oneService={true}
                                    mobileMode={mobileMode}
                                    onUpdateStatus={onUpdateStatus}
                                />
                            )}
                        </div>
                    </div>
                    <StatusCar task={task} />
                    <div className="flex items-center justify-between gap-[5px] w-full min-h-[32px]">
                        <Avatar.Group>
                            {userNameList &&
                                userNameList?.map((user, index) => (
                                    <AutoTrackTooltip
                                        key={index}
                                        content={user?.username}
                                        placement="top"
                                        childNode={
                                            <Avatar
                                                key={index}
                                                style={{
                                                    backgroundColor: '#f56a00',
                                                }}
                                            >
                                                {getNickName(user)}
                                            </Avatar>
                                        }
                                    />
                                ))}
                        </Avatar.Group>
                        <div>
                            {task?.status && (
                                <Tag color={StatusColorMapping[task?.status]}>
                                    {StatusOptionsMapping[task?.status]}
                                </Tag>
                            )}
                        </div>
                    </div>
                </div>
            </Card>
            {!mobileMode && (
                <Modal
                    title={`${task?.car?.make} | ${task?.car?.model}`}
                    open={isShowServices}
                    onCancel={() => setIsShowServices(false)}
                    centered
                    destroyOnClose
                    afterClose={() => onReload()}
                    footer={null}
                    width={800}
                >
                    <ViewServices task={task} onUpdateStatus={onUpdateStatus} />
                </Modal>
            )}
            {mobileMode && (
                <Drawer
                    title={
                        <div className="flex items-center justify-between gap-3">
                            <div className="flex flex-col gap-2">
                                <h3>
                                    {task?.car?.make} | {task?.car?.model}
                                </h3>
                                <div>{renderFooter}</div>
                            </div>
                            <div
                                className="cursor-pointer"
                                onClick={() => setIsShowServices(false)}
                            >
                                <AutoTrackButton
                                    btnSize="sm"
                                    btnType="sub_danger"
                                >
                                    Close
                                </AutoTrackButton>
                            </div>
                        </div>
                    }
                    onClose={() => setIsShowServices(false)}
                    open={isShowServices}
                    destroyOnClose
                    closable={false}
                    zIndex={1000}
                    width={'100%'}
                    height={'90%'}
                    placement="bottom"
                    afterOpenChange={visible => {
                        if (!visible) {
                            onReload();
                        }
                    }}
                    classNames={{
                        body: 'body-card-services-drawer',
                    }}
                >
                    <CardServices task={task} onUpdateStatus={onUpdateStatus} />
                </Drawer>
            )}
            <Modal
                title="Copy Task"
                open={openCopyTask}
                onCancel={() => setOpenCopyTask(false)}
                onOk={onCopyCarInService}
                centered
                destroyOnClose
                afterClose={() => setCarNeedCopy(undefined)}
            >
                <div className="flex flex-col gap-3">
                    Do you want to copy task for {carNeedCopy?.car?.make}{' '}
                    {carNeedCopy?.car?.model}?
                </div>
            </Modal>
        </Wrapper>
    );
};
