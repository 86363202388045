import { DatePicker, Form, Select } from 'antd';
import React from 'react';
import { FormInstance } from 'antd/lib/form/Form';
import styled from 'styled-components';
import dayjs from 'dayjs';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import { TCar } from '../start-new-task/type';
import { AutoTrackForm } from '../../components/form/form';
import { AutoTrackInput } from '../../components/form/input';
import { renderColorOptions } from '../../utils';
dayjs.extend(customParseFormat);

const Wrapper = styled.div`
    .ant-row {
        align-items: center;
    }
    .ant-form-item-has-error {
        .ant-row {
            align-items: flex-start;
        }
    }
`;

type CarFormProps = {
    carData: TCar | undefined;
    form: FormInstance;
};
export const CarForm: React.FC<CarFormProps> = ({ carData, form }) => {
    const colorOptions = renderColorOptions();
    const childForm = (
        <div className="grid gap-[10px] grid-cols-[1fr]">
            <Form.Item
                name="make"
                label="Name"
                rules={[
                    {
                        required: true,
                        message: 'Please input the name!',
                    },
                ]}
            >
                <AutoTrackInput isRequired={true} />
            </Form.Item>
            <Form.Item
                name="model"
                label="Model"
                rules={[
                    {
                        required: true,
                        message: 'Please input the model!',
                    },
                ]}
            >
                <AutoTrackInput isRequired={true} />
            </Form.Item>
            <Form.Item
                name="year"
                label="Year"
                rules={[
                    {
                        required: true,
                        message: 'Please input the year!',
                    },
                ]}
            >
                <DatePicker
                    picker="year"
                    size="large"
                    style={{ width: '100%' }}
                    type="year"
                />
            </Form.Item>
            <Form.Item
                name="color"
                label="Color"
                rules={[
                    {
                        required: true,
                        message: 'Please input the color!',
                    },
                ]}
            >
                <Select
                    showSearch
                    size="large"
                    placeholder="Color"
                    options={colorOptions}
                />
            </Form.Item>
            <Form.Item
                name="vin"
                label="VIN"
                rules={[
                    {
                        required: true,
                        message: 'Please input the VIN!',
                    },
                ]}
            >
                <AutoTrackInput isRequired={true} />
            </Form.Item>
            <Form.Item
                name="licensePlate"
                label="License Plate"
                rules={[
                    {
                        required: true,
                        message: 'Please input the license plate!',
                    },
                ]}
            >
                <AutoTrackInput isRequired={true} />
            </Form.Item>
        </div>
    );

    React.useEffect(() => {
        if (carData) {
            const year = `${carData.year}-01-01`;
            form.setFieldsValue({
                make: carData.make,
                model: carData.model,
                year: dayjs(year),
                color: carData.color,
                vin: carData.vin,
                licensePlate: carData.licensePlate,
            });
        }
    }, [carData]);

    return (
        <Wrapper className="flex flex-col gap-[20px] w-full overflow-hidden h-full items-start box-border py-[20px] px-[5px]">
            <AutoTrackForm
                labelCol={{ flex: '130px' }}
                labelAlign="left"
                form={form}
                labelWrap
                wrapperCol={{ flex: 1 }}
                layout="horizontal"
                className="w-full"
                name="createCar"
                childNode={childForm}
            />
        </Wrapper>
    );
};
