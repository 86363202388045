export const ICNoResults: React.FC = () => {
    return (
        <svg
            width="120"
            height="120"
            viewBox="0 0 226 226"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.2">
                <path
                    d="M198.777 137.346H107.623L82.9004 107.932H174.054L198.777 137.346Z"
                    fill="#EEF0F6"
                />
                <path
                    d="M6.84863 137.346H58.178L82.9011 107.932H31.5717L6.84863 137.346Z"
                    fill="#EEF0F6"
                />
                <path
                    d="M107.623 137.346L82.9004 107.932V208.365H174.054V137.346H107.623Z"
                    fill="#B9D7FF"
                />
                <path
                    d="M58.1777 137.346H31.5713V208.365H82.9007V107.932L58.1777 137.346Z"
                    fill="#7CB7FF"
                />
                <path
                    d="M175.492 102.214L168.678 95.3994C167.514 96.8718 166.281 98.31 164.911 99.6797C163.541 101.049 162.103 102.282 160.631 103.446L167.445 110.261C166.658 113.685 167.616 117.452 170.287 120.122L200.968 150.804C205.112 154.947 211.858 154.947 216.001 150.804C220.144 146.66 220.144 139.915 216.001 135.771L185.32 105.09C182.683 102.351 178.951 101.426 175.492 102.214Z"
                    fill="#7CB7FF"
                />
                <path
                    d="M130.943 113.719C157.475 113.719 178.985 92.2099 178.985 65.677C178.985 39.1441 157.475 17.6349 130.943 17.6349C104.41 17.6349 82.9004 39.1441 82.9004 65.677C82.9004 92.2099 104.41 113.719 130.943 113.719Z"
                    fill="#B9D7FF"
                />
                <path
                    d="M130.944 100.022C126.431 100.039 121.961 99.1574 117.793 97.429C113.624 95.7006 109.842 93.1599 106.666 89.9549C93.2768 76.5661 93.2768 54.7537 106.666 41.3649C113.172 34.8588 121.767 31.2976 130.944 31.2976C140.12 31.2976 148.75 34.8588 155.221 41.3649C168.61 54.7537 168.61 76.5661 155.221 89.9549C152.045 93.1599 148.263 95.7006 144.094 97.429C139.926 99.1574 135.456 100.039 130.944 100.022Z"
                    fill="#EEF0F6"
                />
                <path
                    d="M157.07 39.55C150.084 32.5645 140.839 28.7294 130.943 28.7294C121.047 28.7294 111.801 32.5645 104.85 39.55C90.468 53.966 90.468 77.3879 104.85 91.7697C111.835 98.7551 121.081 102.59 130.943 102.59C140.804 102.59 150.084 98.7551 157.07 91.7697C171.451 77.3879 171.451 53.966 157.07 39.55ZM153.44 88.14C147.447 94.1324 139.469 97.4539 130.977 97.4539C122.485 97.4539 114.506 94.1324 108.514 88.14C96.118 75.7442 96.118 55.5754 108.514 43.1797C114.506 37.1872 122.485 33.8657 130.977 33.8657C139.469 33.8657 147.447 37.1872 153.44 43.1797C165.836 55.5754 165.836 75.7442 153.44 88.14Z"
                    fill="#1576ED"
                />
                <path
                    d="M217.851 133.888L187.169 103.207C185.77 101.809 184.073 100.745 182.206 100.093C180.339 99.4406 178.348 99.2175 176.383 99.44L172.068 95.1254C186.245 75.3676 184.498 47.6312 166.761 29.8594C147.037 10.1357 114.918 10.1357 95.1599 29.8594C75.4363 49.583 75.4363 81.7024 95.1599 101.46C105.022 111.322 118 116.253 130.943 116.253C141.284 116.253 151.626 113.034 160.392 106.768L164.706 111.082C164.261 115.055 165.597 119.027 168.473 121.869L199.154 152.55C201.722 155.118 205.112 156.419 208.502 156.419C211.892 156.419 215.282 155.118 217.851 152.55C222.987 147.414 222.987 139.058 217.851 133.888ZM98.7896 97.8306C90.1948 89.2357 85.4693 77.833 85.4693 65.677C85.4693 53.5209 90.1948 42.1182 98.7896 33.5233C107.384 24.9285 118.787 20.203 130.943 20.203C143.099 20.203 154.502 24.9285 163.097 33.5233C171.692 42.1182 176.417 53.5209 176.417 65.677C176.417 77.833 171.692 89.2357 163.097 97.8306C154.502 106.425 143.099 111.151 130.943 111.151C118.787 111.151 107.384 106.425 98.7896 97.8306ZM214.221 148.955C211.071 152.105 205.968 152.105 202.818 148.955L172.137 118.273C170.185 116.322 169.397 113.514 169.98 110.808C170.077 110.389 170.065 109.951 169.946 109.538C169.826 109.124 169.602 108.748 169.295 108.446L164.432 103.583C165.22 102.898 166.007 102.248 166.727 101.495C167.48 100.741 168.131 99.9536 168.815 99.2003L173.678 104.063C174.294 104.679 175.184 104.953 176.041 104.748C178.78 104.131 181.554 104.919 183.54 106.905L214.221 137.586C215.762 139.127 216.584 141.147 216.584 143.305C216.584 145.462 215.762 147.414 214.221 148.955Z"
                    fill="#1576ED"
                />
                <path
                    d="M60.1298 139.024L82.901 111.938L105.672 139.024C106.152 139.606 106.871 139.949 107.624 139.949H173.061C174.465 139.949 175.629 138.785 175.629 137.381C175.629 135.977 174.465 134.812 173.061 134.812H108.823L88.414 110.535H90.8452C92.2492 110.535 93.4134 109.37 93.4134 107.966C93.4134 106.562 92.2492 105.398 90.8452 105.398H31.5716C30.8183 105.398 30.0992 105.741 29.6198 106.323L4.89675 135.737C4.24614 136.49 4.10918 137.586 4.52008 138.476C4.93099 139.367 5.85554 139.949 6.84857 139.949H58.178C58.9313 139.915 59.6504 139.572 60.1298 139.024ZM12.3616 134.778L32.7701 110.5H77.388L56.9795 134.778H12.3616Z"
                    fill="#1576ED"
                />
                <path
                    d="M121.834 183.745H94.9881C93.5842 183.745 92.4199 184.909 92.4199 186.313C92.4199 187.717 93.5842 188.881 94.9881 188.881H121.834C123.238 188.881 124.402 187.717 124.402 186.313C124.402 184.909 123.238 183.745 121.834 183.745Z"
                    fill="#1576ED"
                />
                <path
                    d="M121.834 193.709H94.9881C93.5842 193.709 92.4199 194.874 92.4199 196.278C92.4199 197.681 93.5842 198.846 94.9881 198.846H121.834C122.515 198.846 123.169 198.575 123.65 198.094C124.132 197.612 124.402 196.959 124.402 196.278C124.402 195.596 124.132 194.943 123.65 194.462C123.169 193.98 122.515 193.709 121.834 193.709Z"
                    fill="#1576ED"
                />
                <path
                    d="M143.03 53.5893C142.547 53.1117 141.895 52.8439 141.215 52.8439C140.536 52.8439 139.884 53.1117 139.401 53.5893L130.943 62.0472L122.485 53.5893C121.492 52.5963 119.848 52.5963 118.855 53.5893C117.862 54.5824 117.862 56.226 118.855 57.219L127.313 65.6769L118.855 74.1348C117.862 75.1278 117.862 76.7715 118.855 77.7645C119.369 78.2781 120.019 78.5178 120.67 78.5178C121.321 78.5178 121.971 78.2781 122.485 77.7645L130.943 69.3066L139.401 77.7645C139.914 78.2781 140.565 78.5178 141.215 78.5178C141.866 78.5178 142.517 78.2781 143.03 77.7645C144.023 76.7715 144.023 75.1278 143.03 74.1348L134.572 65.6769L143.03 57.219C144.058 56.226 144.058 54.5824 143.03 53.5893Z"
                    fill="#1576ED"
                />
                <path
                    d="M174.054 145.051C172.65 145.051 171.486 146.215 171.486 147.619V205.797H85.4687V123.889C85.4687 122.485 84.3044 121.321 82.9005 121.321C81.4966 121.321 80.3323 122.485 80.3323 123.889V205.797H34.1393V147.619C34.1393 146.215 32.9751 145.051 31.5711 145.051C30.1672 145.051 29.0029 146.215 29.0029 147.619V208.365C29.0029 209.769 30.1672 210.933 31.5711 210.933H174.054C175.458 210.933 176.622 209.769 176.622 208.365V147.619C176.622 146.215 175.458 145.051 174.054 145.051Z"
                    fill="#1576ED"
                />
            </g>
        </svg>
    );
};

export const ICNoResultV2: React.FC = () => {
    return (
        <svg
            width="128"
            height="128"
            viewBox="0 0 128 128"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.2" clipPath="url(#clip0_3003_18805)">
                <path
                    d="M125.699 48.2346V118.019H31.8625C30.2908 118.019 29.0195 116.745 29.0195 115.176V38.6208C29.0195 37.0491 30.2908 35.7779 31.8625 35.7779H66.34C67.0924 35.7779 67.8149 36.0758 68.3487 36.6096L76.299 44.5599C76.8328 45.0937 77.5553 45.3917 78.3101 45.3917H122.856C124.425 45.3917 125.699 46.6654 125.699 48.2346Z"
                    fill="white"
                />
                <path
                    d="M87.3335 52.3224C87.3335 56.15 84.2306 59.2529 80.4029 59.2529C76.5753 59.2529 73.4727 56.15 73.4727 52.3224C73.4727 48.4948 76.5753 45.3919 80.4029 45.3919C84.2306 45.3919 87.3335 48.4948 87.3335 52.3224Z"
                    fill="#B9D7FF"
                />
                <path
                    d="M54.9634 68.6812C56.2148 69.9301 55.3309 72.0703 53.5605 72.0703H31.6762C30.7799 72.0703 29.8935 72.0256 29.0195 71.9412V38.6206C29.0195 37.049 30.2908 35.7777 31.8625 35.7777H56.5698C57.7219 38.7597 58.355 42.0024 58.355 45.3915C58.355 52.7583 55.3681 59.4274 50.5413 64.2566L54.9634 68.6812Z"
                    fill="#B9D7FF"
                />
                <path
                    d="M125.699 48.2346V118.019H116.259V48.2346C116.259 46.6654 114.988 45.3917 113.416 45.3917H122.856C124.425 45.3917 125.699 46.6654 125.699 48.2346Z"
                    fill="#B9D7FF"
                />
                <path
                    d="M101.94 45.3927H78.3099C77.5559 45.3926 76.8328 45.0931 76.2997 44.5599L68.3495 36.6099C68.0855 36.3459 67.7722 36.1366 67.4273 35.9937C67.0824 35.8509 66.7128 35.7774 66.3395 35.7774H31.8622C30.2923 35.7774 29.0195 37.0499 29.0195 38.62V115.176C29.0195 116.746 30.2923 118.019 31.8622 118.019H125.699V48.2353C125.699 46.6651 124.426 45.3927 122.857 45.3927H111.133"
                    stroke="#1576ED"
                    strokeWidth="3.72435"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M96.7047 82.3698L125.699 118.02H30.3707C29.9483 118.02 29.5311 117.926 29.1496 117.744C28.7682 117.563 28.4319 117.299 28.1654 116.971L2.94158 85.9568C1.43073 84.0993 2.75263 81.3207 5.1469 81.3207H94.4994C94.9218 81.3208 95.3389 81.4149 95.7204 81.5964C96.1019 81.7778 96.4381 82.042 96.7047 82.3698Z"
                    fill="#7CB7FF"
                />
                <path
                    d="M125.699 118.019H113.473L84.4802 82.3695C84.2135 82.042 83.8773 81.7779 83.496 81.5962C83.1147 81.4146 82.6978 81.32 82.2754 81.3193H94.4987C95.3553 81.3193 96.1647 81.7066 96.7035 82.3695L125.699 118.019Z"
                    fill="#B9D7FF"
                />
                <path
                    d="M63.6064 81.3207H5.14698C2.75272 81.3207 1.43082 84.0993 2.94142 85.9568L28.1652 116.971C28.4318 117.299 28.7681 117.563 29.1496 117.744C29.5312 117.926 29.9483 118.02 30.3708 118.02H125.699L96.7048 82.3698C96.4382 82.042 96.102 81.7778 95.7205 81.5964C95.339 81.4149 94.9219 81.3208 94.4994 81.3207H72.7996"
                    stroke="#1576ED"
                    strokeWidth="3.72435"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M104.111 109.658H79.0287C78.6462 109.658 78.2689 109.569 77.9261 109.399C77.5834 109.23 77.2845 108.983 77.0528 108.678L70.6251 100.233C70.0029 99.4152 70.5859 98.2391 71.6128 98.2391H96.6948C97.0773 98.2391 97.4546 98.3275 97.7973 98.4974C98.14 98.6672 98.4388 98.914 98.6705 99.2184L105.098 107.664C105.72 108.482 105.138 109.658 104.111 109.658Z"
                    fill="#F8F3F1"
                />
                <path
                    d="M104.11 109.657H96.0409C97.0688 109.657 97.6523 108.483 97.0291 107.663L90.6009 99.2191C90.3698 98.9144 90.0714 98.6673 89.729 98.4972C89.3865 98.327 89.0093 98.2384 88.627 98.2384H96.6939C97.4711 98.2384 98.201 98.6009 98.6703 99.2191L105.099 107.663C105.719 108.483 105.138 109.657 104.11 109.657Z"
                    fill="#EEE2DD"
                />
                <path
                    d="M104.111 109.658H79.0287C78.6462 109.658 78.2689 109.569 77.9261 109.399C77.5834 109.23 77.2845 108.983 77.0528 108.678L70.6251 100.233C70.0029 99.4152 70.5859 98.2391 71.6128 98.2391H96.6948C97.0773 98.2391 97.4546 98.3275 97.7973 98.4974C98.14 98.6672 98.4388 98.914 98.6705 99.2184L105.098 107.664C105.72 108.482 105.138 109.658 104.111 109.658Z"
                    stroke="#1576ED"
                    strokeWidth="3.72435"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M63.9988 109.657H52.6055"
                    stroke="#1576ED"
                    strokeWidth="3.72435"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M59.8418 103.481H55.0059"
                    stroke="#1576ED"
                    strokeWidth="3.72435"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M58.3556 36.6596C58.3556 21.9253 46.4111 9.9808 31.6768 9.9808C16.9565 9.9808 4.9978 21.9392 4.99805 36.6598C4.9983 51.3941 16.9428 63.3384 31.6768 63.3384H53.5609C55.3302 63.3384 56.2164 61.1991 54.9652 59.948L50.5414 55.5244C55.3694 50.6964 58.3556 44.0266 58.3556 36.6596Z"
                    fill="white"
                />
                <path
                    d="M38.3652 29.9646C38.0928 29.6954 37.7253 29.5444 37.3424 29.5444C36.9594 29.5444 36.5919 29.6954 36.3195 29.9646L31.5526 34.7314L26.7858 29.9646C26.2261 29.4049 25.2997 29.4049 24.7401 29.9646C24.1804 30.5242 24.1804 31.4506 24.7401 32.0103L29.5069 36.7771L24.7401 41.544C24.1804 42.1037 24.1804 43.03 24.7401 43.5897C25.0296 43.8792 25.3962 44.0143 25.7629 44.0143C26.1296 44.0143 26.4963 43.8792 26.7858 43.5897L31.5526 38.8228L36.3195 43.5897C36.609 43.8792 36.9757 44.0143 37.3424 44.0143C37.709 44.0143 38.0757 43.8792 38.3652 43.5897C38.9249 43.03 38.9249 42.1037 38.3652 41.544L33.5983 36.7771L38.3652 32.0103C38.9442 31.4506 38.9442 30.5242 38.3652 29.9646Z"
                    fill="#1576ED"
                />
                <path
                    d="M54.9634 59.9492C56.2148 61.2006 55.3308 63.3384 53.5605 63.3384H44.5625C46.3303 63.3384 47.2167 61.2006 45.9653 59.9492L43.2222 57.2046C42.3098 56.2917 42.2519 54.8342 43.0837 53.8475C46.9975 49.2054 49.357 43.2073 49.357 36.6596C49.357 23.4605 39.7705 12.4985 27.1797 10.3607C28.4135 10.1498 29.6607 10.0264 30.912 9.99148C45.8322 9.57137 58.103 21.2812 58.3513 36.2055C58.4769 43.752 55.4672 50.5989 50.5413 55.5247L54.9634 59.9492Z"
                    fill="#B9D7FF"
                />
                <path
                    d="M47.2975 15.0318C42.9065 11.8549 37.5109 9.9808 31.6768 9.9808C16.9562 9.9808 4.9978 21.9392 4.99805 36.6598C4.99805 51.3941 16.9425 63.3384 31.6766 63.3384H53.5609C55.3302 63.3384 56.2164 61.1991 54.9652 59.948L50.5414 55.5242C55.3694 50.6964 58.3556 44.0266 58.3556 36.6596C58.3556 31.0795 56.6414 25.9007 53.7126 21.6182"
                    stroke="#1576ED"
                    strokeWidth="3.72435"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M87.3335 46.4557C87.3335 50.2833 84.2306 53.3862 80.4029 53.3862C76.5753 53.3862 73.4727 50.2833 73.4727 46.4557C73.4727 42.6281 76.5753 39.5252 80.4029 39.5252C84.2306 39.5252 87.3335 42.6281 87.3335 46.4557Z"
                    fill="white"
                />
                <path
                    d="M87.3337 46.4549C87.3337 50.2838 84.2301 53.3877 80.4042 53.3877C79.1673 53.3903 77.9527 53.0588 76.8887 52.428C79.6092 51.8009 81.6362 49.3654 81.6362 46.4549C81.6362 43.5474 79.6124 41.1087 76.8919 40.4818C77.9556 39.8534 79.1688 39.523 80.4042 39.5251C84.2301 39.5251 87.3337 42.629 87.3337 46.4549Z"
                    fill="#B9D7FF"
                />
                <path
                    d="M87.3335 46.4557C87.3335 50.2833 84.2306 53.3862 80.4029 53.3862C76.5753 53.3862 73.4727 50.2833 73.4727 46.4557C73.4727 42.6281 76.5753 39.5252 80.4029 39.5252C84.2306 39.5252 87.3335 42.6281 87.3335 46.4557Z"
                    stroke="#1576ED"
                    strokeWidth="3.72435"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M110.145 32.6769C110.145 35.6976 107.697 38.146 104.676 38.146C101.656 38.146 99.207 35.6976 99.207 32.6769C99.207 29.6565 101.656 27.2078 104.676 27.2078C107.697 27.2078 110.145 29.6565 110.145 32.6769Z"
                    fill="white"
                />
                <path
                    d="M110.143 32.6764C110.143 35.6981 107.694 38.1474 104.675 38.1474C103.699 38.1495 102.74 37.8878 101.9 37.3899C104.047 36.8951 105.647 34.9731 105.647 32.6764C105.647 30.3819 104.05 28.4574 101.903 27.9626C102.742 27.4669 103.7 27.2063 104.675 27.208C107.694 27.208 110.143 29.6572 110.143 32.6764Z"
                    fill="#B9D7FF"
                />
                <path
                    d="M110.145 32.6769C110.145 35.6976 107.697 38.146 104.676 38.146C101.656 38.146 99.207 35.6976 99.207 32.6769C99.207 29.6565 101.656 27.2078 104.676 27.2078C107.697 27.2078 110.145 29.6565 110.145 32.6769Z"
                    stroke="#1576ED"
                    strokeWidth="3.72435"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M91.8692 20.3564L87.2207 25.0049"
                    stroke="#1576ED"
                    strokeWidth="3.72435"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M91.8692 25.0049L87.2207 20.3564"
                    stroke="#1576ED"
                    strokeWidth="3.72435"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.9249 66.2724L8.7832 72.4141"
                    stroke="#1576ED"
                    strokeWidth="3.72435"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.9249 72.4141L8.7832 66.2724"
                    stroke="#1576ED"
                    strokeWidth="3.72435"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_3003_18805">
                    <rect
                        width="127.125"
                        height="127.125"
                        fill="white"
                        transform="translate(0.4375 0.4375)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
