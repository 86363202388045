export enum Direction {
    ASC = 'ASC',
    DESC = 'DESC',
}

export type SearchQueryParams = {
    page: number;
    pageSize: number;
    sortBy: Direction;
    orderBy?: string;

    searchWord?: string;
    searchColum?: string;
};

export interface IPagination {
    page: number;
    pageSize: number;
    totalCount?: number;
    totalPage?: number;

    order?: Direction;
    orderBy?: string;
}
