import { IconProps } from './type';

export const ICWarning: React.FC<IconProps> = ({
    width = 24,
    height = 24,
    fill = '#E9A743',
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.9 19.63L12.81 2.37C12.7428 2.25752 12.6476 2.16434 12.5337 2.09951C12.4198 2.03468 12.2911 2.0004 12.16 2H11.86C11.729 2.0004 11.6002 2.03468 11.4864 2.09951C11.3725 2.16434 11.2773 2.25752 11.21 2.37L1.10002 19.63C1.03612 19.7465 1.00262 19.8772 1.00262 20.01C1.00262 20.1428 1.03612 20.2735 1.10002 20.39L1.24002 20.62C1.30361 20.7364 1.39773 20.8333 1.51225 20.9003C1.62677 20.9672 1.75737 21.0017 1.89002 21H22.11C22.2427 21.0017 22.3733 20.9672 22.4878 20.9003C22.6023 20.8333 22.6964 20.7364 22.76 20.62L22.9 20.39C22.9639 20.2735 22.9974 20.1428 22.9974 20.01C22.9974 19.8772 22.9639 19.7465 22.9 19.63ZM13 16.5C13 16.6326 12.9473 16.7598 12.8536 16.8536C12.7598 16.9473 12.6326 17 12.5 17H11.5C11.3674 17 11.2402 16.9473 11.1465 16.8536C11.0527 16.7598 11 16.6326 11 16.5V15.5C11 15.3674 11.0527 15.2402 11.1465 15.1464C11.2402 15.0527 11.3674 15 11.5 15H12.5C12.6326 15 12.7598 15.0527 12.8536 15.1464C12.9473 15.2402 13 15.3674 13 15.5V16.5ZM12.78 12.78C12.7727 12.841 12.7431 12.8971 12.697 12.9377C12.6509 12.9783 12.5914 13.0004 12.53 13H11.47C11.4086 13.0004 11.3492 12.9783 11.3031 12.9377C11.2569 12.8971 11.2274 12.841 11.22 12.78L10.82 9.56C10.8115 9.48948 10.8181 9.41796 10.8394 9.35019C10.8607 9.28242 10.8961 9.21996 10.9434 9.16698C10.9907 9.11399 11.0488 9.0717 11.1137 9.0429C11.1787 9.01411 11.249 8.99949 11.32 9H12.68C12.7511 8.99949 12.8214 9.01411 12.8863 9.0429C12.9512 9.0717 13.0093 9.11399 13.0566 9.16698C13.1039 9.21996 13.1394 9.28242 13.1607 9.35019C13.1819 9.41796 13.1885 9.48948 13.18 9.56L12.78 12.78Z"
                fill={fill}
            />
        </svg>
    );
};
