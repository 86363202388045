import { Drawer, MenuProps } from 'antd';
import React from 'react';
import { Navbar } from './navbar';

type MenuItem = Required<MenuProps>['items'][number];

type MobileSiderProps = {
    collapsed: boolean;
    setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;

    openKeys: string[];
    setOpenKeys: React.Dispatch<React.SetStateAction<string[]>>;
    items: MenuItem[];
    selectedMenu: string;
    onClickMenu: MenuProps['onClick'];
};

export const MobileSider: React.FC<MobileSiderProps> = ({
    collapsed,
    setCollapsed,
    openKeys,
    setOpenKeys,
    items,
    selectedMenu,
    onClickMenu,
}) => {
    React.useEffect(() => {
        setCollapsed(false);
    }, []);

    return (
        <Drawer
            placement="left"
            onClose={() => setCollapsed(false)}
            closable={false}
            open={collapsed}
            className="hideOnDesktop"
            destroyOnClose
            width={250}
            styles={{
                wrapper: {
                    padding: '0',
                    margin: '0',
                    marginTop: '60px',
                },
                content: {
                    padding: '0',
                    width: '250px',
                    overflow: 'hidden',
                },
                mask: {
                    marginTop: '60px',
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                },
                body: {
                    padding: '0',
                },
            }}
        >
            <Navbar
                openKeys={openKeys}
                items={items}
                selectedMenu={selectedMenu}
                onClickMenu={onClickMenu}
                setOpenKeys={setOpenKeys}
            />
        </Drawer>
    );
};
