import { IconProps } from './type';

export const ICCamera: React.FC<IconProps> = ({
    height = 24,
    width = 24,
    fill = 'currentColor',
}) => {
    return (
        <svg
            id="Layer_2"
            viewBox="0 0 32 32"
            width={width}
            height={height}
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="m2 7v-2c0-1.654 1.346-3 3-3h2c.552 0 1 .448 1 1s-.448 1-1 1h-2c-.551 0-1 .449-1 1v2c0 .552-.448 1-1 1s-1-.448-1-1zm5 21h-2c-.551 0-1-.449-1-1v-2c0-.552-.448-1-1-1s-1 .448-1 1v2c0 1.654 1.346 3 3 3h2c.552 0 1-.448 1-1s-.448-1-1-1zm20-26h-2c-.552 0-1 .448-1 1s.448 1 1 1h2c.551 0 1 .449 1 1v2c0 .552.448 1 1 1s1-.448 1-1v-2c0-1.654-1.346-3-3-3zm2 22c-.552 0-1 .448-1 1v2c0 .551-.449 1-1 1h-2c-.552 0-1 .448-1 1s.448 1 1 1h2c1.654 0 3-1.346 3-3v-2c0-.552-.448-1-1-1zm-13-9c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2zm7 9h-14c-1.654 0-3-1.346-3-3v-8c0-1.654 1.346-3 3-3h1.367c.292 0 .569-.127.758-.349l.516-.603c.571-.667 1.401-1.049 2.279-1.049h4.207c.832 0 1.634.35 2.2.96l.668.721c.188.202.455.319.732.319h1.272c1.654 0 3 1.346 3 3v8c0 1.654-1.346 3-3 3zm-3-7c0-2.206-1.794-4-4-4s-4 1.794-4 4 1.794 4 4 4 4-1.794 4-4z" />
        </svg>
    );
};
