import { Form, Input, InputNumber } from 'antd';
import React from 'react';
import { FormInstance } from 'antd/lib/form/Form';
import styled from 'styled-components';
import dayjs from 'dayjs';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import { TService } from '../start-new-task/type';
import { AutoTrackForm } from '../../components/form/form';
import { AutoTrackInput } from '../../components/form/input';

dayjs.extend(customParseFormat);

const Wrapper = styled.div`
    .ant-row {
        align-items: center;
    }
    .ant-form-item-has-error {
        .ant-row {
            align-items: flex-start;
        }
    }
`;

type ServiceFormProps = {
    serviceData: TService | undefined;
    form: FormInstance;
};
export const ServiceForm: React.FC<ServiceFormProps> = ({
    serviceData,
    form,
}) => {
    const childForm = (
        <div className="grid gap-[10px] grid-cols-[1fr]">
            <Form.Item
                name="name"
                label="Name"
                rules={[
                    {
                        required: true,
                        message: 'Please input the name!',
                    },
                ]}
            >
                <AutoTrackInput isRequired={true} />
            </Form.Item>
            <Form.Item
                name="price"
                label="Price"
                rules={[
                    {
                        required: true,
                        message: 'Please input the price!',
                    },
                ]}
            >
                <InputNumber
                    min={0}
                    size="large"
                    style={{ width: '100%' }}
                    formatter={value =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                />
            </Form.Item>
            <Form.Item name="description" label="Description">
                <Input.TextArea />
            </Form.Item>
        </div>
    );

    React.useEffect(() => {
        if (serviceData) {
            form.setFieldsValue({
                name: serviceData.name,
                price: serviceData.price,
                description: serviceData.description,
            });
        }
    }, [serviceData, form]);

    return (
        <Wrapper className="flex flex-col gap-[20px] w-full overflow-hidden h-full items-start box-border py-[20px] px-[5px]">
            <AutoTrackForm
                labelCol={{ flex: '130px' }}
                labelAlign="left"
                form={form}
                labelWrap
                wrapperCol={{ flex: 1 }}
                layout="horizontal"
                className="w-full"
                name="createService"
                childNode={childForm}
            />
        </Wrapper>
    );
};
