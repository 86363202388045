import React from 'react';
import { AutoComplete, Calendar, theme } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import styled from 'styled-components';
import { AutoTrackButton } from '../../../components/button';
import { useNotification } from '../../../hooks/useNotification';

const WrapperTime = styled.div`
    position: relative;
    .ant-select-selector {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        .ant-select-selection-search {
            width: 100%;
            inset-inline-end: 0;
            inset-inline-start: 0;
            input {
                text-align: center;
            }
        }
    }
`;

type TTime = {
    value: string;
    label: string;
};

type DatetimeControlProps = {
    onSelectDate: (value: string) => void;
};

export const DatetimeControl: React.FC<DatetimeControlProps> = ({
    onSelectDate,
}) => {
    const { showError } = useNotification();
    const { token } = theme.useToken();
    const wrapperStyle: React.CSSProperties = {
        border: `1px solid ${token.colorBorderSecondary}`,
        borderRadius: token.borderRadiusLG,
    };
    const [selectedDate, setSelectedDate] = React.useState<Dayjs | undefined>(
        undefined
    );
    const [hourOptions, setHourOptions] = React.useState<TTime[]>([]);
    const [minuteOptions, setMinuteOptions] = React.useState<TTime[]>([]);
    const [selectedHours, setSelectedHours] = React.useState<TTime | null>(
        null
    );
    const [selectedMinutes, setSelectedMinutes] = React.useState<TTime | null>(
        null
    );
    const timeListTemp: TTime[] = Array.from({ length: 24 }).map(
        (_, index) => ({
            value: index < 10 ? `0${index}` : `${index}`,
            label: index < 10 ? `0${index}` : `${index}`,
        })
    );
    const minutesListTemp: TTime[] = Array.from({ length: 60 }).map(
        (_, index) => ({
            value: index < 10 ? `0${index}` : `${index}`,
            label: index < 10 ? `0${index}` : `${index}`,
        })
    );

    React.useEffect(() => {
        const nowDate = new Date();
        setSelectedDate(dayjs(nowDate));
        setHourOptions(timeListTemp);
        setMinuteOptions(minutesListTemp);
    }, []);

    const handleSelectHour = (time: any) => {
        setSelectedHours({
            value: time,
            label: time,
        });
    };

    const handleSelectMinutes = (time: any) => {
        setSelectedMinutes({
            value: time,
            label: time,
        });
    };

    const renderHoursColumn = () => {
        return (
            <div className="flex flex-col items-center w-full">
                <div className="flex text-center items-center justify-center min-h-[48px] border-b header">
                    <h4 className="text-body">Hours</h4>
                </div>
                <WrapperTime className="flex flex-col text-center gap-3 items-center justify-center w-full">
                    <AutoComplete
                        options={hourOptions}
                        value={selectedHours}
                        onSelect={value => handleSelectHour(value)}
                        maxLength={2}
                        onSearch={value => {
                            const filtered = timeListTemp.filter(item =>
                                item.value.includes(value)
                            );
                            setHourOptions(filtered);
                            handleSelectHour(value);
                        }}
                        filterOption={(inputValue, option) =>
                            option?.value?.toString().indexOf(inputValue) !== -1
                        }
                        style={{
                            width: '50px',
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        allowClear={false}
                    />
                </WrapperTime>
            </div>
        );
    };

    const renderMinutesColumn = () => {
        return (
            <div className="flex flex-col items-center w-full">
                <div className="flex text-center items-center justify-center min-h-[48px] border-b header">
                    <h4 className="text-body">Minutes</h4>
                </div>
                <WrapperTime className="flex flex-col text-center gap-3 items-center justify-center w-full">
                    <AutoComplete
                        options={minuteOptions}
                        value={selectedMinutes}
                        onSelect={value => handleSelectMinutes(value)}
                        maxLength={2}
                        onSearch={value => {
                            const filtered = minuteOptions.filter(item =>
                                item.value.includes(value)
                            );
                            setMinuteOptions(filtered);
                            handleSelectMinutes(value);
                        }}
                        filterOption={(inputValue, option) =>
                            option?.value?.toString().indexOf(inputValue) !== -1
                        }
                        style={{
                            width: '50px',
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        allowClear={false}
                    />
                </WrapperTime>
            </div>
        );
    };

    const onSubmit = React.useCallback(() => {
        if (!selectedHours || !selectedMinutes) {
            showError('Warning', 'Please select hours and minutes');
            return;
        }
        if (selectedHours?.value === null || selectedMinutes?.value === null) {
            return;
        }
        const selectedDatetime = selectedDate
            ?.hour(Number(selectedHours?.value))
            .minute(Number(selectedMinutes?.value));
        const formattedDatetime = selectedDatetime?.format('YYYY-MM-DD HH:mm');
        if (!formattedDatetime) {
            return;
        }
        onSelectDate(formattedDatetime);
    }, [selectedHours, selectedDate, selectedMinutes]);

    const onSelectCalendar = (value: Dayjs) => {
        setSelectedDate(value);
    };

    return (
        <div className="flex flex-col bg-white rounded-[8px] gap-[20px]">
            <div className="max-md:grid max-md:grid-cols-[240px_1fr] gap-3 flex flex-row">
                <div
                    className="flex max-md:w-[240px] w-[300px]"
                    style={wrapperStyle}
                >
                    <Calendar
                        fullscreen={false}
                        value={selectedDate}
                        onSelect={value => onSelectCalendar(value)}
                    />
                </div>
                <div className=" max-md:grid max-md:grid-cols-1 max-md:grid-rows-[80px_1fr] max-md:grid-flow-col flex max-md:flex-wrap max-md:max-h-full max-md:gap-0 max-md:px-[2px] items-start justify-start max-h-[100px] border rounded-[6px] px-[10px] gap-2">
                    {renderHoursColumn()}
                    {renderMinutesColumn()}
                </div>
            </div>
            <div>
                <AutoTrackButton
                    type="primary"
                    btnSize="sm"
                    btnType="primary"
                    classess="max-md:w-full"
                    onClick={onSubmit}
                >
                    Done
                </AutoTrackButton>
            </div>
        </div>
    );
};
