import { Radio, Tag } from 'antd';
import React from 'react';
import {
    EStatusOfService,
    TCarInService,
    TServiceDetail,
    StatusOfServiceColorMapping,
} from '../start-new-task/type';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import classNames from 'classnames';
import { AutoTrackButton } from '../../components/button';

type ServicesActionsPageProps = {
    carInServiceSelected: TCarInService;
    service: TServiceDetail;
    oneService: boolean;
    size?: SizeType;
    mobileMode: boolean;
    onUpdateStatus: (
        status: EStatusOfService,
        serviceSelection: TServiceDetail,
        task: TCarInService,
        forceReload?: boolean
    ) => void;
};

export const ServicesActionsPage: React.FC<ServicesActionsPageProps> = ({
    service,
    size,
    oneService,
    mobileMode,
    onUpdateStatus,
    carInServiceSelected,
}) => {
    const [actionOptions, setActionOptions] = React.useState<
        { label: string; value: EStatusOfService }[]
    >([]);
    const [value, setValue] = React.useState<EStatusOfService | undefined>();

    React.useEffect(() => {
        if (
            !service?.serviceDetailStatus ||
            service?.serviceDetailStatus === undefined
        ) {
            setActionOptions([
                { label: 'Start', value: EStatusOfService.START },
            ]);
            return;
        }
        if (service?.serviceDetailStatus === EStatusOfService.START) {
            setActionOptions([
                { label: 'Pause', value: EStatusOfService.PAUSE },
                { label: 'Done', value: EStatusOfService.DONE },
            ]);
        }
        if (service?.serviceDetailStatus === EStatusOfService.PAUSE) {
            setActionOptions([
                { label: 'UnPause', value: EStatusOfService.UNPAUSE },
                { label: 'Done', value: EStatusOfService.DONE },
            ]);
        }
        if (service?.serviceDetailStatus === EStatusOfService.UNPAUSE) {
            setActionOptions([
                { label: 'Pause', value: EStatusOfService.PAUSE },
                { label: 'Done', value: EStatusOfService.DONE },
            ]);
        }
    }, [service]);

    const onChange = (e: any) => {
        setValue(e.target.value);
        const isForceReload: boolean = !!oneService;
        onUpdateStatus(
            e.target.value,
            service,
            carInServiceSelected,
            isForceReload
        );
    };

    const hasUserAssigned = React.useMemo(() => {
        if (!service) return false;
        if (!service.user) return false;
        return (
            service?.user !== undefined && Object.keys(service?.user).length > 0
        );
    }, [service.user]);

    const doneService = React.useMemo(() => {
        return service?.serviceDetailStatus === EStatusOfService.DONE;
    }, [service.serviceDetailStatus]);

    const renderNoneMobile = React.useMemo(() => {
        if (mobileMode) return null;
        return (
            <>
                {hasUserAssigned && !doneService && (
                    <Radio.Group
                        size={oneService ? 'large' : (size ?? 'small')}
                        options={actionOptions}
                        onChange={onChange}
                        value={value}
                        buttonStyle="solid"
                        optionType="button"
                    />
                )}
                {doneService && <Tag color="green">Done</Tag>}
            </>
        );
    }, [
        mobileMode,
        oneService,
        doneService,
        hasUserAssigned,
        actionOptions,
        value,
    ]);

    const renderMobile = React.useMemo(() => {
        if (!mobileMode) return null;
        return (
            <div
                className={classNames([
                    'grid w-full',
                    {
                        'grid-cols-2 gap-2': actionOptions.length > 1,
                    },
                    {
                        'grid-cols-1 pt-0': actionOptions.length === 1,
                    },
                ])}
            >
                {actionOptions.map((item, index) => {
                    return (
                        <AutoTrackButton
                            btnSize="md"
                            classess="w-full"
                            style={{
                                height: '40px',
                                width: '100%',
                                backgroundColor:
                                    StatusOfServiceColorMapping[item.value],
                            }}
                            key={index}
                            className={classNames([
                                'flex-1 border border-gray-300 rounded-md py-1',
                                {
                                    'mr-1': index === 0,
                                },
                                {
                                    'ml-1': index === actionOptions.length - 1,
                                },
                            ])}
                            onClick={() => {
                                onChange({
                                    target: {
                                        value: item.value,
                                    },
                                });
                            }}
                        >
                            {item.label}
                        </AutoTrackButton>
                    );
                })}
            </div>
        );
    }, [mobileMode, actionOptions, value]);

    return !hasUserAssigned && !doneService ? null : (
        <div
            className={classNames([
                'flex flex-col items-end justify-end relative',
                {
                    'min-w-[120px]': !mobileMode,
                },
                {
                    'min-w-[100%] w-full items-center justify-center':
                        mobileMode,
                },
            ])}
        >
            {renderMobile}
            {renderNoneMobile}
        </div>
    );
};
