import React from 'react';
import {
    EServiceRecordStatus,
    TPaging,
    TSearchCarInService,
    TServiceRecordRequest,
} from '../../api/car-in-service/type';
import { useDispatch, useSelector } from 'react-redux';
import { removeUndefinedAttribute } from '../../utils';
import { setLoading } from '../../redux/slices/appInfo';
import { SearchParamsStateType, useFilter } from '../../hooks/useFilter';
import { DEFAULT_PAGE_SIZE_LOAD_MORE } from '../../utils/constants';
import { Direction } from '../../interface/paging';
import {
    addServiceRecord,
    searchCarInService,
    updateCarInService,
} from '../../api/car-in-service';
import {
    EPriorityCarInService,
    EPriorityCarInServiceOptions,
    EStatusCarInService,
    EStatusOfService,
    EUpdateType,
    StatusOptions,
    TCarInService,
    TCarInServiceRequest,
    TServiceDetail,
    TServiceRequest,
} from '../start-new-task/type';
import { TaskTablePage } from './task-table';
import { Drawer, Segmented } from 'antd';
import { useScreenSize } from '../../hooks/useScreenSize';
import { ServiceList } from '../all-tasks/edit';
import { FieldsetPage } from '../all-tasks/fieldset';
import { FilterTaskPage } from '../all-tasks/filter';
import { ViewMode } from '../all-tasks/type';
import { RootState } from '../../redux/reducers';
import styled from 'styled-components';
import { useNotification } from '../../hooks/useNotification';
import { TaskListPage } from '../components/task-list';
import { ICNoResults } from '../../icons';

const WrapperSegmented = styled.div`
    .active-primary {
        .ant-segmented-item-selected {
            background-color: var(--primaryColor);
            color: white;
        }
    }
`;

type TSortData = {
    field?: string;
    order?: 'asc' | 'desc';
};
type TSortDataContext = {
    sortData: TSortData;
    setSortData: (data: TSortData) => void;
};
const SortDataContext = React.createContext<TSortDataContext>(
    {} as TSortDataContext
);
export const useSortData = () => React.useContext(SortDataContext);

export const MyToDoListPage: React.FC = () => {
    const dispatch = useDispatch();
    const size = useScreenSize();
    const { showSuccess } = useNotification();
    const userInfo = useSelector((state: RootState) => state.userInfo);
    const mobileMode = size === 'xs' || size === 'sm' || size === 'md';
    const filtersDefaults: SearchParamsStateType = {
        pageNum: { type: 'number', default: 1 },
        pageSize: { type: 'number', default: DEFAULT_PAGE_SIZE_LOAD_MORE },
        sortBy: { type: 'string', default: Direction.DESC },
        orderBy: { type: 'string', default: 'CarId' },
        status: { type: 'string', default: '' },
        priority: { type: 'string', default: '' },
    };
    const [viewMode, setViewMode] = React.useState<ViewMode>(ViewMode.LIST);
    const [filterParams, setFilterParams] = useFilter(filtersDefaults);
    const [pagination, setPagination] = React.useState<TPaging | undefined>(
        undefined
    );
    const [openEditTask, setOpenEditTask] = React.useState<boolean>(false);
    const [carInServiceSelected, setCarInServiceSelected] = React.useState<
        TCarInService | undefined
    >(undefined);
    const [prioritySelectedControl, setPrioritySelectedControl] =
        React.useState<string>('All');
    const [carInServices, setCarInServices] = React.useState<TCarInService[]>(
        []
    );
    const [hasLoadMore, setHasLoadMore] = React.useState<boolean>(false);
    const priorityOptions = EPriorityCarInServiceOptions.map(option => ({
        ...option,
    }));
    const [statusSelectedControl, setStatusSelectedControl] =
        React.useState<string>();
    const statusOptions = StatusOptions.map(option => ({
        ...option,
    }));
    const [prioritySelected, setPrioritySelected] =
        React.useState<string>('All');
    const [statusSelected, setStatusSelected] = React.useState<string>(
        EStatusCarInService.INPROGRESS
    );

    const loadData = React.useCallback(
        async (queryParams: TSearchCarInService) => {
            try {
                dispatch(setLoading(true));
                const userId = userInfo?.userId;
                if (queryParams.priority === 'All') {
                    queryParams.priority = undefined;
                }
                const payload = removeUndefinedAttribute(queryParams);
                const newPayload = {
                    ...payload,
                    userId,
                };
                setStatusSelected(newPayload.status as EStatusCarInService);
                setFilterParams(newPayload);
                const result = await searchCarInService(newPayload);
                if (result.status === 200) {
                    setCarInServices(result.data?.data ?? []);
                    setPagination({
                        currentPage: result.data?.currentPage ?? 1,
                        pageSize:
                            result.data?.pageSize ??
                            DEFAULT_PAGE_SIZE_LOAD_MORE,
                        totalPages: result.data?.totalPages ?? 0,
                        totalRecords: result.data?.totalRecords ?? 0,
                    });
                    const isLoadMore =
                        result.data?.currentPage * result.data?.pageSize <
                        result.data?.totalRecords;
                    setHasLoadMore(isLoadMore);
                }
            } catch (error) {
                // Handle error
            } finally {
                dispatch(setLoading(false));
            }
        },
        [dispatch]
    );

    React.useEffect(() => {
        loadData({
            page: 1,
            pageSize: DEFAULT_PAGE_SIZE_LOAD_MORE,
            orderBy: 'CarId',
            status:
                (filterParams.status as EStatusCarInService) || statusSelected,
            priority: (filterParams.priority as string) || prioritySelected,
        });
    }, []);

    const onChangeStatus = (status: EStatusCarInService) => {
        setStatusSelected(status);
        loadData({
            page: 1,
            pageSize: DEFAULT_PAGE_SIZE_LOAD_MORE,
            sortBy: Direction.DESC,
            orderBy: 'CarId',
            status: status,
            priority: prioritySelected,
        });
    };

    const onChangePriority = (priority: string) => {
        setPrioritySelected(priority);
        loadData({
            page: 1,
            pageSize: DEFAULT_PAGE_SIZE_LOAD_MORE,
            sortBy: Direction.DESC,
            status: statusSelected,
            priority: priority,
        });
    };

    const onReloadData = React.useCallback(() => {
        loadData({
            page: pagination?.currentPage ?? 1,
            pageSize: pagination?.pageSize ?? DEFAULT_PAGE_SIZE_LOAD_MORE,
            sortBy: Direction.DESC,
            status: statusSelected,
            priority: prioritySelected,
        });
    }, [filterParams, pagination, statusSelected, prioritySelected]);

    const onChangePaging = React.useCallback(
        (values: any) => {
            const searchQueryParams: TSearchCarInService = {
                page: values.page,
                pageSize: pagination?.pageSize ?? 10,
                sortBy: Direction.DESC,
                status: statusSelected,
                priority: prioritySelected,
            };
            loadData(searchQueryParams);
        },
        [pagination, statusSelected, prioritySelected]
    );

    const onSaveServiceRecord = async (
        payload: TServiceRecordRequest,
        isForceReload?: boolean
    ) => {
        try {
            dispatch(setLoading(true));
            const result = await addServiceRecord(payload);
            if (result.status === 201 && isForceReload) {
                onReloadData();
            }
        } catch (error) {
            // Handle error
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const onUpdateStatus = async (
        status: EStatusOfService,
        serviceSelection: TServiceDetail,
        taskSelected: TCarInService,
        isForceReload?: boolean
    ) => {
        try {
            if (!taskSelected) return;
            dispatch(setLoading(true));
            const nowDateUTC = new Date().toISOString();
            const newServices: TServiceRequest[] = taskSelected.services?.map(
                item => {
                    if (
                        item?.serviceDetailId ===
                        serviceSelection.serviceDetailId
                    ) {
                        if (status === EStatusOfService.PAUSE) {
                            return {
                                serviceId: item?.service?.serviceId,
                                userId: item?.user?.userId,
                                serviceDetailId: item?.serviceDetailId,
                                serviceDetailStatus: status,
                                updateType: EUpdateType.UPDATE,
                                startTime: item?.startTime,
                                stopTime: nowDateUTC,
                            } as TServiceRequest;
                        }
                        if (status === EStatusOfService.UNPAUSE) {
                            return {
                                serviceId: item?.service?.serviceId,
                                userId: item?.user?.userId,
                                serviceDetailId: item?.serviceDetailId,
                                serviceDetailStatus: EStatusOfService.START,
                                updateType: EUpdateType.UPDATE,
                                startTime: nowDateUTC,
                            } as TServiceRequest;
                        }
                        if (status === EStatusOfService.DONE) {
                            return {
                                serviceId: item?.service?.serviceId,
                                userId: item?.user?.userId,
                                serviceDetailId: item?.serviceDetailId,
                                serviceDetailStatus: status,
                                updateType: EUpdateType.UPDATE,
                                startTime: item?.startTime,
                                stopTime: nowDateUTC,
                            } as TServiceRequest;
                        }
                        return {
                            serviceId: item?.service?.serviceId,
                            userId: item?.user?.userId,
                            serviceDetailId: item?.serviceDetailId,
                            serviceDetailStatus: status,
                            updateType: EUpdateType.UPDATE,
                            startTime: nowDateUTC,
                        } as TServiceRequest;
                    }
                    return {
                        serviceId: item?.service?.serviceId,
                        userId: item?.user?.userId,
                        serviceDetailId: item?.serviceDetailId,
                        serviceDetailStatus: item?.serviceDetailStatus,
                        updateType: EUpdateType.NOT_MODIFY,
                    } as TServiceRequest;
                }
            ) as TServiceRequest[];
            // check status of car
            let statusOfCar = EStatusCarInService.INPROGRESS;
            const doneServices = newServices?.filter(
                service =>
                    service?.serviceDetailStatus === EStatusOfService.DONE
            );
            if (doneServices?.length === newServices?.length) {
                statusOfCar = EStatusCarInService.DONE;
            }
            const pauseServices = newServices?.filter(
                service =>
                    service?.serviceDetailStatus === EStatusOfService.PAUSE
            );
            if (pauseServices?.length === newServices?.length) {
                statusOfCar = EStatusCarInService.ON_HOLD;
            }
            const unPauseServices = newServices?.filter(
                service =>
                    service?.serviceDetailStatus === EStatusOfService.UNPAUSE
            );
            if (unPauseServices?.length === newServices?.length) {
                statusOfCar = EStatusCarInService.INPROGRESS;
            }
            // done status
            const doneStatus = newServices?.filter(
                service =>
                    service?.serviceDetailStatus === EStatusOfService.DONE
            );
            if (doneStatus?.length === newServices?.length) {
                statusOfCar = EStatusCarInService.DONE;
            }
            const payload: TCarInServiceRequest = {
                carId: taskSelected?.carId,
                carInServiceId: taskSelected?.carInServiceId,
                services: newServices,
                priority: taskSelected?.priority,
                status: statusOfCar,
            };
            const result = await updateCarInService(
                taskSelected?.carInServiceId ?? 0,
                payload
            );
            if (result.status === 200) {
                // add to write logs
                let statusOfService: EServiceRecordStatus =
                    EServiceRecordStatus.START;
                if (status === EStatusOfService.START) {
                    statusOfService = EServiceRecordStatus.START;
                }
                if (status === EStatusOfService.PAUSE) {
                    statusOfService = EServiceRecordStatus.PAUSE;
                }
                if (status === EStatusOfService.UNPAUSE) {
                    statusOfService = EServiceRecordStatus.UNPAUSING;
                }
                if (status === EStatusOfService.DONE) {
                    statusOfService = EServiceRecordStatus.DONE;
                }

                const payload: TServiceRecordRequest = {
                    carInServiceId: taskSelected?.carInServiceId ?? 0,
                    serviceDetailId: serviceSelection.serviceDetailId ?? 0,
                    carId: taskSelected?.carId ?? 0,
                    status: statusOfService,
                };
                onSaveServiceRecord(payload, isForceReload);
            }
        } catch (error) {
            // Handle error
            dispatch(setLoading(false));
        }
    };

    const onSaveStateCar = React.useCallback(
        async (
            priority: EPriorityCarInService | undefined,
            statusCar: EStatusCarInService | undefined
        ) => {
            try {
                dispatch(setLoading(true));
                const newServices: TServiceRequest[] =
                    carInServiceSelected?.services?.map(item => ({
                        serviceId: item.service?.serviceId,
                        userId: item.user?.userId,
                        serviceDetailId: item.serviceDetailId,
                        updateType: EUpdateType.NOT_MODIFY,
                    })) as TServiceRequest[];
                const payload: TCarInServiceRequest = {
                    carId: carInServiceSelected?.carId,
                    carInServiceId: carInServiceSelected?.carInServiceId,
                    services: newServices,
                    priority: priority || carInServiceSelected?.priority,
                    status: statusCar || carInServiceSelected?.status,
                };
                const result = await updateCarInService(
                    carInServiceSelected?.carInServiceId ?? 0,
                    payload
                );
                if (result.status === 200) {
                    showSuccess(
                        'Save service success',
                        'Service priority has been updated'
                    );
                }
            } catch (error) {
                // Handle error
            } finally {
                dispatch(setLoading(false));
            }
        },
        [dispatch, showSuccess, carInServiceSelected]
    );

    const onLoadMore = async () => {
        try {
            dispatch(setLoading(true));
            const newPayload: TSearchCarInService = {
                page: (pagination?.currentPage ?? 1) + 1,
                pageSize: pagination?.pageSize ?? DEFAULT_PAGE_SIZE_LOAD_MORE,
                sortBy: Direction.DESC,
                status: statusSelected,
                priority: prioritySelected,
            };
            const result = await searchCarInService(newPayload);
            if (result.status === 200) {
                const newData = result.data?.data ?? [];
                setCarInServices([...carInServices, ...newData]);
                setPagination({
                    currentPage: result.data?.currentPage ?? 1,
                    pageSize:
                        result.data?.pageSize ?? DEFAULT_PAGE_SIZE_LOAD_MORE,
                    totalPages: result.data?.totalPages ?? 0,
                    totalRecords: result.data?.totalRecords ?? 0,
                });
                const isLoadMore =
                    result.data?.currentPage * result.data?.pageSize <
                    result.data?.totalRecords;
                setHasLoadMore(isLoadMore);
            }
        } catch (error) {
            // Handle error
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <div className="flex flex-col items-start gap-[15px] justify-start relative h-full overflow-auto p-[3px]">
            <div className="flex items-center justify-end w-full">
                <FilterTaskPage
                    viewMode={viewMode}
                    mobileMode={mobileMode}
                    setViewMode={setViewMode}
                    statusSelected={statusSelected}
                    prioritySelected={prioritySelected}
                    onChangeStatus={onChangeStatus}
                    onChangePriority={onChangePriority}
                />
            </div>
            <div className="flex w-full">
                <div className="flex flex-col w-full">
                    {carInServices?.length === 0 && (
                        <div className="flex items-center justify-center w-full h-full">
                            <div className="flex flex-col w-full bg-white rounded-[12px] items-center justify-center p-[32px]">
                                <ICNoResults />
                                <span className="text-body-medium">
                                    No data
                                </span>
                            </div>
                        </div>
                    )}
                    {carInServices?.length > 0 &&
                        viewMode === ViewMode.LIST && (
                            <TaskListPage
                                taskList={carInServices}
                                mobileMode={mobileMode}
                                hasLoadMore={hasLoadMore}
                                setCarInServiceSelected={
                                    setCarInServiceSelected
                                }
                                setOpenEditTask={setOpenEditTask}
                                onUpdateStatus={onUpdateStatus}
                                onLoadMore={onLoadMore}
                                onReloadData={onReloadData}
                            />
                        )}
                    {carInServices?.length > 0 &&
                        viewMode === ViewMode.TABLE && (
                            <TaskTablePage
                                taskList={carInServices}
                                pagination={pagination}
                                onChangePaging={onChangePaging}
                                setCarInServiceSelected={
                                    setCarInServiceSelected
                                }
                                setOpenEditTask={setOpenEditTask}
                                onUpdateStatus={onUpdateStatus}
                            />
                        )}
                </div>
            </div>
            <Drawer
                title="Edit Task"
                onClose={() => setOpenEditTask(false)}
                open={openEditTask}
                destroyOnClose
                zIndex={1000}
                width={'90%'}
                afterOpenChange={visible => {
                    if (!visible) {
                        setCarInServiceSelected(undefined);
                        onReloadData();
                    }
                    if (visible && carInServiceSelected) {
                        setPrioritySelectedControl(
                            carInServiceSelected?.priority as string
                        );
                    }
                }}
                classNames={{
                    body: 'body-edit-table-drawer',
                }}
            >
                <div className="flex w-full flex-col max-md:pt-0 pt-[16px]">
                    <div className="flex flex-col gap-[20px] w-full">
                        <FieldsetPage
                            title="Modify Car"
                            child={
                                <div className="flex flex-col w-full gap-[12px] p-[16px] pt-0">
                                    <div className="grid max-md:grid-cols-1 grid-cols-[80px_1fr] items-center w-full gap-1">
                                        <span className="text-low-em">
                                            Name
                                        </span>
                                        <span>
                                            {carInServiceSelected?.car?.make}{' '}
                                            {carInServiceSelected?.car?.model}
                                        </span>
                                    </div>
                                    <div className="grid max-md:grid-cols-1 grid-cols-[80px_1fr] items-center w-full gap-1">
                                        <div className="text-high-em">
                                            Status
                                        </div>
                                        <WrapperSegmented>
                                            <Segmented
                                                className="active-primary"
                                                type="primary"
                                                value={statusSelectedControl}
                                                options={statusOptions}
                                                onChange={status => {
                                                    setStatusSelectedControl(
                                                        status as string
                                                    );
                                                    onSaveStateCar(
                                                        undefined,
                                                        status as EStatusCarInService
                                                    );
                                                }}
                                            />
                                        </WrapperSegmented>
                                    </div>
                                    <div className="grid max-md:grid-cols-1 grid-cols-[80px_1fr] items-center w-full gap-1">
                                        <div className="text-high-em">
                                            Priority
                                        </div>
                                        <WrapperSegmented>
                                            <Segmented
                                                className="active-primary"
                                                type="primary"
                                                value={prioritySelectedControl}
                                                options={priorityOptions}
                                                onChange={status => {
                                                    setPrioritySelectedControl(
                                                        status as string
                                                    );
                                                    onSaveStateCar(
                                                        status as EPriorityCarInService,
                                                        undefined
                                                    );
                                                }}
                                            />
                                        </WrapperSegmented>
                                    </div>
                                </div>
                            }
                        />
                        <FieldsetPage
                            title="Services"
                            child={
                                <div className="flex flex-col w-full p-[10px]">
                                    {carInServiceSelected && (
                                        <div className="flex flex-col w-full gap-3">
                                            <ServiceList
                                                carInService={
                                                    carInServiceSelected
                                                }
                                                mobileMode={mobileMode}
                                            />
                                        </div>
                                    )}
                                </div>
                            }
                        />
                    </div>
                </div>
            </Drawer>
        </div>
    );
};
