import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export type User = {
    Id: string | undefined;
    UserIdentifier: string | undefined;
    PhoneNumber: string | undefined | null;
    Language: string | undefined;
    FirstName: string | undefined;
    LastName: string | undefined;
    Email: string | undefined | null;
};

export type TUserInfo = {
    token?: string | null;
    roles?: string[] | null;
    userId?: number | undefined;
    username?: string | undefined;
    email?: string | undefined;
    passwordHash?: string | undefined;
    createdAt?: string | undefined;
} & Partial<User>;

const initialState: TUserInfo = JSON.parse(
    window.localStorage.getItem('userInfo') || '{}'
);

const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        setUserInfo: (state: TUserInfo, action: PayloadAction<TUserInfo>) => {
            Object.entries(action.payload).forEach(([key, value]) => {
                // A little bit of magic to make sure that the state is immutable
                state[key as keyof TUserInfo] = value as any;
            });
            // Merge from local storage
            const localState = JSON.parse(
                window.localStorage.getItem('userInfo') || '{}'
            );
            window.localStorage.setItem(
                'userInfo',
                JSON.stringify({ ...localState, ...state })
            );
        },
        removeUserInfo: () => {
            window.localStorage.removeItem('userInfo');
            return {};
        },
        setToken: (state: TUserInfo, action: PayloadAction<string>) => {
            state.token = action.payload;
            window.localStorage.setItem(
                'userInfo',
                JSON.stringify({ ...state })
            );
        },
    },
});

export const { setUserInfo, removeUserInfo, setToken } = userInfoSlice.actions;

export default userInfoSlice.reducer;
