import { Modal } from 'antd';
import React from 'react';
import { TRepairService } from '../../type';
import { AutoTrackTooltip } from '../../../../components/tooltip';
import { AutoTrackButton } from '../../../../components/button';
import { ICDelete, ICDraggable } from '../../../../icons';
import { formatNumber } from '../../../../utils';

type ReviewServiceRowProps = {
    serviceData: TRepairService;
    index: number;
    onDelete: (index: number) => void;
};
export const ReviewServiceRow: React.FC<ReviewServiceRowProps> = ({
    serviceData,
    index,
    onDelete,
}) => {
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);

    return (
        <div className="group max-md:py-[16px] py-[8px] max-md:px-0 px-[16px] border-[1px] max-md:border-gray-200 border-transparent hover:border-purple rounded-[8px] mb-3">
            <div className="flex max-md:items-start items-center justify-between">
                <div className="grid grid-cols-[auto_1fr] gap-[8px] max-md:items-start items-center w-full">
                    <span className="relative p-1 cursor-pointer max-md:opacity-100 opacity-0 group-hover:opacity-100">
                        <AutoTrackTooltip
                            content="Drag to reorder"
                            placement={'top'}
                            childNode={
                                <>
                                    <ICDraggable fill="#7357FF" />
                                </>
                            }
                        ></AutoTrackTooltip>
                    </span>
                    <div className="grid max-md:grid-cols-[1fr_1fr] grid-cols-[50px_1fr_200px] gap-[8px] justify-start max-md:items-start items-center w-full">
                        <span className="max-md:hidden">{index + 1}</span>
                        <div className="flex max-md:gap-[5px] gap-[16px] justify-start flex-wrap max-md:items-start items-center">
                            <span className="">
                                <AutoTrackTooltip
                                    content={serviceData?.name}
                                    childNode={
                                        <div className="flex gap-[8px] items-center">
                                            {serviceData?.name}
                                        </div>
                                    }
                                ></AutoTrackTooltip>
                            </span>
                            <span className="text-base font-bold pr-[60px]">
                                {formatNumber(serviceData?.price)}
                            </span>
                        </div>
                        <div className="max-md:pl-[20px]">
                            {serviceData?.users?.map((user, index) => (
                                <span key={index} className="text-[14px]">
                                    {user?.username}
                                    {index < serviceData?.users?.length - 1
                                        ? ', '
                                        : ''}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="flex space-x-2">
                    <span
                        className="p-1 cursor-pointer max-md:opacity-100 opacity-0 group-hover:opacity-100"
                        onClick={() => setShowDeleteModal(true)}
                    >
                        <ICDelete stroke="#A6A8B4" />
                    </span>
                </div>
            </div>

            <Modal
                centered
                onCancel={() => setShowDeleteModal(false)}
                open={showDeleteModal}
                title={
                    <div className="font-bold text-[18px]">Delete service</div>
                }
                footer={
                    <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                        <AutoTrackButton
                            btnType="sub"
                            btnSize="sm"
                            onClick={() => setShowDeleteModal(false)}
                        >
                            Cancel
                        </AutoTrackButton>
                        <AutoTrackButton
                            btnSize="sm"
                            onClick={() => {
                                onDelete(index);
                                setShowDeleteModal(false);
                            }}
                        >
                            Delete
                        </AutoTrackButton>
                    </div>
                }
            >
                <p>Are you sure you want to delete this service?</p>
            </Modal>
        </div>
    );
};
