export type TSearchCarInService = {
    status?: string;
    priority?: string;
    page: number;
    pageSize: number;
    orderBy?: string;
    sortBy?: string;
};

export type TPaging = {
    currentPage: number;
    pageSize: number;
    totalPages: number;
    totalRecords: number;
};

// Service record status
export enum EServiceRecordStatus {
    START = 0,
    PAUSE = 1,
    UNPAUSING = 2,
    DONE = 3,
}

export type TServiceRecordRequest = {
    carInServiceId: number;
    serviceDetailId: number;
    carId: number | string | undefined;
    status: EServiceRecordStatus;
};
