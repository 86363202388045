import { IconProps } from './type';

export const ICNotification: React.FC<IconProps> = ({ fill = '#4F4B5C' }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                id="Vector"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 3C8.44917 3 5.45455 6.10646 5.45455 10.0868V10.0982L5.42709 12.4965C5.42576 14.161 4.8667 15.5664 4.22902 16.5999H19.7769C19.1649 15.5643 18.6291 14.1563 18.6291 12.4906V12.4786L18.6565 10.2001C18.6536 6.15677 15.6104 3 12 3ZM3.45456 10.0809C3.45752 5.13141 7.21823 1 12 1C16.845 1 20.6565 5.18801 20.6565 10.2059V10.2179L20.6291 12.4964C20.6305 13.9145 21.1587 15.1049 21.7152 15.9236C22.0848 16.4673 22.0474 17.1113 21.8676 17.5641C21.6917 18.007 21.2272 18.5999 20.43 18.5999H3.57106C2.76088 18.5999 2.29758 17.9891 2.12628 17.5444C1.9506 17.0884 1.91637 16.4336 2.30289 15.8882C2.8843 15.0679 3.42709 13.8885 3.42709 12.4906V12.4792L3.45456 10.0809ZM7.99997 21.1999C7.99997 20.6476 8.44769 20.1999 8.99997 20.1999H15C15.5523 20.1999 16 20.6476 16 21.1999C16 21.7521 15.5523 22.1999 15 22.1999H8.99997C8.44769 22.1999 7.99997 21.7521 7.99997 21.1999Z"
                fill={fill}
            />
        </svg>
    );
};
