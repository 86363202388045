import Webcam from 'react-webcam';
import styled from 'styled-components';
import React from 'react';
import { ICCamera, ICSwitchCamera, ICWarning } from '../../../../icons';

const WrapperCamera = styled.div`
    video {
        height: 100%;
        object-fit: cover;
    }
`;
const WrapperSwitchCamera = styled.div`
    background-color: rgba(255, 255, 255, 0.5);
`;

type CameraControlProps = {
    webcamRef: React.RefObject<Webcam>;
    isMobile: boolean;
};
export const CameraControl: React.FC<CameraControlProps> = ({
    webcamRef,
    isMobile,
}) => {
    const FACING_MODE_USER = 'user';
    const FACING_MODE_ENVIRONMENT = 'environment';
    const [facingMode, setFacingMode] = React.useState<string>(
        FACING_MODE_ENVIRONMENT
    );

    const videoConstraints = React.useMemo(() => {
        return {
            facingMode: facingMode,
            width: window.innerWidth,
            height: window.innerHeight,
        };
    }, [isMobile, facingMode]);

    const [isNotSupportCamera, setIsNotSupportCamera] =
        React.useState<boolean>(false);

    const stopCamera = () => {
        if (webcamRef?.current) {
            const streamVideo = webcamRef?.current?.video
                ?.srcObject as MediaStream;
            if (streamVideo) {
                streamVideo.getTracks()?.forEach((track: any) => track?.stop());
            }
        }
    };

    React.useEffect(() => {
        return () => {
            stopCamera();
        };
    }, []);

    const onUserMediaError = React.useCallback(() => {
        setIsNotSupportCamera(true);
    }, []);

    React.useEffect(() => {
        return () => {
            stopCamera();
        };
    }, []);

    return (
        <WrapperCamera className="relative flex flex-col w-full h-full">
            <div className="relative flex flex-col items-center h-full w-full">
                <Webcam
                    ref={webcamRef}
                    audio={false}
                    style={{ width: '100%', height: '100%' }}
                    videoConstraints={videoConstraints}
                    screenshotFormat="image/jpeg"
                    onUserMediaError={onUserMediaError}
                    forceScreenshotSourceSize={true}
                    onUserMedia={() => {
                        setIsNotSupportCamera(false);
                    }}
                />
                <div className="absolute flex w-full items-center justify-center bottom-[20px]">
                    <WrapperSwitchCamera className="flex rounded-[50%] h-[50px] w-[50px] items-center justify-center">
                        <ICCamera fill="white" />
                    </WrapperSwitchCamera>
                </div>
                <div className="absolute bottom-[10px] right-[20px] p-2">
                    <WrapperSwitchCamera
                        className="flex rounded-[50%] h-[35px] w-[35px] items-center justify-center"
                        onClick={() => {
                            setFacingMode(
                                facingMode === FACING_MODE_USER
                                    ? FACING_MODE_ENVIRONMENT
                                    : FACING_MODE_USER
                            );
                        }}
                    >
                        <ICSwitchCamera />
                    </WrapperSwitchCamera>
                </div>
                {isNotSupportCamera && (
                    <div className="flex flex-col items-center justify-center h-full absolute top-[0] w-full gap-[8px] bg-surface-high rounded-[12px] not-support-camera">
                        <span>
                            <ICWarning fill="#C6C5CA" />
                        </span>
                        <span className="text-body-medium text-high-em">
                            Camera not supported
                        </span>
                    </div>
                )}
            </div>
        </WrapperCamera>
    );
};
