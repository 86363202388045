import React from 'react';
import { createGlobalStyle } from 'styled-components';

type GlobalStyleProps = {
    primaryColor: string | undefined;
    secondaryColor: string | undefined;
    fontFamily: string | undefined;
};

const GlobalStyleTemplate = createGlobalStyle<GlobalStyleProps>`
    html,
    body {
        height: 100%;
        width: 100%;
        line-height: 1.5;
        --fontFamily: ${props => props.fontFamily};
        --primaryColor: ${props => props.primaryColor};
        --secondaryColor: ${props => props.secondaryColor};
    }
`;

type ThemingProps = {
    primaryColor: string | undefined;
    secondaryColor: string | undefined;
    fontFamily: string | undefined;
};

export const Theming: React.FC<ThemingProps> = ({
    primaryColor,
    secondaryColor,
    fontFamily,
}) => {
    return (
        <GlobalStyleTemplate
            fontFamily={fontFamily}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
        />
    );
};
