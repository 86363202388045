export const isValidEmail = (email: string) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|([a-zA-Z0-9]([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const isNetworkError = (error: any) => {
    return error.code === 'ERR_NETWORK';
};

export const removeUndefinedAttribute = (values: any = {}) => {
    Object.keys(values).forEach(key => {
        if (
            values[key] === null ||
            values[key] === undefined ||
            values[key] === 'undefined' ||
            values[key] === ''
        ) {
            delete values[key];
        }
    });
    return values;
};

export const getRandomCharsFromString = (charset: string, length: number) => {
    return new Array(length)
        .fill(0)
        .map(() => charset[Math.floor(Math.random() * charset.length)])
        .join('');
};

export function formatLocaleDateTimeString(date: string) {
    if (!date) return '';
    return new Date(date)
        .toLocaleString('en-GB', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        })
        .split('/')
        .join('-')
        .replace(',', '');
}

export function formatLocaleDateString(date: string) {
    if (!date) return '';
    const longEnGBFormatter = new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
    const newDate = longEnGBFormatter.format(new Date(date));

    const today = new Date();
    const todayString = longEnGBFormatter.format(today);
    if (newDate === todayString) {
        return 'Now';
    }
    return newDate;
}

export function formatNumber(number: number) {
    if (!number) return '';
    const newValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(number);
    return newValue.replace('.00', '');
}

export function formatNumberNoneCurrency(val: number) {
    if (!val) return '';
    const value = new Intl.NumberFormat('en-US', { style: 'decimal' }).format(
        val
    );
    // need to format tofixed 2 decimal
    const parts = value.split('.');
    if (parts.length === 1) {
        return `${value}`;
    }
    if (parts.length === 2) {
        if (parts[1].length === 1) {
            return `${value}0`;
        }
        return value;
    }
    return value;
}

export function renderColorOptions() {
    return [
        {
            value: 'red',
            label: 'Red',
        },
        {
            value: 'blue',
            label: 'Blue',
        },
        {
            value: 'green',
            label: 'Green',
        },
        {
            value: 'yellow',
            label: 'Yellow',
        },
        {
            value: 'black',
            label: 'Black',
        },
        {
            value: 'white',
            label: 'White',
        },
        {
            value: 'silver',
            label: 'Silver',
        },
        {
            value: 'gray',
            label: 'Gray',
        },
        {
            value: 'brown',
            label: 'Brown',
        },
        {
            value: 'orange',
            label: 'Orange',
        },
        {
            value: 'purple',
            label: 'Purple',
        },
        {
            value: 'pink',
            label: 'Pink',
        },
        {
            value: 'gold',
            label: 'Gold',
        },
        {
            value: 'beige',
            label: 'Beige',
        },
        {
            value: 'bronze',
            label: 'Bronze',
        },
        {
            value: 'copper',
            label: 'Copper',
        },
        {
            value: 'cream',
            label: 'Cream',
        },
        {
            value: 'maroon',
            label: 'Maroon',
        },
        {
            value: 'navy',
            label: 'Navy',
        },
        {
            value: 'tan',
            label: 'Tan',
        },
        {
            value: 'teal',
            label: 'Teal',
        },
        {
            value: 'turquoise',
            label: 'Turquoise',
        },
        {
            value: 'violet',
            label: 'Violet',
        },
        {
            value: 'burgundy',
            label: 'Burgundy',
        },
        {
            value: 'charcoal',
            label: 'Charcoal',
        },
        {
            value: 'crimson',
            label: 'Crimson',
        },
        {
            value: 'indigo',
            label: 'Indigo',
        },
        {
            value: 'lavender',
            label: 'Lavender',
        },
        {
            value: 'magenta',
            label: 'Magenta',
        },
        {
            value: 'mauve',
            label: 'Mauve',
        },
        {
            value: 'olive',
            label: 'Olive',
        },
        {
            value: 'peach',
            label: 'Peach',
        },
        {
            value: 'plum',
            label: 'Plum',
        },
        {
            value: 'slate',
            label: 'Slate',
        },
        {
            value: 'taupe',
            label: 'Taupe',
        },
        {
            value: 'aquamarine',
            label: 'Aquamarine',
        },
        {
            value: 'azure',
            label: 'Azure',
        },
        {
            value: 'bisque',
            label: 'Bisque',
        },
        {
            value: 'blanchedalmond',
            label: 'Blanched Almond',
        },
        {
            value: 'blueviolet',
            label: 'Blue Violet',
        },
        {
            value: 'burlywood',
            label: 'Burly Wood',
        },
        {
            value: 'cadetblue',
            label: 'Cadet Blue',
        },
        {
            value: 'chartreuse',
            label: 'Chartreuse',
        },
        {
            value: 'chocolate',
            label: 'Chocolate',
        },
        {
            value: 'coral',
            label: 'Coral',
        },
        {
            value: 'cornflowerblue',
            label: 'Cornflower Blue',
        },
        {
            value: 'cornsilk',
            label: 'Cornsilk',
        },
        {
            value: 'cyan',
            label: 'Cyan',
        },
        {
            value: 'darkblue',
            label: 'Dark Blue',
        },
        {
            value: 'darkcyan',
            label: 'Dark Cyan',
        },
        {
            value: 'darkgoldenrod',
            label: 'Dark Goldenrod',
        },
        {
            value: 'darkgray',
            label: 'Dark Gray',
        },
        {
            value: 'darkgreen',
            label: 'Dark Green',
        },
        {
            value: 'darkkhaki',
            label: 'Dark Khaki',
        },
        {
            value: 'darkmagenta',
            label: 'Dark Magenta',
        },
        {
            value: 'darkolivegreen',
            label: 'Dark Olive Green',
        },
        {
            value: 'darkorange',
            label: 'Dark Orange',
        },
        {
            value: 'darkorchid',
            label: 'Dark Orchid',
        },
        {
            value: 'darkred',
            label: 'Dark Red',
        },
        {
            value: 'darksalmon',
            label: 'Dark Salmon',
        },
        {
            value: 'others',
            label: 'Others',
        },
    ];
}

export const reorder = (
    list: any[] | undefined,
    startIndex: number,
    endIndex: number
): any[] => {
    const result = list ? [...list] : [];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const timeAgo = (date: string) => {
    const seconds = Math.floor(
        (new Date().getTime() - new Date(date).getTime()) / 1000
    );
    let interval = seconds / 31536000;
    if (interval > 1) {
        return Math.floor(interval) + ' years ago';
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + ' months ago';
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + ' days ago';
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + ' hours ago';
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + ' minutes ago';
    }
    if (seconds === 0) {
        return 'Just now';
    }
    return Math.floor(seconds) + ' seconds ago';
};
