export const registerWorker = () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker
            .register('/firebase-messaging-sw.js')
            .then(registration => {
                // eslint-disable-next-line no-console
                console.log(
                    'Service Worker registered with scope:',
                    registration.scope
                );
            })
            .catch(error => {
                // eslint-disable-next-line no-console
                console.error('Error registering service worker:', error);
            });
    }
};
