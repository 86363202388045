import { LoginResult } from '../../interface/user/login';
import { intercepter, mock } from '../config';

mock.mock(/\/user\/login\/mock*/, 'post', (config: any) => {
    const body: LoginResult = JSON.parse(config?.body);

    return intercepter<LoginResult>(
        {
            token: '123456789',
            email: body?.email,
            roles: ['admin'],
        },
        null
    );
});
