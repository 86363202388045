import { Table, Row, Modal, Input, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { SortOrder } from 'antd/es/table/interface';
import classNames from 'classnames';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useSortData } from '.';
import { AutoTrackButton, IconButton } from '../../components/button';
import { ICSort, ICAdd, ICNoneData } from '../../icons';
import { ICMaterialSymbol } from '../../icons/material-symbol';
import { ServiceForm } from './create';
import { TService } from '../start-new-task/type';
import { AutoTrackPagination } from '../../components/pagination';
import { useNotification } from '../../hooks/useNotification';
import dayjs from 'dayjs';
import { formatNumber, removeUndefinedAttribute } from '../../utils';
import { setLoading } from '../../redux/slices/appInfo';
import {
    createService,
    deleteService,
    updateService,
} from '../../api/setting/services';
import debouce from 'lodash/debounce';
import {
    Direction,
    IPagination,
    SearchQueryParams,
} from '../../interface/paging';

const ActionMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    cursor: pointer;
    padding-right: 16px;
    &:hover {
        background-color: #f7f8fa;
    }
    button {
        padding: 0 !important;
    }
`;

const WrapperTable = styled.div`
    .ant-table-thead > tr > th {
        background-color: white;
    }
    .data-row:nth-child(odd) {
        background-color: #f7f8fa;
    }
    .ant-table-column-sorters .ant-table-column-sorter-inner {
        .anticon {
            display: none;
        }
    }
`;

const WrapperInput = styled.div`
    @media (max-width: 768px) {
    .ant-input-group-wrapper {
        width: 100% !important;
    }
`;

const WrapperButton = styled.div`
    @media (max-width: 768px) {
        width: 100%;
        .ant-btn {
            width: 100% !important;
        }
    }
`;

const TitleRenderer: React.FC<{ title: string }> = ({ title }) => {
    const keyword = React.useContext(KeywordContext);

    return (
        <Highlighter
            highlightClassName="text-highlight"
            searchWords={[`${keyword || ''}`]}
            autoEscape={true}
            className="text-body text-high-em"
            textToHighlight={title || ''}
        />
    );
};
type SortableColumnProps = {
    title: string;
    order?: SortOrder;
    name: string;
};
const SortableColumn: React.FC<SortableColumnProps> = ({
    title,
    order,
    name,
}) => {
    const { setSortData } = useSortData();
    return (
        <div
            className="flex space-x-3 items-center justify-between select-none group"
            onClick={() => {
                setSortData({
                    field: order === 'descend' ? 'createdDate' : name,
                    order:
                        order === 'descend' ? 'desc' : !order ? 'asc' : 'desc',
                });
            }}
        >
            <span className="text-body-bold text-high-em">
                {title as string}
            </span>
            <ICSort order={order} />
        </div>
    );
};

type ServicePageListProps = {
    serviceList: TService[];
    setServiceList: (serviceList: TService[]) => void;
    keyword?: string;
    pagination?: IPagination;
    onChangePaging: ({ page }: { page: number }) => void;
    onReload: () => void;
    filterParams: SearchQueryParams;
    onFilterData: (payload: SearchQueryParams) => void;
};
const KeywordContext = React.createContext<string>('');

export const ServicePageList: React.FC<ServicePageListProps> = ({
    serviceList,
    setServiceList,
    keyword,
    pagination,
    onChangePaging,
    onReload,
    filterParams,
    onFilterData,
}) => {
    const dispatch = useDispatch();
    const { showSuccess, showError } = useNotification();
    const onChangePage = (page: number) => {
        onChangePaging({ page });
    };
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [serviceId, setServiceId] = React.useState<string | undefined>('');
    const [showServiceDialog, setShowServiceDialog] = React.useState(false);
    const [serviceSelected, setServiceSelected] = React.useState<
        TService | undefined
    >(undefined);
    const [form] = useForm();

    // handle search keyword
    const [searchColumn, setSearchColumn] = React.useState<string>('Name');
    const [searchWord, setSearchWord] = React.useState<string | undefined>('');
    const selectBeforeOptions = ['Name', 'Price', 'Description'];
    const onSearchData = React.useCallback(
        async (searchWord: string | undefined, searchColumnTemp?: string) => {
            try {
                const queryParams: SearchQueryParams = {
                    page: 1,
                    pageSize: filterParams?.pageSize || 10,
                    searchWord: searchWord,
                    sortBy: Direction.ASC,
                    searchColum: searchColumnTemp || searchColumn,
                };
                const newPayload = removeUndefinedAttribute(queryParams);
                onFilterData(newPayload);
            } catch (error) {
                // Handle error
            }
        },
        [filterParams, searchColumn]
    );

    const handleSearchData = (value: string | undefined) => {
        onSearchKeyword(value);
        setSearchWord(value);
    };

    const onSearchKeyword = React.useMemo(() => {
        return debouce(onSearchData, 600);
    }, [onSearchData]);

    React.useEffect(() => {
        return () => {
            onSearchKeyword.cancel();
        };
    }, [onSearchKeyword]);
    // end handle search keyword

    const onShowDeleteModal = React.useCallback(
        (id: string) => {
            setServiceId(id);
            setShowDeleteModal(true);
        },
        [setShowDeleteModal]
    );

    const columns: ColumnsType<TService> = [
        {
            title: 'Name',
            dataIndex: 'name',
            field: 'name',
            key: 'name',
            sorter: true,
            render: (title: string) => {
                return <TitleRenderer title={title} />;
            },
        },
        {
            title: 'Price',
            dataIndex: 'price',
            field: 'price',
            key: 'price',
            sorter: true,
            render: (val: number) => {
                return <span>{formatNumber(val)}</span>;
            },
        },
        {
            title: 'Description',
            dataIndex: 'description',
            field: 'description',
            key: 'description',
            sorter: true,
            render: (title: string) => {
                return <TitleRenderer title={title} />;
            },
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            field: 'createdAt',
            key: 'createdAt',
            sorter: true,
            render: (val: string) => {
                const newDate = dayjs(val, 'YYYY-MM-DD').format('YYYY-MM-DD');
                return newDate;
            },
        },
        {
            title: 'Action',
            dataIndex: 'serviceId',
            key: 'serviceId',
            width: '40px',
            render: (serviceId: string, data: TService) => {
                return (
                    <ActionMenu>
                        <IconButton
                            icon={<ICMaterialSymbol icon="edit_square" />}
                            btnType="tertiary"
                            onClick={() => {
                                setServiceSelected(data);
                                setShowServiceDialog(true);
                            }}
                        />
                        <IconButton
                            icon={<ICMaterialSymbol icon="delete" />}
                            onClick={() => onShowDeleteModal(serviceId)}
                            btnType="tertiary"
                        />
                    </ActionMenu>
                );
            },
        },
    ].map((col: ColumnType<TService>) => {
        if (col.sorter) {
            const oldTitle = col.title;
            col.title = ({ sortColumns }) => {
                const sortedColumn = sortColumns?.find(
                    ({ column }) => column.key === col.key
                );
                return (
                    <SortableColumn
                        title={oldTitle as string}
                        order={sortedColumn?.order}
                        name={col.dataIndex as string}
                    />
                );
            };
        } else {
            col.title = (
                <div className="text-body-bold text-high-em">
                    {col.title as string}
                </div>
            );
        }
        col.showSorterTooltip = false;
        return col;
    });

    const doSaveService = React.useCallback(
        async (id: string | number | undefined, payload: TService) => {
            try {
                dispatch(setLoading(true));
                let result: any;
                if (id) {
                    result = await updateService(id, payload);
                    showSuccess('Update Service success', 'Service updated');
                } else {
                    result = await createService(payload);
                    showSuccess('Create Service success', 'Service created');
                }
                if (result.status) {
                    onReload();
                    setShowServiceDialog(false);
                }
            } catch (error: any) {
                const errorMessage =
                    error?.response?.data?.detail ||
                    'Please check your input data';
                showError('Save Service failed', errorMessage);
            } finally {
                dispatch(setLoading(false));
            }
        },
        [serviceList, dispatch, showSuccess, showError]
    );

    const onSaveService = React.useCallback(() => {
        form.validateFields()
            .then(values => {
                const serviceData: TService = {
                    ...serviceSelected,
                    ...values,
                };
                if (serviceSelected) {
                    doSaveService(serviceSelected.serviceId, serviceData);
                } else {
                    doSaveService(undefined, serviceData);
                }
            })
            .catch(() => {
                showError(
                    'Save Service failed',
                    'Please check your input data'
                );
            });
    }, [form, serviceList, serviceSelected, showError]);

    const onDeleteService = React.useCallback(async () => {
        if (!serviceId) {
            return;
        }
        try {
            dispatch(setLoading(true));
            const result = await deleteService(serviceId);
            setShowDeleteModal(false);
            if (result.status === 200) {
                showSuccess(
                    'Delete Service success',
                    'Service has been deleted'
                );
                onReload();
            }
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.detail ||
                'Error occurred while deleting Service';
            showError('Delete Service failed', errorMessage);
        } finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, serviceId, showSuccess, setServiceList, showError]);

    return (
        <WrapperTable className="flex flex-col gap-[20px] w-full overflow-hidden h-full items-start box-border">
            <div className="flex w-full px-[12px] py-[20px] shadow-e-03 max-md:justify-center items-center max-md:flex-wrap gap-3 justify-between bg-white rounded-[12px]">
                <div className="flex gap-3 items-center justify-center w-full flex-wrap">
                    <WrapperInput className="flex items-center w-full gap-2">
                        <Input
                            placeholder="Search by ..."
                            value={searchWord}
                            onChange={e => handleSearchData(e.target.value)}
                            style={{
                                width: '420px',
                            }}
                            addonBefore={
                                <>
                                    <Select
                                        className="select-before"
                                        defaultValue="Make"
                                        value={searchColumn}
                                        style={{ width: 122 }}
                                        onChange={(value: string) => {
                                            setSearchColumn(value);
                                            onSearchData(searchWord, value);
                                        }}
                                    >
                                        {selectBeforeOptions.map(
                                            (item, index) => (
                                                <Select.Option
                                                    key={index}
                                                    value={item}
                                                >
                                                    {item}
                                                </Select.Option>
                                            )
                                        )}
                                    </Select>
                                </>
                            }
                            size="large"
                        />
                    </WrapperInput>
                </div>
                <WrapperButton className="flex gap-[10px]">
                    <AutoTrackButton
                        btnType="primary"
                        onClick={() => {
                            setServiceSelected(undefined);
                            setShowServiceDialog(true);
                        }}
                        style={{
                            width: '220px',
                        }}
                        btnSize="md"
                    >
                        <div className="flex space-x-2 items-center">
                            <ICAdd stroke="#fff" width={18} height={18} />
                            <span>Create new Service</span>
                        </div>
                    </AutoTrackButton>
                </WrapperButton>
            </div>
            <div className="flex flex-col w-full h-full bg-white rounded-[12px] overflow-hidden shadow-e-03">
                <KeywordContext.Provider value={keyword ?? ''}>
                    <Table
                        key="service-list"
                        className={classNames('white-header w-full')}
                        columns={columns}
                        dataSource={serviceList}
                        rowClassName="cursor-pointer data-row"
                        rowKey="serviceId"
                        scroll={{ x: 'max-content' }}
                        locale={{
                            emptyText: (
                                <EmptyTable
                                    keyword={keyword}
                                    onCreateNew={() => {
                                        setServiceSelected(undefined);
                                        setShowServiceDialog(true);
                                    }}
                                />
                            ),
                        }}
                        pagination={false}
                    />
                </KeywordContext.Provider>
                {pagination?.totalPage && pagination.totalPage > 1 ? (
                    <Row
                        gutter={16}
                        style={{
                            margin: 0,
                        }}
                        className="grid items-center max-md:justify-center justify-end w-full bg-white p-[16px] border-t border-solid border-outline-med rounded-b-[12px]"
                    >
                        <AutoTrackPagination
                            onChange={onChangePage}
                            defaultCurrent={pagination?.page}
                            defaultPageSize={pagination?.pageSize}
                            total={pagination?.totalCount}
                            currentPage={pagination?.page}
                            showSizeChanger={false}
                        />
                    </Row>
                ) : null}
            </div>

            <Modal
                centered
                onCancel={() => setShowDeleteModal(false)}
                open={showDeleteModal}
                title={<div className="font-bold text-[18px]">Confirm</div>}
                footer={
                    <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                        <AutoTrackButton
                            btnType="sub"
                            btnSize="sm"
                            onClick={() => setShowDeleteModal(false)}
                        >
                            Cancel
                        </AutoTrackButton>
                        <AutoTrackButton
                            btnType="danger"
                            btnSize="sm"
                            onClick={onDeleteService}
                        >
                            Delete
                        </AutoTrackButton>
                    </div>
                }
            >
                <p>Are you sure you want to delete this service?</p>
            </Modal>

            <Modal
                centered
                onCancel={() => setShowServiceDialog(false)}
                open={showServiceDialog}
                maskClosable={false}
                destroyOnClose
                afterClose={() => form.resetFields()}
                title={
                    <div className="font-bold text-[18px]">
                        {serviceSelected
                            ? 'Edit Service'
                            : 'New Service Register'}
                    </div>
                }
                footer={
                    <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                        <AutoTrackButton
                            btnType="sub"
                            btnSize="sm"
                            onClick={() => setShowServiceDialog(false)}
                        >
                            Cancel
                        </AutoTrackButton>
                        <AutoTrackButton
                            btnType="primary"
                            btnSize="sm"
                            onClick={onSaveService}
                        >
                            Save
                        </AutoTrackButton>
                    </div>
                }
            >
                <div className="flex flex-col gap-[20px] w-full overflow-hidden h-full items-start box-border">
                    <ServiceForm serviceData={serviceSelected} form={form} />
                </div>
            </Modal>
        </WrapperTable>
    );
};

type IEmptyTableProps = {
    keyword: string | undefined;
    onCreateNew: () => void;
};

const EmptyTable: React.FC<IEmptyTableProps> = ({ keyword, onCreateNew }) => {
    return (
        <div className="my-6 flex flex-col gap-[5px]">
            <div className="flex justify-center">
                <ICNoneData />
            </div>
            <div className="text-standard-bold text-high-em">
                {keyword
                    ? `No data found with keyword “${keyword}”`
                    : 'No data found'}
            </div>
            <div className="flex justify-center">
                <span>
                    <AutoTrackButton
                        btnType="outline"
                        btnSize="sm"
                        style={{
                            borderRadius: '144px',
                        }}
                        onClick={() => onCreateNew()}
                    >
                        <div className="flex space-x-2 items-center">
                            <ICAdd stroke="#7357FF" width={18} height={18} />
                            <span>Create new Service</span>
                        </div>
                    </AutoTrackButton>
                </span>
            </div>
        </div>
    );
};
