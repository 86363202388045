export const ICLogoutUser: React.FC = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.1698 20.895C20.5845 21.4755 19.7925 21.8 18.9683 21.8L15.2624 21.8C14.7101 21.8 14.2624 21.3523 14.2624 20.8C14.2624 20.2478 14.7101 19.8 15.2624 19.8L18.9683 19.8C19.2674 19.8 19.5526 19.6821 19.7616 19.4749C19.9702 19.268 20.0859 18.9891 20.0859 18.7L20.0859 6.10005C20.0859 5.81103 19.9702 5.53213 19.7616 5.32518C19.5526 5.11796 19.2674 5.00005 18.9683 5.00005L15.2624 5.00005C14.7101 5.00005 14.2624 4.55233 14.2624 4.00005C14.2624 3.44776 14.7101 3.00005 15.2624 3.00005L18.9683 3.00005C19.7925 3.00005 20.5845 3.32464 21.1698 3.90507C21.7554 4.48577 22.0859 5.27517 22.0859 6.10005L22.0859 18.7C22.0859 19.5249 21.7554 20.3143 21.1698 20.895ZM8.27511 17.9232C7.87575 18.3046 7.24275 18.2901 6.86126 17.8908L2.27611 13.0908C1.90692 12.7043 1.90692 12.0958 2.27611 11.7093L6.86127 6.90931C7.24275 6.50995 7.87575 6.49546 8.27511 6.87694C8.67447 7.25843 8.68896 7.89143 8.30747 8.29079L5.33738 11.4L14.9992 11.4C15.5515 11.4 15.9992 11.8478 15.9992 12.4C15.9992 12.9523 15.5515 13.4 14.9992 13.4L5.33738 13.4L8.30747 16.5093C8.68896 16.9087 8.67447 17.5417 8.27511 17.9232Z"
                fill="#F03D3D"
            />
        </svg>
    );
};
