import Mock from 'mockjs';

export type Response<T = any> = {
    status: boolean;
    message: string;
    result: T;
};

export type FakeResponse<T = any> = [T, Response<T>];

Mock.setup({
    timeout: 1000,
});

export function intercepter<T>(data: T, params: unknown): FakeResponse<T>;

export function intercepter(data: any, params?: unknown) {
    if (params) {
        const { keyword, theme, langCode } = params as any;
        if (keyword) {
            const messagesByKeyword = data.find((item: any) => {
                if (Array.isArray(item.keyword)) {
                    return item.keyword.some((key: string) => {
                        return key
                            .toLowerCase()
                            .includes(keyword.toLowerCase());
                    });
                }
                return item.keyword
                    .toLowerCase()
                    .includes(keyword.toLowerCase());
            });
            if (messagesByKeyword) {
                return [messagesByKeyword];
            }
            const result = data.filter((item: any) => {
                return item.message.includes(keyword);
            });
            return [...result];
        }
        if (theme) {
            if (theme === 'all') {
                return [...data];
            }
            const result = data.filter((item: any) => {
                return item.value === theme;
            });
            return result?.length ? result[0] : {};
        }
        if (langCode) {
            const result = data.filter((item: any) => {
                return item?.value === langCode;
            });
            return result?.length ? result[0] : {};
        }
    }
    return data;
}

export const mock = Mock;
