import { Modal } from 'antd';
import React from 'react';
import { TServiceDetail, TUserService } from '../../start-new-task/type';
import { AutoTrackTooltip } from '../../../components/tooltip';
import { ICDelete, ICDraggable } from '../../../icons';
import { formatNumber } from '../../../utils';
import { AutoTrackButton } from '../../../components/button';
import { UserAssigned } from './user_assigned';

type RowServiceProps = {
    serviceData: TServiceDetail;
    onChangeUserAssigned: (value: TUserService, index: number) => void;
    index: number;
    onDelete: (index: number) => void;
};
export const RowService: React.FC<RowServiceProps> = ({
    serviceData,
    onChangeUserAssigned,
    index,
    onDelete,
}) => {
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);

    return (
        <div className="group py-[8px] max-md:px-0 px-[16px] border-[1px] border-transparent hover:border-purple rounded-[8px]">
            <div className="flex max-md:items-start items-center justify-between gap-2">
                <div className="grid grid-cols-[auto_1fr] gap-[8px] max-md:items-start items-center w-full flex-wrap">
                    <div className="relative p-1 cursor-pointer">
                        <AutoTrackTooltip
                            content="Drag to reorder"
                            placement={'top'}
                            childNode={
                                <>
                                    <ICDraggable fill="#7357FF" />
                                </>
                            }
                        ></AutoTrackTooltip>
                    </div>
                    <div className="grid max-md:grid-cols-[1fr_1fr] grid-cols-[50px_1fr_200px] gap-[8px] justify-start flex-wrap max-md:items-start items-center w-full">
                        <div className="max-md:hidden">{index + 1}</div>
                        <div className="flex max-md:gap-2 gap-[16px] justify-start flex-wrap items-center">
                            <span>
                                <AutoTrackTooltip
                                    content={serviceData?.service?.name ?? ''}
                                    childNode={
                                        <div className="flex gap-[8px] items-center">
                                            {serviceData?.service?.name ?? ''}
                                        </div>
                                    }
                                ></AutoTrackTooltip>
                            </span>
                            <span className="text-base font-bold">
                                {formatNumber(serviceData?.service?.price ?? 0)}
                            </span>
                        </div>
                        <UserAssigned
                            serviceData={serviceData}
                            onChangeUserAssigned={onChangeUserAssigned}
                            index={index}
                        />
                    </div>
                </div>
                <div className="flex">
                    <span
                        className="p-1 cursor-pointer"
                        onClick={() => setShowDeleteModal(true)}
                    >
                        <ICDelete stroke="#A6A8B4" />
                    </span>
                </div>
            </div>

            <Modal
                centered
                onCancel={() => setShowDeleteModal(false)}
                open={showDeleteModal}
                title={
                    <div className="font-bold text-[18px]">Delete service</div>
                }
                footer={
                    <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                        <AutoTrackButton
                            btnType="sub"
                            btnSize="sm"
                            onClick={() => setShowDeleteModal(false)}
                        >
                            Cancel
                        </AutoTrackButton>
                        <AutoTrackButton
                            btnSize="sm"
                            onClick={() => {
                                onDelete(index);
                                setShowDeleteModal(false);
                            }}
                        >
                            Delete
                        </AutoTrackButton>
                    </div>
                }
            >
                <p>Are you sure you want to delete this service?</p>
            </Modal>
        </div>
    );
};
