import { IconProps } from './type';

export const ICNoneData: React.FC<IconProps> = ({
    width = 60,
    height = 60,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 129 107"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M71.8703 8.47813H17.125C13.2414 8.47813 10.0938 11.6258 10.0938 15.5094H117.906V5.59766C117.906 2.76875 115.614 0.474224 112.785 0.474224C105.564 0.474224 86.9359 0.474224 80.4063 0.474224C77.9547 0.474224 75.693 1.78907 74.4766 3.91485L71.8703 8.47813Z"
                fill="var(--primaryColor, #7357FF)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M120.173 80.7266C122.43 64.475 125.266 44.068 126.96 31.857C127.38 28.836 126.47 25.782 124.469 23.4805C122.465 21.1813 119.566 19.8594 116.514 19.8594C92.6171 19.8594 35.3827 19.8594 11.4858 19.8594C8.43427 19.8594 5.53505 21.1813 3.53114 23.4805C1.52958 25.782 0.620207 28.836 1.03974 31.857C1.86943 37.836 3.40692 48.9055 4.58114 57.3617C4.67021 58.0039 5.26317 58.4516 5.90302 58.3625C6.54286 58.2734 6.99286 57.6805 6.9038 57.0406L3.36005 31.5359C3.03427 29.1852 3.74208 26.8086 5.29833 25.0203C6.85693 23.232 9.11396 22.2031 11.4858 22.2031H116.514C118.886 22.2031 121.143 23.232 122.701 25.0203C124.258 26.8086 124.966 29.1852 124.64 31.5359L117.852 80.4031C117.763 81.0453 118.211 81.636 118.851 81.725C119.493 81.8141 120.083 81.3664 120.173 80.7266Z"
                fill="var(--primaryColor, #7357FF)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.64063 64.9953C7.375 77.4711 9.08125 89.7571 10.1547 97.4821C10.8789 102.697 15.3367 106.578 20.6008 106.578H107.399C111.438 106.578 115.002 104.295 116.77 100.878C117.067 100.304 116.842 99.5961 116.268 99.2985C115.691 99.0008 114.984 99.2258 114.688 99.8C113.313 102.458 110.54 104.234 107.399 104.234C88.1102 104.234 39.8898 104.234 20.6008 104.234C16.5063 104.234 13.0375 101.216 12.475 97.161C11.4016 89.4336 9.69531 77.1477 7.96328 64.6742C7.87422 64.032 7.28125 63.5844 6.64141 63.6735C6.00157 63.7625 5.55391 64.3555 5.64063 64.9953Z"
                fill="var(--primaryColor, #7357FF)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.1582 86.7172C24.1582 89.9539 26.7832 92.5766 30.0176 92.5766H48.7676C52.0043 92.5766 54.6269 89.9539 54.6269 86.7172V82.0297C54.6269 78.7953 52.0043 76.1703 48.7676 76.1703H30.0176C27.7887 76.1703 25.848 77.4172 24.859 79.2524C24.552 79.8219 24.7652 80.532 25.3348 80.8391C25.902 81.1461 26.6145 80.9328 26.9215 80.3656C27.5145 79.2641 28.6793 78.5141 30.0176 78.5141H48.7676C50.7105 78.5141 52.2832 80.0891 52.2832 82.0297V86.7172C52.2832 88.6602 50.7105 90.2328 48.7676 90.2328H30.0176C28.077 90.2328 26.502 88.6602 26.502 86.7172C26.502 86.0703 25.977 85.5453 25.3301 85.5453C24.6832 85.5453 24.1582 86.0703 24.1582 86.7172Z"
                fill="var(--primaryColor, #7357FF)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M88.6094 39.1719C75.6742 39.1719 65.1719 49.6742 65.1719 62.6094C65.1719 75.5445 75.6742 86.0469 88.6094 86.0469C101.545 86.0469 112.047 75.5445 112.047 62.6094C112.047 49.6742 101.545 39.1719 88.6094 39.1719ZM88.6094 43.8594C98.957 43.8594 107.359 52.2617 107.359 62.6094C107.359 72.957 98.957 81.3594 88.6094 81.3594C78.2617 81.3594 69.8594 72.957 69.8594 62.6094C69.8594 52.2617 78.2617 43.8594 88.6094 43.8594Z"
                fill="var(--primaryColor, #7357FF)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M102.257 78.7766L124.119 100.639C125.033 101.553 126.519 101.553 127.433 100.639C128.347 99.725 128.347 98.2391 127.433 97.325L105.571 75.4625C104.657 74.5461 103.171 74.5461 102.257 75.4625C101.343 76.3766 101.343 77.8625 102.257 78.7766Z"
                fill="var(--primaryColor, #7357FF)"
            />
        </svg>
    );
};
