import React from 'react';
import { FormInstance } from 'antd';
import { TStepItemProps } from '../config';
import { TCar, TRepairService, TService, TUserService } from '../type';

export type TCreateServicesData = {
    step: number;
    setStep: React.Dispatch<React.SetStateAction<number>>;
    onChangeStep: (step: number) => void;
    stepServices: TStepItemProps[];
    setStepServices: React.Dispatch<React.SetStateAction<TStepItemProps[]>>;
    addLicensePlateForm: FormInstance<any>;
    selectLicensePlateForm: FormInstance<any>;
    stepTwoForm: FormInstance<any>;

    carList: TCar[];
    setCarList: React.Dispatch<React.SetStateAction<TCar[]>>;
    carSelected: TCar | undefined;
    setCarSelected: React.Dispatch<React.SetStateAction<TCar | undefined>>;

    serviceList: TService[] | undefined;
    setServiceList: React.Dispatch<
        React.SetStateAction<TService[] | undefined>
    >;
    servicesSelected: TService[] | undefined;
    setServicesSelected: React.Dispatch<
        React.SetStateAction<TService[] | undefined>
    >;

    userServices: TUserService[] | undefined;
    setUserServices: React.Dispatch<
        React.SetStateAction<TUserService[] | undefined>
    >;

    repairServices: TRepairService[] | undefined;
    setRepairServices: React.Dispatch<
        React.SetStateAction<TRepairService[] | undefined>
    >;
    reviewServices: TRepairService[] | undefined;
    setReviewServices: React.Dispatch<
        React.SetStateAction<TRepairService[] | undefined>
    >;
};
export const CreateServicesDataContext =
    React.createContext<TCreateServicesData>({} as TCreateServicesData);
export const useCreateServices = () =>
    React.useContext(CreateServicesDataContext);
