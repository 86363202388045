import React from 'react';
import {
    EStatusOfService,
    StatusOfServiceColorMapping,
    StatusOfServiceMapping,
    StatusOfServiceStateMapping,
    TCarInService,
    TServiceDetail,
} from '../start-new-task/type';
import { ServicesActionsPage } from './services-actions';
import { Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';

type ViewServicesProps = {
    task: TCarInService;
    onUpdateStatus: (
        status: EStatusOfService,
        serviceSelection: TServiceDetail,
        task: TCarInService
    ) => void;
};

export const ViewServices: React.FC<ViewServicesProps> = ({
    task,
    onUpdateStatus,
}) => {
    const [taskSelected, setTaskSelected] = React.useState<
        TCarInService | undefined
    >(undefined);
    React.useEffect(() => {
        setTaskSelected(task);
    }, [task]);

    const onChangeStatusService = React.useCallback(
        (status: EStatusOfService, service: TServiceDetail) => {
            const newServices: TServiceDetail[] = taskSelected?.services?.map(
                item => {
                    if (item.serviceDetailId === service.serviceDetailId) {
                        return {
                            ...item,
                            serviceDetailStatus: status,
                        };
                    }
                    return item;
                }
            ) as TServiceDetail[];
            const newServiceDetail = newServices.find(
                item => item.serviceDetailId === service.serviceDetailId
            ) as TServiceDetail;
            const newTaskSelected: TCarInService = {
                ...taskSelected,
                services: newServices,
            };
            setTaskSelected(newTaskSelected);
            onUpdateStatus(status, newServiceDetail, newTaskSelected);
        },
        [onUpdateStatus, taskSelected]
    );

    const columns: ColumnsType<TServiceDetail> = [
        {
            title: 'No',
            dataIndex: 'serviceId',
            key: 'serviceId',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Service Name',
            dataIndex: 'service',
            key: 'service',
            render: service => {
                return service?.name;
            },
        },
        {
            title: 'Status',
            dataIndex: 'serviceDetailStatus',
            key: 'serviceDetailStatus',
            render: status => {
                const statusValue = StatusOfServiceMapping[status];
                return statusValue ? (
                    <Tag color={StatusOfServiceColorMapping[status]}>
                        {StatusOfServiceStateMapping[status]}
                    </Tag>
                ) : (
                    ''
                );
            },
        },
        {
            title: 'Assignee',
            dataIndex: 'user',
            key: 'user',
            render: user => user?.username,
        },
        {
            title: 'Duration',
            dataIndex: 'durationInMinutes',
            key: 'durationInMinutes',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <ServicesActionsPage
                    service={record}
                    size="small"
                    mobileMode={false}
                    oneService={false}
                    carInServiceSelected={task}
                    onUpdateStatus={onChangeStatusService}
                />
            ),
        },
    ];

    const renderFooter = React.useMemo(() => {
        const countDuration = task.services?.reduce(
            (acc, service) => acc + (service?.durationInMinutes || 0),
            0
        );
        return (
            <div className="flex flex-row items-center justify-between w-full">
                <div className="flex items-center gap-2">
                    <span className="text-muted">Duration:</span>
                    <span className="font-bold">{countDuration} hours</span>
                </div>
            </div>
        );
    }, [taskSelected?.services]);

    return (
        <div className="flex flex-col relative">
            <div className="relative flex overflow-auto max-h-full flex-col items-start gap-2 bg-white h-full w-full rounded-[8px] p-[10px]">
                <div className="flex flex-col w-full gap-2">
                    <Table
                        key="service-list"
                        className="white-header w-full"
                        columns={columns}
                        id="serviceDetailId"
                        dataSource={taskSelected?.services ?? []}
                        rowClassName="cursor-pointer data-row"
                        rowKey="serviceDetailId"
                        locale={{
                            emptyText: (
                                <div className="flex flex-col items-center justify-center w-full h-[200px]">
                                    No data
                                </div>
                            ),
                        }}
                        pagination={false}
                        footer={() => renderFooter}
                    />
                </div>
            </div>
        </div>
    );
};
