import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TUserLocation = {
    Country: string;
    CountryName: string;
    Region: string;
    City: string;
    Postal: string;
    Latitude: string;
    Longitude: string;
};

type CommonData = {
    userLocation?: TUserLocation;
};

const initialState: CommonData = {};

const commonDataSlice = createSlice({
    name: 'commonData',
    initialState,
    reducers: {
        setUserLocation: (
            state: CommonData,
            action: PayloadAction<TUserLocation>
        ) => {
            state.userLocation = action.payload;
        },
    },
});

export const { setUserLocation } = commonDataSlice.actions;

export default commonDataSlice.reducer;
