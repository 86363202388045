import React from 'react';
import { useForm } from 'antd/lib/form/Form';
import { TCar } from '../start-new-task/type';
import { Drawer, FormInstance } from 'antd';
import { LicensePlateForm } from './components/license-plate';
import { AutoTrackButton } from '../../components/button';
import styled from 'styled-components';
import { CameraControl } from './components/camera-control';
import Webcam from 'react-webcam';
import { useScreenSize } from '../../hooks/useScreenSize';
import { FileUpload } from './components/FileUpload';

const WrapperDocuments = styled.div`
    .ant-upload-wrapper.ant-upload-picture-card-wrapper {
        width: 100%;
        height: 100%;
        .ant-upload.ant-upload-select {
            border-color: #7357ff !important;
            width: 100%;
            height: 100%;
            padding: 5px;
        }
    }
`;

const DrawerBottom = styled(Drawer)`
    .ant-drawer-body {
        padding: 0;
    }
`;

export type TAddReceiptsData = {
    carList: TCar[];
    setCarList: React.Dispatch<React.SetStateAction<TCar[]>>;
    carSelected: TCar | undefined;
    setCarSelected: React.Dispatch<React.SetStateAction<TCar | undefined>>;
    addLicensePlateForm: FormInstance<any>;
    selectLicensePlateForm: FormInstance<any>;
};
export const AddReceiptsDataContext = React.createContext<TAddReceiptsData>(
    {} as TAddReceiptsData
);
export const useAddReceipts = () => React.useContext(AddReceiptsDataContext);

export const AddReceiptsPage: React.FC = () => {
    const [carList, setCarList] = React.useState<TCar[]>([]);
    const [carSelected, setCarSelected] = React.useState<TCar | undefined>();
    const [addLicensePlateForm] = useForm();
    const [selectLicensePlateForm] = useForm();

    const webcamRef = React.useRef<Webcam>(null);
    const size = useScreenSize();
    const mobileMode = size === 'xs' || size === 'sm';
    const [openCamera, setOpenCamera] = React.useState(false);

    return (
        <AddReceiptsDataContext.Provider
            value={{
                carList,
                setCarList,
                carSelected,
                setCarSelected,
                addLicensePlateForm,
                selectLicensePlateForm,
            }}
        >
            <div className="flex flex-col w-full bg-white max-md:p-[16px] p-[32px] rounded-[8px]">
                <div className="grid grid-cols-1 gap-[20px]">
                    <LicensePlateForm />
                    <div className="flex flex-col gap-2 w-full">
                        <h4>Documents</h4>
                        <WrapperDocuments className="flex w-full gap-[15px]">
                            <FileUpload setOpenCamera={setOpenCamera} />
                        </WrapperDocuments>
                    </div>
                    <div className="flex justify-start pt-[30px]">
                        <span className="max-md:w-full w-1/6">
                            <AutoTrackButton
                                type="primary"
                                htmlType="submit"
                                btnSize="md"
                                classess="max-md:w-full"
                                btnType="primary"
                            >
                                Save
                            </AutoTrackButton>
                        </span>
                    </div>
                </div>
                <DrawerBottom
                    title="Take a photo"
                    onClose={() => setOpenCamera(false)}
                    open={openCamera}
                    destroyOnClose
                    zIndex={1000}
                    width="100%"
                    height="100%"
                    placement="bottom"
                >
                    <div className="flex h-full flex-col justify-between w-full gap-3">
                        <div className="flex w-full h-full mt-[0]">
                            <CameraControl
                                webcamRef={webcamRef}
                                isMobile={mobileMode}
                            />
                        </div>
                    </div>
                </DrawerBottom>
            </div>
        </AddReceiptsDataContext.Provider>
    );
};
