import { IconProps } from './type';

export const ICDraggable: React.FC<IconProps> = ({ fill = '#A6A8B4' }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.5 17C7.4 17 6.5 17.9 6.5 19C6.5 20.1 7.4 21 8.5 21C9.6 21 10.5 20.1 10.5 19C10.5 17.9 9.6 17 8.5 17ZM15.5 7C16.6 7 17.5 6.1 17.5 5C17.5 3.9 16.6 3 15.5 3C14.4 3 13.5 3.9 13.5 5C13.5 6.1 14.4 7 15.5 7ZM8.5 10C7.4 10 6.5 10.9 6.5 12C6.5 13.1 7.4 14 8.5 14C9.6 14 10.5 13.1 10.5 12C10.5 10.9 9.6 10 8.5 10ZM15.5 10C14.4 10 13.5 10.9 13.5 12C13.5 13.1 14.4 14 15.5 14C16.6 14 17.5 13.1 17.5 12C17.5 10.9 16.6 10 15.5 10ZM15.5 17C14.4 17 13.5 17.9 13.5 19C13.5 20.1 14.4 21 15.5 21C16.6 21 17.5 20.1 17.5 19C17.5 17.9 16.6 17 15.5 17ZM8.5 3C7.4 3 6.5 3.9 6.5 5C6.5 6.1 7.4 7 8.5 7C9.6 7 10.5 6.1 10.5 5C10.5 3.9 9.6 3 8.5 3Z"
                fill={fill}
            />
        </svg>
    );
};
