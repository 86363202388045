import { Checkbox, CheckboxProps } from 'antd';

type AutoTrackCheckBoxProps = CheckboxProps;

export const AutoTrackCheckBox: React.FC<AutoTrackCheckBoxProps> = ({
    ...props
}) => {
    return (
        <Checkbox
            className="purple-checkbox"
            {...props}
            style={{
                fontSize: '12px',
                fontWeight: 500,
            }}
        />
    );
};
