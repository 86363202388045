import { Table, Row, Modal, Input, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { SortOrder } from 'antd/es/table/interface';
import classNames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useSortData } from '.';
import { AutoTrackButton, IconButton } from '../../components/button';
import { ICSort, ICAdd, ICNoneData } from '../../icons';
import { ICMaterialSymbol } from '../../icons/material-symbol';
import { CarForm } from './car';
import { TCar } from '../start-new-task/type';
import { AutoTrackPagination } from '../../components/pagination';
import { useNotification } from '../../hooks/useNotification';
import dayjs from 'dayjs';
import { setLoading } from '../../redux/slices/appInfo';
import { updateCar, createCar, deleteCar } from '../../api/setting/cars';
import {
    Direction,
    IPagination,
    SearchQueryParams,
} from '../../interface/paging';
import debouce from 'lodash/debounce';
import { removeUndefinedAttribute } from '../../utils';

const ActionMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    cursor: pointer;
    padding-right: 16px;
    &:hover {
        background-color: #f7f8fa;
    }
    button {
        padding: 0 !important;
    }
`;

const WrapperTable = styled.div`
    .ant-table-thead > tr > th {
        background-color: white;
    }
    .data-row:nth-child(odd) {
        background-color: #f7f8fa;
    }
    .ant-table-column-sorters .ant-table-column-sorter-inner {
        .anticon {
            display: none;
        }
    }
`;

const WrapperInput = styled.div`
    @media (max-width: 768px) {
    .ant-input-group-wrapper {
        width: 100% !important;
    }
`;

const WrapperButton = styled.div`
    @media (max-width: 768px) {
        width: 100%;
        .ant-btn {
            width: 100% !important;
        }
    }
`;

type SortableColumnProps = {
    title: string;
    order?: SortOrder;
    name: string;
};
const SortableColumn: React.FC<SortableColumnProps> = ({
    title,
    order,
    name,
}) => {
    const { setSortData } = useSortData();
    return (
        <div
            className="flex space-x-3 items-center justify-between select-none group"
            onClick={() => {
                setSortData({
                    field: order === 'descend' ? 'createdDate' : name,
                    order:
                        order === 'descend' ? 'desc' : !order ? 'asc' : 'desc',
                });
            }}
        >
            <span className="text-body-bold text-high-em">
                {title as string}
            </span>
            <ICSort order={order} />
        </div>
    );
};

type CarPageListProps = {
    carList: TCar[];
    keyword?: string;
    pagination?: IPagination;
    onChangePaging: ({ page }: { page: number }) => void;
    onReload: () => void;
    filterParams: SearchQueryParams;
    onFilterData: (payload: SearchQueryParams) => void;
};
const KeywordContext = React.createContext<string>('');

export const CarPageList: React.FC<CarPageListProps> = ({
    carList,
    keyword,
    pagination,
    onChangePaging,
    onReload,
    filterParams,
    onFilterData,
}) => {
    const dispatch = useDispatch();
    const { showSuccess, showError } = useNotification();
    const onChangePage = (page: number) => {
        onChangePaging({ page });
    };
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [carId, setCarId] = React.useState<string | undefined>('');
    const [showCarDialog, setShowCarDialog] = React.useState(false);
    const [carSelected, setCarSelected] = React.useState<TCar | undefined>(
        undefined
    );
    const [form] = useForm();

    // handle search keyword
    const [searchColumn, setSearchColumn] = React.useState<string>('Make');
    const [searchWord, setSearchWord] = React.useState<string | undefined>('');
    const selectBeforeOptions = [
        'Make',
        'Model',
        'Year',
        'Color',
        'VIN',
        'LicensePlate',
    ];
    const onSearchData = React.useCallback(
        async (searchWord: string | undefined, searchColumnTemp?: string) => {
            try {
                const queryParams: SearchQueryParams = {
                    page: 1,
                    pageSize: filterParams?.pageSize || 10,
                    searchWord: searchWord,
                    sortBy: Direction.ASC,
                    searchColum: searchColumnTemp || searchColumn,
                };
                const newPayload = removeUndefinedAttribute(queryParams);
                onFilterData(newPayload);
            } catch (error) {
                // Handle error
            }
        },
        [filterParams, searchColumn]
    );

    const handleSearchData = (value: string | undefined) => {
        onSearchKeyword(value);
        setSearchWord(value);
    };

    const onSearchKeyword = React.useMemo(() => {
        return debouce(onSearchData, 600);
    }, [onSearchData]);

    React.useEffect(() => {
        return () => {
            onSearchKeyword.cancel();
        };
    }, [onSearchKeyword]);
    // end handle search keyword

    const onShowDeleteModal = React.useCallback(
        (id: string) => {
            setCarId(id);
            setShowDeleteModal(true);
        },
        [setShowDeleteModal]
    );

    const columns: ColumnsType<TCar> = [
        {
            title: 'Name',
            dataIndex: 'make',
            field: 'make',
            key: 'make',
            sorter: true,
        },
        {
            title: 'Model',
            dataIndex: 'model',
            field: 'model',
            key: 'model',
            sorter: true,
        },
        {
            title: 'Year',
            dataIndex: 'year',
            field: 'year',
            key: 'year',
            sorter: true,
        },
        {
            title: 'Color',
            dataIndex: 'color',
            field: 'color',
            key: 'color',
            sorter: true,
        },
        {
            title: 'VIN',
            dataIndex: 'vin',
            field: 'vin',
            key: 'vin',
            sorter: true,
        },
        {
            title: 'License Plate',
            dataIndex: 'licensePlate',
            field: 'licensePlate',
            key: 'licensePlate',
            sorter: true,
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            field: 'createdAt',
            key: 'createdAt',
            sorter: true,
            render: (val: string) => {
                const newDate = dayjs(val, 'YYYY-MM-DD').format('YYYY-MM-DD');
                return newDate;
            },
        },
        {
            title: 'Action',
            dataIndex: 'carId',
            key: 'carId',
            width: '40px',
            render: (carId: string, data: TCar) => {
                return (
                    <ActionMenu>
                        <IconButton
                            icon={<ICMaterialSymbol icon="edit_square" />}
                            btnType="tertiary"
                            onClick={() => {
                                setCarSelected(data);
                                setShowCarDialog(true);
                            }}
                        />
                        <IconButton
                            icon={<ICMaterialSymbol icon="delete" />}
                            onClick={() => onShowDeleteModal(carId)}
                            btnType="tertiary"
                        />
                    </ActionMenu>
                );
            },
        },
    ].map((col: ColumnType<TCar>) => {
        if (col.sorter) {
            const oldTitle = col.title;
            col.title = ({ sortColumns }) => {
                const sortedColumn = sortColumns?.find(
                    ({ column }) => column.key === col.key
                );
                return (
                    <SortableColumn
                        title={oldTitle as string}
                        order={sortedColumn?.order}
                        name={col.dataIndex as string}
                    />
                );
            };
        } else {
            col.title = (
                <div className="text-body-bold text-high-em">
                    {col.title as string}
                </div>
            );
        }
        col.showSorterTooltip = false;
        return col;
    });

    const doSaveCar = React.useCallback(
        async (carId: string | undefined | number, carData: TCar) => {
            try {
                dispatch(setLoading(true));
                if (carId) {
                    const result = await updateCar(carId, carData);
                    if (result.status === 200) {
                        showSuccess('Save Car success', 'Car has been updated');
                    }
                } else {
                    const result = await createCar(carData);
                    if (result.status === 201) {
                        showSuccess('Save Car success', 'Car has been created');
                    }
                }
                onReload();
                setShowCarDialog(false);
            } catch (error: any) {
                const errorMessage =
                    error?.response?.data?.detail ||
                    'Please check your input data';
                showError('Save Car failed', errorMessage);
            } finally {
                dispatch(setLoading(false));
            }
        },
        [carList, dispatch, showSuccess, showError]
    );

    const onSaveCar = React.useCallback(() => {
        form.validateFields()
            .then(values => {
                const carData = {
                    ...carSelected,
                    ...values,
                    year: values.year.format('YYYY'),
                };
                if (carSelected) {
                    doSaveCar(carSelected.carId, carData);
                } else {
                    doSaveCar(undefined, carData);
                }
            })
            .catch(() => {
                showError('Save Car failed', 'Please check your input data');
            });
    }, [form, carSelected, showError]);

    const onDeleteCar = React.useCallback(async () => {
        if (!carId) {
            return;
        }
        try {
            dispatch(setLoading(true));
            const result = await deleteCar(carId);
            setShowDeleteModal(false);
            if (result.status === 200) {
                showSuccess('Delete Car success', 'Car has been deleted');
                onReload();
            }
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.detail ||
                'Error occurred while deleting Car';
            showError('Delete Car failed', errorMessage);
        } finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, carId, showSuccess, showError]);

    return (
        <WrapperTable className="flex flex-col gap-[20px] w-full overflow-hidden h-full items-start box-border">
            <div className="flex w-full px-[12px] py-[20px] shadow-e-03 max-md:justify-center items-center max-md:flex-wrap gap-3 justify-between bg-white rounded-[12px]">
                <div className="flex gap-3 items-center justify-center w-full flex-wrap">
                    <WrapperInput className="flex items-center w-full gap-2">
                        <Input
                            placeholder="Search by ..."
                            value={searchWord}
                            onChange={e => handleSearchData(e.target.value)}
                            style={{
                                width: '420px',
                            }}
                            addonBefore={
                                <>
                                    <Select
                                        className="select-before"
                                        defaultValue="Make"
                                        value={searchColumn}
                                        style={{ width: 122 }}
                                        onChange={(value: string) => {
                                            setSearchColumn(value);
                                            onSearchData(searchWord, value);
                                        }}
                                    >
                                        {selectBeforeOptions.map(
                                            (item, index) => (
                                                <Select.Option
                                                    key={index}
                                                    value={item}
                                                >
                                                    {item}
                                                </Select.Option>
                                            )
                                        )}
                                    </Select>
                                </>
                            }
                            size="large"
                        />
                    </WrapperInput>
                </div>
                <WrapperButton className="flex gap-[10px]">
                    <AutoTrackButton
                        btnType="primary"
                        onClick={() => {
                            setCarSelected(undefined);
                            setShowCarDialog(true);
                        }}
                        style={{
                            width: '220px',
                        }}
                        btnSize="md"
                    >
                        <div className="flex space-x-2 items-center">
                            <ICAdd stroke="#fff" width={18} height={18} />
                            <span>Create new Car</span>
                        </div>
                    </AutoTrackButton>
                </WrapperButton>
            </div>
            <div className="flex flex-col w-full h-full bg-white rounded-[12px] overflow-hidden shadow-e-03">
                <KeywordContext.Provider value={keyword ?? ''}>
                    <Table
                        key="car-list"
                        className={classNames('white-header w-full')}
                        columns={columns}
                        dataSource={carList}
                        rowClassName="cursor-pointer data-row"
                        rowKey="carId"
                        scroll={{ x: 'max-content' }}
                        locale={{
                            emptyText: (
                                <EmptyTable
                                    keyword={keyword}
                                    onCreateNew={() => {
                                        setCarSelected(undefined);
                                        setShowCarDialog(true);
                                    }}
                                />
                            ),
                        }}
                        pagination={false}
                    />
                </KeywordContext.Provider>
                {pagination?.totalPage && pagination?.totalPage > 1 ? (
                    <Row
                        gutter={16}
                        style={{
                            margin: 0,
                        }}
                        className="grid items-center max-md:justify-center justify-end w-full bg-white p-[16px] border-t border-solid border-outline-med rounded-b-[12px]"
                    >
                        <AutoTrackPagination
                            onChange={onChangePage}
                            defaultCurrent={pagination?.page}
                            defaultPageSize={pagination?.pageSize}
                            total={pagination?.totalCount}
                            currentPage={pagination?.page}
                            showSizeChanger={false}
                        />
                    </Row>
                ) : null}
            </div>

            <Modal
                centered
                onCancel={() => setShowDeleteModal(false)}
                open={showDeleteModal}
                title={<div className="font-bold text-[18px]">Confirm</div>}
                footer={
                    <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                        <AutoTrackButton
                            btnType="sub"
                            btnSize="sm"
                            onClick={() => setShowDeleteModal(false)}
                        >
                            Cancel
                        </AutoTrackButton>
                        <AutoTrackButton
                            btnType="danger"
                            btnSize="sm"
                            onClick={onDeleteCar}
                        >
                            Delete
                        </AutoTrackButton>
                    </div>
                }
            >
                <p>Are you sure you want to delete this Car?</p>
            </Modal>

            <Modal
                centered
                onCancel={() => setShowCarDialog(false)}
                open={showCarDialog}
                maskClosable={false}
                destroyOnClose
                afterClose={() => form.resetFields()}
                title={
                    <div className="font-bold text-[18px]">
                        {carSelected ? 'Edit Car' : 'New Car Register'}
                    </div>
                }
                footer={
                    <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                        <AutoTrackButton
                            btnType="sub"
                            btnSize="sm"
                            onClick={() => setShowCarDialog(false)}
                        >
                            Cancel
                        </AutoTrackButton>
                        <AutoTrackButton
                            btnType="primary"
                            btnSize="sm"
                            onClick={onSaveCar}
                        >
                            Save
                        </AutoTrackButton>
                    </div>
                }
            >
                <div className="flex flex-col gap-[20px] w-full overflow-hidden h-full items-start box-border">
                    <CarForm carData={carSelected} form={form} />
                </div>
            </Modal>
        </WrapperTable>
    );
};

type IEmptyTableProps = {
    keyword: string | undefined;
    onCreateNew: () => void;
};

const EmptyTable: React.FC<IEmptyTableProps> = ({ keyword, onCreateNew }) => {
    return (
        <div className="my-6 flex flex-col gap-[5px]">
            <div className="flex justify-center">
                <ICNoneData />
            </div>
            <div className="text-standard-bold text-high-em">
                {keyword
                    ? `No data found with keyword “${keyword}”`
                    : 'No data found'}
            </div>
            <div className="flex justify-center">
                <span>
                    <AutoTrackButton
                        btnType="outline"
                        btnSize="sm"
                        style={{
                            borderRadius: '144px',
                        }}
                        onClick={() => onCreateNew()}
                    >
                        <div className="flex space-x-2 items-center">
                            <ICAdd stroke="#7357FF" width={18} height={18} />
                            <span>Create new Car</span>
                        </div>
                    </AutoTrackButton>
                </span>
            </div>
        </div>
    );
};
