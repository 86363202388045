import { AutoComplete, SelectProps } from 'antd';
import React from 'react';
import { TServiceDetail, TUserService } from '../../start-new-task/type';
import debouce from 'lodash/debounce';
import { Direction, SearchQueryParams } from '../../../interface/paging';
import { removeUndefinedAttribute } from '../../../utils';
import { getUsersPaging } from '../../../api/setting/users';

type UserAssignedProps = {
    serviceData: TServiceDetail;
    index: number;
    onChangeUserAssigned: (value: TUserService, index: number) => void;
};
export const UserAssigned: React.FC<UserAssignedProps> = ({
    serviceData,
    onChangeUserAssigned,
    index,
}) => {
    const [userSelected, setUserSelected] = React.useState<
        string | undefined
    >();
    const [usersOptions, setUsersOptions] = React.useState<
        SelectProps<object>['options']
    >([]);
    const [users, setUsers] = React.useState<TUserService[]>([]);
    React.useEffect(() => {
        if (serviceData?.user && serviceData?.user?.username) {
            setUserSelected(serviceData?.user?.username);
        }
    }, [serviceData]);

    const onSelectUser = (value: string, index: number) => {
        const userSelection =
            (users?.find(user => user.username === value) as TUserService) ||
            undefined;
        if (userSelection) {
            onChangeUserAssigned(userSelection, index);
            setUserSelected(value);
        }
    };

    const loadUserServices = React.useCallback(async (searchWord: string) => {
        try {
            const queryParams: SearchQueryParams = {
                page: 1,
                pageSize: 10,
                searchWord: searchWord,
                sortBy: Direction.ASC,
                searchColum: 'Username',
            };
            const newPayload = removeUndefinedAttribute(queryParams);
            const result = await getUsersPaging(newPayload);
            if (result.status === 200) {
                const data = result.data?.data ?? [];
                setUsers(data);
                const options = data.map(item => ({
                    value: item.username,
                }));
                setUsersOptions(options);
            }
        } catch (error) {
            // Handle error
        }
    }, []);

    const handleSearchUsers = (value: string) => {
        onSearchKeywordUsers(value);
        setUserSelected(value);
    };

    const onSearchKeywordUsers = React.useMemo(() => {
        return debouce(loadUserServices, 600);
    }, [loadUserServices]);

    React.useEffect(() => {
        return () => {
            onSearchKeywordUsers.cancel();
        };
    }, [onSearchKeywordUsers]);

    return (
        <div key={index} className="flex w-full">
            {/* {serviceData?.user?.username} */}
            <AutoComplete
                options={usersOptions}
                className="w-full"
                size="large"
                showSearch
                value={userSelected}
                popupClassName="certain-category-search-dropdown"
                placeholder="Assignee"
                onSearch={handleSearchUsers}
                onSelect={value => onSelectUser(value, index)}
            />
        </div>
    );
};
