import React from 'react';

type FieldsetPageProps = {
    title: string;
    child: React.ReactNode;
};

export const FieldsetPage: React.FC<FieldsetPageProps> = ({ title, child }) => {
    return (
        <div className="flex flex-col items-start relative w-full h-full max-md:py-[16px] max-md:px-0 px-[16px] py-[20px] border rounded-[8px] bg-white">
            <div className="absolute top-[-18px] bg-white p-[5px] text-body-medium left-[15px]">
                {title}
            </div>
            {child}
        </div>
    );
};
