import React from 'react';
import {
    EStatusOfService,
    StatusOfServiceColorMapping,
    StatusOfServiceStateMapping,
    TCarInService,
    TServiceDetail,
} from '../start-new-task/type';
import { ServicesActionsPage } from './services-actions';
import { Card, Tag } from 'antd';
import classNames from 'classnames';
import styled from 'styled-components';

const WrapperContent = styled.div`
    max-height: calc(100vh - 170px);
    overflow-y: auto;
`;

type CardServicesProps = {
    task: TCarInService;
    onUpdateStatus: (
        status: EStatusOfService,
        serviceSelection: TServiceDetail,
        task: TCarInService
    ) => void;
};

export const CardServices: React.FC<CardServicesProps> = ({
    task,
    onUpdateStatus,
}) => {
    const [taskSelected, setTaskSelected] = React.useState<
        TCarInService | undefined
    >(undefined);
    const [services, setServices] = React.useState<TServiceDetail[]>([]);

    React.useEffect(() => {
        setTaskSelected(task);
        setServices(task.services ?? []);
    }, [task]);

    const onChangeStatusService = React.useCallback(
        (status: EStatusOfService, service: TServiceDetail) => {
            const newServices: TServiceDetail[] = taskSelected?.services?.map(
                item => {
                    if (item.serviceDetailId === service.serviceDetailId) {
                        return {
                            ...item,
                            serviceDetailStatus: status,
                        };
                    }
                    return item;
                }
            ) as TServiceDetail[];
            const newServiceDetail = newServices.find(
                item => item.serviceDetailId === service.serviceDetailId
            ) as TServiceDetail;
            const newTaskSelected: TCarInService = {
                ...taskSelected,
                services: newServices,
            };
            setTaskSelected(newTaskSelected);
            setServices(newServices);
            onUpdateStatus(status, newServiceDetail, newTaskSelected);
        },
        [onUpdateStatus, taskSelected]
    );

    return (
        <div className="flex flex-col relative">
            <div className="relative flex overflow-auto max-h-full flex-col items-start gap-2 bg-white h-full w-full rounded-[8px]">
                <WrapperContent className="flex flex-col w-full gap-[15px] p-[16px]">
                    {services.map((service, index) => (
                        <Card
                            key={`${service.serviceDetailId}_${index}`}
                            className={classNames([
                                'relative flex flex-col cursor-pointer rounded-[12px] hover:ring-2 hover:ring-[var(--primaryColor)] shadow-card-shadow',
                            ])}
                        >
                            <div className="flex flex-col gap-[10px] relative">
                                <div className="flex flex-row items-center justify-between">
                                    <div className="flex flex-row items-center gap-2">
                                        <span className="text-lg font-bold">
                                            {service.service?.name}
                                        </span>
                                    </div>
                                </div>
                                <div className="grid grid-cols-[80px_1fr] flex-row items-center justify-start gap-2">
                                    <span className="text-muted">Assigned</span>
                                    <span className="font-bold underline">
                                        {service.user?.username || 'N/A'}
                                    </span>
                                </div>
                                {service.serviceDetailStatus && (
                                    <div className="grid grid-cols-[80px_1fr] items-center justify-start gap-2">
                                        <span className="text-muted">
                                            Status
                                        </span>
                                        <span className="font-bold">
                                            <Tag
                                                color={
                                                    StatusOfServiceColorMapping[
                                                        service
                                                            .serviceDetailStatus
                                                    ]
                                                }
                                            >
                                                {
                                                    StatusOfServiceStateMapping[
                                                        service
                                                            .serviceDetailStatus
                                                    ]
                                                }
                                            </Tag>
                                        </span>
                                    </div>
                                )}
                                <div className="grid grid-cols-[80px_1fr] items-center justify-start gap-2">
                                    <span className="text-muted">Duration</span>
                                    <span className="font-bold">
                                        {service.durationInMinutes || 0} minutes
                                    </span>
                                </div>

                                <div className="flex w-full">
                                    <ServicesActionsPage
                                        service={service}
                                        size="small"
                                        oneService
                                        mobileMode={true}
                                        carInServiceSelected={task}
                                        onUpdateStatus={onChangeStatusService}
                                    />
                                </div>
                            </div>
                        </Card>
                    ))}

                    {/* <Table
                        key="service-list"
                        className="white-header w-full"
                        columns={columns}
                        id="serviceDetailId"
                        dataSource={taskSelected?.services ?? []}
                        rowClassName="cursor-pointer data-row"
                        rowKey="serviceDetailId"
                        locale={{
                            emptyText: (
                                <div className="flex flex-col items-center justify-center w-full h-[200px]">
                                    No data
                                </div>
                            ),
                        }}
                        pagination={false}
                        footer={() => renderFooter}
                    /> */}
                </WrapperContent>
            </div>
        </div>
    );
};
