import { Segmented, Select } from 'antd';
import React from 'react';
import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import {
    EStatusCarInService,
    PriorityOptions,
    StatusOptions,
} from '../start-new-task/type';
import { ViewMode } from './type';
import classNames from 'classnames';

const Wrapper = styled.div`
    .active-primary {
        .ant-segmented-item-selected {
            background-color: var(--primaryColor);
            color: white;
        }
    }
    .ant-segmented.active-primary {
        @media (max-width: 768px) {
            width: 100%;
            .ant-segmented-group {
                display: flex;
                justify-content: space-between;
                width: 100%;
                .ant-segmented-item {
                    flex: 1;
                }
            }
        }
    }
`;

type FilterTaskPageProps = {
    viewMode: ViewMode;
    setViewMode: React.Dispatch<React.SetStateAction<ViewMode>>;
    statusSelected: string;
    prioritySelected: string;
    mobileMode: boolean;
    onChangeStatus: (status: EStatusCarInService) => void;
    onChangePriority: (priority: string) => void;
};

export const FilterTaskPage: React.FC<FilterTaskPageProps> = ({
    viewMode,
    setViewMode,
    statusSelected,
    prioritySelected,
    mobileMode,
    onChangeStatus,
    onChangePriority,
}) => {
    const priorityOptions = PriorityOptions.map(option => {
        return {
            ...option,
        };
    });
    const statusOptions = StatusOptions.map(option => ({
        ...option,
    }));
    return (
        <Wrapper className="flex max-md:flex-col-reverse max-md:items-start flex-row items-center justify-between flex-wrap gap-3 w-full relative">
            <div className="max-md:rounded-[12px] max-md:flex max-md:w-full max-md:p-[12px] max-md:bg-white">
                <Segmented
                    className="active-primary"
                    value={statusSelected}
                    options={statusOptions}
                    onChange={(status: string) => {
                        onChangeStatus(status as EStatusCarInService);
                    }}
                />
            </div>

            <div className="flex justify-end items-center max-md:w-full gap-3">
                <div className="w-full">
                    <Select
                        rootClassName={classNames([
                            {
                                'w-full': mobileMode,
                                'w-[100px]': !mobileMode,
                            },
                        ])}
                        size="large"
                        value={prioritySelected}
                        options={priorityOptions.map(option => ({
                            label: option.label,
                            value: option.value,
                        }))}
                        onSelect={onChangePriority}
                    />
                </div>
                {!mobileMode && (
                    <div>
                        <Segmented
                            value={viewMode}
                            onChange={setViewMode}
                            options={[
                                {
                                    value: ViewMode.TABLE,
                                    icon: <BarsOutlined />,
                                },
                                {
                                    value: ViewMode.LIST,
                                    icon: <AppstoreOutlined />,
                                },
                            ]}
                        />
                    </div>
                )}
            </div>
        </Wrapper>
    );
};
