import { Layout, theme as themeAntd } from 'antd';
import React from 'react';
import { WrapperLayout } from './styled';
import classNames from 'classnames';
import { Breadcrumbs } from './components/breadcrumbs';
import { HeaderAdmin } from './components/header';
import { SiderAdmin } from './components/sider';
import { Title } from './components/title';
import { TThemeConfig, TTheme, getThemes } from '../api/theme';
import { Theming } from '../pages/theming';
import { useScreenSize } from '../hooks/useScreenSize';

type AdminLayoutProps = {
    children: React.ReactNode;
};
export const AdminLayout: React.FC<AdminLayoutProps> = ({ children }) => {
    const { Header, Content } = Layout;
    const [theme, setTheme] = React.useState<TThemeConfig | null>(null);
    const [collapsed, setCollapsed] = React.useState<boolean>(false);
    const [themeList, setThemeList] = React.useState<TTheme[]>([]);
    const {
        token: { borderRadiusLG },
    } = themeAntd.useToken();
    const size = useScreenSize();
    const mobileMode = size === 'xs' || size === 'sm';

    const onSelectedTheme = (theme: TThemeConfig) => {
        setTheme(theme);
    };

    // load theme
    const loadTheme = React.useCallback(async () => {
        try {
            const result = await getThemes('all');
            if (result?.data) {
                setThemeList(result.data);
            }
        } catch (error) {
            // TODO: handle error
        }
    }, []);

    React.useEffect(() => {
        loadTheme();
    }, []);

    return (
        <WrapperLayout className="layout-page">
            <Header
                style={{
                    display: 'flex',
                    width: '100%',
                    height: '60px',
                    backgroundColor: 'white',
                    zIndex: 999,
                    position: 'fixed',
                    top: 0,
                    borderBottom: '1px solid #d7d7d7',
                    padding: '0',
                    boxShadow: '0 4px 10px #ddd',
                }}
            >
                <HeaderAdmin
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    data={themeList}
                    onSelectedTheme={onSelectedTheme}
                />
            </Header>
            <Layout>
                <SiderAdmin collapsed={collapsed} setCollapsed={setCollapsed} />
                <Content
                    className={classNames([
                        'px-[16px] min-h-[100vh] pt-[60px]',
                        { 'layout-page-content': !collapsed && !mobileMode },
                        {
                            'layout-page-content-collapsed':
                                collapsed && !mobileMode,
                        },
                    ])}
                    style={{
                        borderRadius: borderRadiusLG,
                    }}
                >
                    <div className="flex flex-row justify-between items-center w-full max-md:pl-[2px] px-[16px] py-[16px] max-md:pt-[16px] pt-[0]">
                        <Title />
                        {!mobileMode && <Breadcrumbs />}
                    </div>
                    <>{children}</>
                </Content>
            </Layout>
            <Theming
                primaryColor={theme?.colorPrimary}
                secondaryColor={theme?.colorSecondary}
                fontFamily={theme?.fontFamily}
            />
        </WrapperLayout>
    );
};
