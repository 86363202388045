import { ApiInstance } from '..';

export type TThemeConfig = {
    fontFamily: string;
    colorPrimary: string | undefined;
    colorSecondary: string | undefined;
};

export type TTheme = {
    id: number;
    name: string;
    value: string;
    config: TThemeConfig;
};

export const getThemes = (theme: string) => {
    return ApiInstance.getInstance().get('/theme/list/mock?theme=' + theme);
};
