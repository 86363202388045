import { Tooltip, TooltipProps } from 'antd';
import { ICAlertCircle } from '../icons/index';
import { htmlToText } from '../utils/htmlToText';
import classNames from 'classnames';
import React from 'react';

type AutoTrackTooltipProps = TooltipProps & {
    content: string;
    childNode: React.ReactNode;
    hasInfoIcon?: boolean;
    theme?: 'gray' | 'black';
    level?: 'low' | 'high';
    sign?: 'info' | 'error';
};

export const AutoTrackTooltip: React.FC<AutoTrackTooltipProps> = ({
    content,
    childNode,
    hasInfoIcon = false,
    theme = 'gray',
    level = 'low',
    sign = 'info',
    ...props
}) => {
    return (
        <Tooltip
            placement="bottom"
            overlayClassName={theme}
            // open={true}
            zIndex={1000}
            overlayStyle={{ maxWidth: '358px', maxHeight: '300px' }}
            overlayInnerStyle={{
                padding: '8px 16px',
                background:
                    level === 'low'
                        ? 'var(--tc-system-info-50)'
                        : 'var(--hr-surface-info)',
                borderRadius: '4px',
                overflow: 'auto',
                boxShadow: '0px 1px 2px -1px rgba(17, 12, 34, 0.08)',
            }}
            title={
                <span className="grid grid-cols-[auto_auto] gap-[10px] items-center text-left">
                    {hasInfoIcon && (
                        <ICAlertCircle
                            fill={
                                sign === 'info'
                                    ? 'var(--text-info)'
                                    : 'var(--system-error-500)'
                            }
                        />
                    )}
                    <span
                        className={classNames([
                            'text-sub',
                            {
                                'text-info': level === 'low',
                                'text-white': level === 'high',
                                'text-error': sign === 'error',
                            },
                        ])}
                    >
                        {htmlToText(content)}
                    </span>
                </span>
            }
            {...props}
        >
            <div className="flex">{childNode}</div>
        </Tooltip>
    );
};
