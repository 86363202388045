import { IconProps } from './type';

export const ICCheckFill: React.FC<IconProps> = ({
    fill = '#4DCC8F',
    width = 24,
    height = 24,
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 23C18.0751 23 23 18.0751 23 12C23 9.61495 22.2409 7.40719 20.9512 5.60512L10.2529 16.3035C10.2472 16.3091 10.2381 16.3091 10.2324 16.3035L6.29289 12.3639C6.10536 12.1764 6 11.9221 6 11.6568C6 11.1046 6.44772 10.6568 7 10.6568C7.26522 10.6568 7.51957 10.7622 7.70711 10.9497L10.2426 13.4853L19.6409 4.08696C17.6621 2.17576 14.9684 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
                fill={fill}
            />
        </svg>
    );
};

export const ICCheckMultiComplete: React.FC<IconProps> = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.6667 2H5.33333C4.44928 2 3.60143 2.35119 2.97631 2.97631C2.35119 3.60143 2 4.44928 2 5.33333V18.6667C2 19.5507 2.35119 20.3986 2.97631 21.0237C3.60143 21.6488 4.44928 22 5.33333 22H18.6667C19.5507 22 20.3986 21.6488 21.0237 21.0237C21.6488 20.3986 22 19.5507 22 18.6667V5.33333C22 4.44928 21.6488 3.60143 21.0237 2.97631C20.3986 2.35119 19.5507 2 18.6667 2ZM16.7778 9.34444L11.7 16.0111C11.5965 16.1456 11.4636 16.2545 11.3114 16.3297C11.1592 16.4048 10.9919 16.444 10.8222 16.4444C10.6535 16.4454 10.4867 16.4078 10.3346 16.3346C10.1825 16.2615 10.0491 16.1546 9.94444 16.0222L7.23333 12.5667C7.1436 12.4514 7.07744 12.3196 7.03865 12.1787C6.99986 12.0379 6.98918 11.8908 7.00724 11.7458C7.02529 11.6009 7.07173 11.4609 7.14388 11.3339C7.21604 11.2069 7.31251 11.0953 7.42778 11.0056C7.66058 10.8243 7.95584 10.743 8.2486 10.7795C8.39357 10.7975 8.53355 10.8439 8.66057 10.9161C8.78759 10.9883 8.89915 11.0847 8.98889 11.2L10.8 13.5111L15 7.95556C15.089 7.83882 15.2001 7.74077 15.327 7.66699C15.4539 7.5932 15.5941 7.54514 15.7396 7.52553C15.8851 7.50593 16.033 7.51517 16.1749 7.55273C16.3168 7.59029 16.4499 7.65544 16.5667 7.74444C16.6834 7.83345 16.7815 7.94458 16.8552 8.07148C16.929 8.19838 16.9771 8.33858 16.9967 8.48406C17.0163 8.62953 17.0071 8.77745 16.9695 8.91936C16.9319 9.06126 16.8668 9.19438 16.7778 9.31111V9.34444Z"
                fill="#0BAA60"
            />
        </svg>
    );
};

export const ICCheckSingleComplete: React.FC<IconProps> = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_13272_3439)">
                <path
                    d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.00519941 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693604 16.6688 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6934 24 14.3734 24 12C24 10.4241 23.6896 8.8637 23.0866 7.4078C22.4835 5.95189 21.5996 4.62902 20.4853 3.51472C19.371 2.40041 18.0481 1.5165 16.5922 0.913445C15.1363 0.310389 13.5759 0 12 0ZM17.16 9.132L11.676 16.332C11.5642 16.4772 11.4206 16.5949 11.2563 16.676C11.092 16.7571 10.9113 16.7996 10.728 16.8C10.5457 16.801 10.3656 16.7604 10.2014 16.6814C10.0371 16.6024 9.89301 16.487 9.78 16.344L6.852 12.612C6.75509 12.4875 6.68364 12.3451 6.64174 12.193C6.59985 12.0409 6.58832 11.8821 6.60782 11.7255C6.62732 11.5689 6.67747 11.4178 6.7554 11.2806C6.83332 11.1434 6.93751 11.0229 7.062 10.926C7.31343 10.7303 7.63231 10.6424 7.94849 10.6818C8.10505 10.7013 8.25624 10.7515 8.39342 10.8294C8.5306 10.9073 8.65109 11.0115 8.748 11.136L10.704 13.632L15.24 7.632C15.3361 7.50593 15.4561 7.40003 15.5932 7.32034C15.7303 7.24065 15.8817 7.18874 16.0388 7.16757C16.1959 7.1464 16.3556 7.15638 16.5089 7.19695C16.6622 7.23751 16.8059 7.30787 16.932 7.404C17.0581 7.50012 17.164 7.62014 17.2437 7.7572C17.3233 7.89425 17.3753 8.04566 17.3964 8.20278C17.4176 8.35989 17.4076 8.51964 17.367 8.6729C17.3265 8.82616 17.2561 8.96993 17.16 9.096V9.132Z"
                    fill="#0BAA60"
                />
            </g>
            <defs>
                <clipPath id="clip0_13272_3439">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
