import React from 'react';
import { Steps } from 'antd';
import styled from 'styled-components';
import { useCreateServices } from './hooks/useCreateServices';

const StepWrapper = styled.div`
    .ant-steps .ant-steps-item-container {
        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .ant-steps-item-title {
            font-size: 14px;
            line-height: 24px;
            color: #676472;
            @media (max-width: 768px) {
                font-size: 13px;
                text-align: left;
                display: block;
            }
        }
    }
    .ant-steps.ant-steps-label-vertical .ant-steps-item-icon {
        margin-inline-start: 60px;
        @media (max-width: 768px) {
            margin-inline-start: 10px !important;
        }
    }
    .ant-steps.ant-steps-label-vertical .ant-steps-item-content {
        width: 150px;
        @media (max-width: 768px) {
            width: auto;
        }
    }
    .ant-steps.ant-steps-label-vertical .ant-steps-item-tail {
        margin-inline-start: 72px;
        @media (max-width: 768px) {
            margin-inline-start: 60px !important;
        }
    }
    .ant-steps-item-finish {
        .ant-steps-item-icon {
            background-color: #0baa60;
            svg {
                color: white;
            }
        }
    }
`;

type TStepItemProps = {
    mobileMode: boolean;
};

export const CreateServicesStep: React.FC<TStepItemProps> = ({
    mobileMode,
}) => {
    const { step, onChangeStep, stepServices } = useCreateServices();

    return (
        <StepWrapper className="flex justify-center">
            {
                <Steps
                    current={step}
                    items={stepServices}
                    onChange={(value: number) => onChangeStep(value)}
                    labelPlacement="vertical"
                    direction="horizontal"
                    responsive={false}
                    size={mobileMode ? 'small' : 'default'}
                />
            }
        </StepWrapper>
    );
};
