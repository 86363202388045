import { Breadcrumb } from 'antd';
import { Link, RouteObject, useMatches } from 'react-router-dom';
import { useGeneralPageData } from '../../../pages/general';

export type TBreadcrumbs = {
    title: string;
    href: string;
};

export const Breadcrumbs: React.FC = () => {
    const matches: RouteObject[] = useMatches();
    const crumbs = matches.map(match => match.handle?.crumbs);
    const routesInfos = crumbs.reduce((acc, crumb) => {
        if (crumb) {
            acc.push(...crumb);
        }
        return acc;
    }, []);
    const { breadcrumbs } = useGeneralPageData();

    return (
        <div className="flex items-start">
            <Breadcrumb separator=">" items={routesInfos}>
                {routesInfos.map((routeInfo: any, index: number) => {
                    return (
                        <Breadcrumb.Item key={index}>
                            <Link to={routeInfo.href}>{routeInfo.title}</Link>
                        </Breadcrumb.Item>
                    );
                })}
                {breadcrumbs.map((breadcrumb, index) => {
                    return (
                        <Breadcrumb.Item key={index}>
                            <Link to={breadcrumb.href}>{breadcrumb.title}</Link>
                        </Breadcrumb.Item>
                    );
                })}
            </Breadcrumb>
        </div>
    );
};
