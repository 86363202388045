import { MenuProps } from 'antd';
import React from 'react';
import Sider from 'antd/es/layout/Sider';
import classNames from 'classnames';
import { Navbar } from './navbar';

type MenuItem = Required<MenuProps>['items'][number];

type DesktopSiderProps = {
    collapsed: boolean;
    setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
    openKeys: string[];
    setOpenKeys: React.Dispatch<React.SetStateAction<string[]>>;
    items: MenuItem[];
    selectedMenu: string;
    onClickMenu: MenuProps['onClick'];
};

export const DesktopSider: React.FC<DesktopSiderProps> = ({
    collapsed,
    setCollapsed,
    openKeys,
    items,
    selectedMenu,
    onClickMenu,
    setOpenKeys,
}) => {
    return (
        <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            breakpoint="lg"
            collapsedWidth="50"
            className={classNames([
                'sider-admin',
                collapsed ? 'sider-admin-collapsed' : '',
            ])}
            onCollapse={collapsed => {
                setCollapsed(collapsed);
            }}
        >
            <Navbar
                openKeys={openKeys}
                items={items}
                selectedMenu={selectedMenu}
                onClickMenu={onClickMenu}
                setOpenKeys={setOpenKeys}
            />
        </Sider>
    );
};
