import React from 'react';
import { RouteObject, useMatches } from 'react-router-dom';
import { useGeneralPageData } from '../../../pages/general';

export const Title: React.FC = () => {
    const matches: RouteObject[] = useMatches();
    const infos = matches.map(match => match.handle?.info);
    const dataTitle = React.useMemo(() => {
        return infos[0];
    }, [infos]);
    const { titlePage } = useGeneralPageData();

    return (
        <div className="flex items-start">
            <h1 className="text-heading-6-semi-bold">
                {titlePage || dataTitle?.title}
            </h1>
        </div>
    );
};
