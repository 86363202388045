import { notification } from 'antd';
import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';

export const isNetworkError = (error: any) => {
    return error.code === 'ERR_NETWORK';
};

axios.interceptors.response.use();
export class ApiInstance {
    private static instance: AxiosInstance;
    private static token: string;
    private constructor() {}
    public static getInstance() {
        if (!this.instance) {
            const token = localStorage.getItem('token') ?? this.token;
            const headers: AxiosRequestHeaders = {
                'Content-Type': 'application/json',
            } as AxiosRequestHeaders;
            if (token !== undefined) {
                headers.Authorization = `Bearer ${token}`;
            }

            this.instance = axios.create({
                baseURL: process.env.REACT_APP_API_BASE_URL,
                headers,
                withCredentials: false,
            });

            this.instance.interceptors.request.use(
                config => {
                    if (config.headers) {
                        const token =
                            localStorage.getItem('token') ?? this.token;
                        if (token !== undefined) {
                            config.headers['Authorization'] = `Bearer ${token}`;
                        }
                    }
                    return config;
                },
                error => {
                    return Promise.reject(error);
                }
            );

            this.instance.interceptors.response.use(
                function (response) {
                    return response;
                },
                function (error) {
                    if (isNetworkError(error)) {
                        notification.error({
                            message: 'Network Error',
                            description: 'Please check your network connection',
                        });
                        return Promise.reject('error');
                    }
                    if (error.response?.status === 401) {
                        localStorage.removeItem('userInfo');
                        window.location.href = '/login';
                        // window.location.reload(); // TODO: update to use react router
                    }
                    // Do something with response error
                    return Promise.reject(error);
                }
            );
        }
        return this.instance;
    }

    public static setToken(token: string) {
        if (this.instance) {
            this.instance.defaults.headers.common.Authorization = `Bearer ${token}`;
        }
        this.token = token;
    }

    public static resetToken() {
        this.instance.defaults.headers.common['Authorization'] = '';
    }
}
