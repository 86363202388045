import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from '../api/setting/users';
import { setUserInfo } from '../redux/actions';
import { RootState } from '../redux/reducers';
import { setLoading } from '../redux/slices/appInfo';

export const useMe = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector((state: RootState) => state.userInfo);
    const token = useMemo(() => userInfo?.token, [userInfo]);
    const [userId, setUserId] = React.useState<string | undefined>('');

    const getMe = React.useCallback(async () => {
        dispatch(setLoading(true));
        try {
            const result = await getUserInfo(userInfo?.Email ?? '');
            if (result.status === 200) {
                setUserId(result.data?.userId);
                const newUserInfo = {
                    ...userInfo,
                    ...result.data,
                };
                dispatch(setUserInfo(newUserInfo));
            }
        } catch (e) {
            // Handle error
        } finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, token, setUserId]);

    return {
        getMe,
        userId,
    };
};
