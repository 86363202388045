import { Menu, MenuProps } from 'antd';
import React from 'react';
import styled from 'styled-components';

const WrapperMenu = styled(Menu)`
    .ant-menu-item-selected {
        color: var(--primaryColor) !important;
    }
    .ant-menu-submenu-title,
    .ant-menu-item {
        gap: 0.5rem;
        @media (max-width: 768px) {
        }
    }
`;

type MenuItem = Required<MenuProps>['items'][number];

type NavbarProps = {
    openKeys: string[];
    setOpenKeys: React.Dispatch<React.SetStateAction<string[]>>;
    items: MenuItem[];
    selectedMenu: string;
    onClickMenu: MenuProps['onClick'];
};

export const Navbar: React.FC<NavbarProps> = ({
    openKeys,
    items,
    selectedMenu,
    onClickMenu,
    setOpenKeys,
}) => {
    return (
        <>
            <WrapperMenu
                key={'menu'}
                mode="inline"
                onClick={onClickMenu}
                selectedKeys={[selectedMenu]}
                openKeys={openKeys}
                onOpenChange={keys => {
                    setOpenKeys(keys as string[]);
                }}
                items={items}
                getPopupContainer={node => node.parentNode as HTMLElement}
            />
        </>
    );
};
