import { PreviewStep } from './components/preview';
import { RepairServicesStep } from './components/repair-services';
import { SelectLicensePlateStep } from './components/select-license-plate';
import { TStepItem } from './type';
import { StepProps } from 'antd';

export type TStepItemProps = StepProps & TStepItem;

export const STEP: TStepItemProps[] = [
    {
        title: 'Select Car',
        component: <SelectLicensePlateStep />,
    },
    {
        title: 'Select Services',
        component: <RepairServicesStep />,
    },
    {
        title: 'Preview & Submit',
        component: <PreviewStep />,
    },
];
