import { Button, ButtonProps } from 'antd';
import { ICClose } from '../icons/index';
import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

const AutoTrackButtonControl = styled(Button)`
    &.track-btn-primary {
        &:hover {
            // background: var(--primaryColor, #7357ff) !important;
            box-shadow: var(--shadow-e-02);
        }
        &:focus {
            // background: var(--primaryColor, #7357ff) !important;
            box-shadow: var(--shadow-pressed-primary);
        }
        &.cursor-not-allowed {
            &:hover {
                border-color: transparent !important;
                background: transparent !important;
                box-shadow: none;
            }
        }
    }
    &.track-btn-secondary {
        &:hover {
            border-color: var(--tc-primary-p-75) !important;
            background: var(--tc-primary-p-50) !important;
            box-shadow: var(--shadow-e-02);
        }
        &:focus {
            border-color: var(--tc-primary-p-75) !important;
            background: var(--tc-primary-p-50) !important;
            box-shadow: var(--shadow-pressed-primary);
        }
        &.cursor-not-allowed {
            &:hover {
                border-color: transparent !important;
                background: transparent !important;
                box-shadow: none;
            }
        }
    }
    &.track-btn-sub {
        &:hover {
            border-color: var(--tc-gray-g-75) !important;
            background: var(--tc-gray-g-25) !important;
            box-shadow: var(--shadow-e-02);
        }
        &:focus {
            border-color: var(--tc-gray-g-75) !important;
            background: var(--tc-gray-g-25) !important;
            box-shadow: var(--shadow-pressed-gray);
        }
        &.cursor-not-allowed {
            &:hover {
                border-color: transparent !important;
                background: transparent !important;
                box-shadow: none;
            }
        }
    }
    &.track-btn-info {
        &:hover {
            background: var(--tc-system-info-400) !important;
            box-shadow: var(--shadow-e-02);
        }
        &:focus {
            background: var(--tc-system-info-400) !important;
            box-shadow: var(--shadow-pressed-info);
        }
        &.cursor-not-allowed {
            &:hover {
                border-color: transparent !important;
                background: transparent !important;
                box-shadow: none;
            }
        }
    }
    &.track-btn-sub-info {
        &:hover {
            border-color: var(--tc-system-info-75) !important;
            background: var(--tc-system-info-25) !important;
            box-shadow: var(--shadow-e-02);
        }
        &:focus {
            border-color: var(--tc-system-info-75) !important;
            background: var(--tc-system-info-25) !important;
            box-shadow: var(--shadow-pressed-info);
        }
        &.cursor-not-allowed {
            &:hover {
                border-color: transparent !important;
                background: transparent !important;
                box-shadow: none;
            }
        }
    }
`;

type AutoTrackButtonProps = ButtonProps & {
    btnType?:
        | 'primary'
        | 'secondary'
        | 'sub'
        | 'outline'
        | 'danger'
        | 'info'
        | 'success'
        | 'warning'
        | 'sub_info'
        | 'tertiary'
        | 'tertiary_info'
        | 'sub_danger'
        | 'sub_rounded_primary';
    btnSize?: 'lg' | 'md' | 'sm' | 'xs';
    onlyRole?: string;
    width?: string;
    classess?: string;
};

const BUTTON_TYPE_MAPPING_PROPS = {
    primary: {
        background: 'var(--primaryColor, #7357FF)',
        color: 'white',
        borderRadius: '8px',
        fontWeight: 700,
    },
    danger: {
        background: '#F03D3D',
        color: 'white',
        borderRadius: '8px',
        fontWeight: 500,
    },
    secondary: {
        background: 'var(--secondaryColor, #4DCC8F)',
        color: '#7357FF',
        border: '1px solid #E2DCFF',
        borderRadius: '8px',
        fontWeight: 700,
    },
    sub: {
        background: 'white',
        color: '#4F4B5C',
        border: '1px solid #ECECED',
        borderRadius: '8px',
        fontWeight: 700,
    },
    outline: {
        background: 'white',
        color: '#000000',
        border: '1px solid #7357FF',
        borderRadius: '8px',
        fontWeight: 700,
    },
    info: {
        background: '#0084FF',
        color: 'white',
        borderRadius: '8px',
        fontWeight: 700,
    },
    success: {
        background: '#0BAA60',
        color: 'white',
        borderRadius: '8px',
        fontWeight: 700,
    },
    warning: {
        background: '#E09400',
        color: 'white',
        borderRadius: '8px',
        fontWeight: 700,
    },
    sub_info: {
        background: 'white',
        color: '#0084FF',
        border: '1px solid #C2E2FF',
        borderRadius: '8px',
        fontWeight: 700,
    },
    sub_danger: {
        background: 'white',
        color: '#F03D3D',
        border: '1px solid #FFE0E0',
        borderRadius: '8px',
        fontWeight: 700,
    },
    tertiary: {
        background: 'transparent',
        color: '#4F4B5C',
        border: 0,
        fontWeight: 700,
    },
    tertiary_info: {
        background: 'transparent',
        color: '#0084FF',
        border: 0,
        fontWeight: 700,
    },
    sub_rounded_primary: {
        background: 'transparent',
        color: '#7357FF',
        borderRadius: '144px',
        border: '1px solid var(--tc-primary-p-75)',
    },
};

const BUTTON_TYPE_TEXT_MAPPING = {
    primary: 'track-btn-primary',
    danger: 'track-btn-danger',
    secondary: 'track-btn-secondary',
    sub: 'track-btn-sub',
    outline: 'track-btn-outline',
    info: 'track-btn-info',
    success: 'track-btn-success',
    warning: 'track-btn-warning',
    sub_info: 'track-btn-sub-info',
    sub_danger: 'track-btn-sub-danger',
    tertiary: 'track-btn-tertiary',
    tertiary_info: 'track-btn-tertiary-info',
    sub_rounded_primary: 'track-btn-sub-rounded-primary',
};

const BUTTON_SIZE_MAPPING_PROPS = {
    lg: {
        fontSize: '16px',
        padding: '16px 24px',
        lineHeight: '24px',
    },
    md: {
        fontSize: '14px',
        padding: '10px',
        lineHeight: '24px',
    },
    sm: {
        fontSize: '14px',
        padding: '8px 16px',
        lineHeight: '24px',
    },
    xs: {
        fontSize: '10px',
        padding: '4px 8px',
        lineHeight: '16px',
    },
};

const ICON_BUTTON_SIZE_MAPPING_PROPS = {
    lg: {
        fontSize: '16px',
        padding: '16px',
        lineHeight: '24px',
    },
    md: {
        fontSize: '16px',
        padding: '10px',
        lineHeight: '24px',
    },
    sm: {
        fontSize: '14px',
        padding: '11px',
        lineHeight: '24px',
        width: '40px',
        height: '40px',
        minWidth: '40px',
    },
    xs: {
        fontSize: '10px',
        padding: '4px 8px',
        lineHeight: '16px',
    },
};

export const AutoTrackButton: React.FC<AutoTrackButtonProps> = ({
    btnType = 'primary',
    btnSize = 'lg',
    width = '100%',
    style,
    classess,
    ...props
}) => {
    const extraStyle = React.useMemo(() => {
        if (props.disabled) {
            return {
                width: width,
                height: 'auto',
                ...BUTTON_TYPE_MAPPING_PROPS[btnType],
                ...BUTTON_SIZE_MAPPING_PROPS[btnSize],
                ...style,
                background: '#F2F2F2',
                color: '#D6D7DD',
                border: '1px solid transparent',
            };
        }
        return {
            width: width,
            height: 'auto',
            border: 0,
            ...BUTTON_TYPE_MAPPING_PROPS[btnType],
            ...BUTTON_SIZE_MAPPING_PROPS[btnSize],
            ...style,
        };
    }, [btnType, btnSize, props.disabled, style, width]);

    return (
        <AutoTrackButtonControl
            {...props}
            className={classNames([
                'shadow-e-01',
                BUTTON_TYPE_TEXT_MAPPING[btnType],
                {
                    'cursor-not-allowed': props.disabled,
                },
                classess,
            ])}
            style={{
                boxSizing: 'border-box',
                overflow: 'hidden',
                ...extraStyle,
            }}
        />
    );
};

type CloseButtonProps = ButtonProps & {
    color?: string;
    text?: string;
    fill?: string;
};
export const CloseButton: React.FC<CloseButtonProps> = ({
    color = '#3FB483',
    text = '',
    fill = '#9A9CA9',
}) => {
    return (
        <div className="flex items-center p-[11px] border rounded-[8px] shadow-e-01 border-[#ECECED] cursor-pointer box-border overflow-hidden">
            <ICClose height={18} width={18} fill={fill} />
            {text && (
                <span
                    className="font-bold"
                    style={{
                        color: `${color}`,
                    }}
                >
                    {text}
                </span>
            )}
        </div>
    );
};

type IconButtonProps = ButtonProps & {
    icon: React.ReactNode;
    btnType?:
        | 'primary'
        | 'secondary'
        | 'sub'
        | 'outline'
        | 'danger'
        | 'info'
        | 'success'
        | 'warning'
        | 'sub_info'
        | 'tertiary'
        | 'sub_danger'
        | 'sub_rounded_primary';
    btnSize?: 'lg' | 'md' | 'sm' | 'xs';
};
export const IconButton: React.FC<IconButtonProps> = ({
    icon,
    btnType = 'primary',
    btnSize = 'lg',
    ...props
}) => {
    const extraStyle = React.useMemo(() => {
        if (props.disabled) {
            return {
                ...BUTTON_TYPE_MAPPING_PROPS[btnType],
                ...ICON_BUTTON_SIZE_MAPPING_PROPS[btnSize],
                background: '#F3F3F4',
                color: '#D6D7DD',
                border: 0,
            };
        }
        return {
            ...BUTTON_TYPE_MAPPING_PROPS[btnType],
            ...ICON_BUTTON_SIZE_MAPPING_PROPS[btnSize],
        };
    }, [btnType, btnSize, props.disabled]);

    return (
        <AutoTrackButtonControl
            className={classNames([
                'shadow-e-01',
                BUTTON_TYPE_TEXT_MAPPING[btnType],
                {
                    'cursor-not-allowed': props.disabled,
                },
            ])}
            style={{
                width: 'auto',
                height: 'auto',
                boxSizing: 'border-box',
                overflow: 'hidden',
                ...extraStyle,
            }}
            {...props}
        >
            {icon}
        </AutoTrackButtonControl>
    );
};
